import React, { useState } from 'react';
import { Linking, TouchableOpacity, Image } from 'react-native';
import hexToRgba from 'hex-to-rgba';

import { SvgUri } from '@src/components/SvgUri';
import { Color } from '@src/styles';
import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { Icon } from '@src/components/Icon';
import { Text, Heading } from '@src/components/Text';
import TextInput from '@src/components/TextInput';
import { useT } from '@src/lib/i18n';

export function Contact({
  name,
  image,
  imageBorderRadius,
  phone,
  imageOnly,
}: {
  name: { first: string | null; last: string | null };
  image?: string | null;
  imageBorderRadius?: boolean;
  phone?: string;
  imageOnly?: boolean;
}) {
  const nameStr = [name.first, name.last].filter((part) => !!part).join(' ');
  return (
    <View
      style={{
        flexBasis: '33%',
        marginVertical: 20,
        alignItems: 'center',
      }}
      spacing={8}
    >
      <View>
        {image ? (
          image.endsWith('svg') ? (
            <View
              style={{ width: 80, height: 80, borderRadius: imageBorderRadius ? 40 : undefined }}
            >
              <SvgUri uri={image} width="100%" height="100%" />
            </View>
          ) : (
            <Image
              style={{ width: 80, height: 80, borderRadius: imageBorderRadius ? 40 : undefined }}
              resizeMode="contain"
              source={{ uri: image }}
            />
          )
        ) : (
          <View
            style={{
              backgroundColor: hexToRgba(Color.styleGuide.LogoLilac, 0.4),
              width: 80,
              height: 80,
              borderRadius: 40,
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Text
              color={Color.styleGuide.LogoLilac}
              text={nameStr
                .replace(/[^A-Za-z ]/g, '')
                .split(' ')
                .map((segment) => segment[0] || '')
                .join('')}
              size={35}
              weight="bold"
              style={{ lineHeight: 61 }}
            />
          </View>
        )}
        {phone && !imageOnly ? (
          <TouchableOpacity
            style={{
              padding: 8,
              borderRadius: 50,
              backgroundColor: 'rgba(255,255,255, 0.8)',
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
            onPress={() => Linking.openURL(`tel:${phone}`)}
          >
            <Icon name="phone" color={Color.accent} size={20} />
          </TouchableOpacity>
        ) : null}
      </View>
      {!imageOnly ? <Text text={nameStr} weight="bold" style={{ textAlign: 'center' }} /> : null}
    </View>
  );
}

const MyPlanContactsInput = (props: {
  value: { names: string[] };
  onChangeValue: (answer: { names: string[] }) => void;
}) => {
  const t = useT();
  const [name, setName] = useState('');
  return (
    <View spacing={20} style={{ backgroundColor: 'white', flex: 1, padding: 16 }}>
      <Heading text={t('myplan_contacts_input.heading')} />
      <View row style={{ alignItems: 'flex-start', flexWrap: 'wrap' }}>
        {props.value.names.map((n, i) => (
          <Contact key={i} name={{ first: n, last: '' }} />
        ))}
        <View
          style={{
            flexBasis: '33%',
            marginVertical: 20,
          }}
          spacing={8}
        >
          <View
            style={{
              width: 80,
              height: 80,
              borderRadius: 40,
              backgroundColor: '#eee',
              alignSelf: 'center',
            }}
          />
          <TextInput
            label={t('myplan_contacts_input.name_label')}
            value={name}
            onChangeValue={setName}
            testID="MyPlanContactsInput_name"
          />
          <Button
            testID="MyPlanContactsInput_submit"
            text={t('myplan_contacts_input.save_button')}
            onPress={() => {
              props.onChangeValue({ names: [...props.value.names, name] });
              setName('');
            }}
            alignSelf="center"
            style={{ paddingHorizontal: 40 }}
          />
        </View>
      </View>
    </View>
  );
};

export default MyPlanContactsInput;
