import {
  ApolloCache,
  DefaultOptions,
  InMemoryCache,
  ApolloLink,
  FieldPolicy,
} from '@apollo/client';
import merge from 'lodash/merge';
import uuid from 'uuid/v4';
import * as Localization from 'expo-localization';

import Sentry from '@src/sentry';
import ApolloClient from '@src/lib/apollo';
import { getAuthToken } from '@src/lib/auth';
import { manifest, APP_SLUG } from '@src/constants';
import { TypedTypePolicies } from '@src/types/apollo-helpers';
import apolloPossibleTypes from '@src/types/apollo-possible-types';

const merger: FieldPolicy<any> = {
  merge(existing, incoming, { mergeObjects }) {
    return mergeObjects(existing, incoming);
  },
};

// https://github.com/dotansimha/graphql-code-generator/issues/5025
const typePolicies: TypedTypePolicies = {
  Query: {
    fields: {
      patientByPatientID(_, { args, toReference }) {
        return toReference({
          __typename: 'PatientProfile',
          patient: {
            ID: args?.patientID,
          },
        });
      },
      hopeKitItems: {
        merge(existing = [], incoming: any[]) {
          return incoming ?? existing;
        },
      },
    },
  },
  ActivityEvent: {
    keyFields: ['activityEventID'],
  },
  ActivityEventInstance: {
    keyFields: ['activityEventInstanceID'],
  },
  KVResponse: {
    keyFields: ['context', 'key'],
  },
  Organization: {
    keyFields: ['ID'],
  },
  Composition: {
    keyFields: ['ID'],
  },
  CompositionSection: {
    keyFields: ['ID'],
  },
  CurrentOuiUser: {
    keyFields: ['user', ['ID'] as any],
  },
  UserEntity: {
    keyFields: ['ID'],
  },
  User: {
    keyFields: ['ID'],
    fields: {
      name: merger,
      demo: merger,
      address: merger,
    },
  },
  PatientProfile: {
    keyFields: ['patient', ['ID'] as any],
    fields: {
      progress: {
        merge(existing, incoming) {
          return incoming ?? existing;
        },
      },
    },
  },
  Practitioner: {
    keyFields: ['ID'],
    fields: {
      person: merger,
    },
  },
  Patient: {
    keyFields: ['ID'],
    fields: {
      person: merger,
    },
  },
  Practice: {
    keyFields: ['practiceID'],
    fields: {
      practiceValues: merger,
    },
  },
  SleepDiaryEntryPractice: {
    keyFields: ['practiceValues', ['date'] as any],
    fields: {
      practiceValues: merger,
    },
  },
  FileUpload: {
    keyFields: ['fileUploadID'],
  },
  HopeKitImage: {
    keyFields: ['hopeKitItemID'],
  },
  HopeKitVideo: {
    keyFields: ['hopeKitItemID'],
  },
  HopeKitQuote: {
    keyFields: ['hopeKitItemID'],
  },
  CMSSession: {
    keyFields: ['sessionID'],
  },
  CMSExchange: {
    keyFields: ['exchangeID'],
  },
};

export const createApolloCache = () => {
  return new InMemoryCache({
    addTypename: false,
    possibleTypes: apolloPossibleTypes.possibleTypes,
    typePolicies,
    dataIdFromObject: (object) => {
      return object.ID ? `${object.ID}` : undefined;
    },
  });
};

async function getAuthorizationHeader() {
  const authToken = await getAuthToken();
  return authToken ? `Bearer ${authToken}` : undefined;
}

export const createApolloClient = (
  uri: string,
  {
    subscriptionUri,
    connectToDevTools = false,
    getAuthHeader: _getAuthHeader,
    cache = createApolloCache(),
    preHttpLink,
    defaultOptions = {},
  }: {
    subscriptionUri?: string;
    connectToDevTools?: boolean;
    getAuthHeader?: () => Promise<string>;
    cache?: ApolloCache<any>;
    preHttpLink?: ApolloLink;
    defaultOptions?: DefaultOptions;
  } = {},
) => {
  return new ApolloClient<object>({
    connectToDevTools,
    cache,
    defaultOptions: merge<DefaultOptions, DefaultOptions>(
      {
        mutate: {},
        query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          // We want to rely on the cache when a component re-renders (not remounts) rather
          // than send a new network request on every render after a mutation that touched the cached object
          // https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
          nextFetchPolicy: 'cache-first',
          errorPolicy: 'all',
        },
      },
      defaultOptions,
    ),
    uri,
    subscriptionOptions:
      !subscriptionUri || process.env.NODE_ENV === 'test'
        ? undefined
        : {
            uri: subscriptionUri,
            connectionParams: async () => {
              return {
                Authorization: _getAuthHeader
                  ? await _getAuthHeader()
                  : await getAuthorizationHeader(),
              };
            },
          },
    preHttpLink,
    request: async (operation) => {
      const authHeader = _getAuthHeader ? await _getAuthHeader() : await getAuthorizationHeader();
      if (authHeader) {
        operation.setContext({
          headers: {
            authorization: authHeader,
          },
        });
      }
      operation.setContext((currentContext: any) => ({
        headers: {
          ...currentContext.headers,
          'X-Oui-Client': APP_SLUG,
          'X-RequestID': uuid(),
          'X-Oui-Client-Version': manifest.version,
          'X-Client-Timezone': Localization.timezone,
          'X-Client-Locale': Localization.locale,
        },
      }));
    },
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          const { message, locations, path } = error;

          const bodyStr = message.split('body: ')[1];
          if (bodyStr && bodyStr.startsWith('{')) {
            // @ts-expect-error
            error.body = JSON.parse(bodyStr);
          }

          Sentry.captureMessage(
            `[GraphQL error]: Message: ${message}, Location: ` + `${locations}, Path: ${path}`,
          );
        });
      }
      if (networkError) {
        Sentry.captureException(networkError);
      }
    },
  });
};
