import React, { forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Platform,
  StyleProp,
  TextStyle,
  TextInput as RNTextInput,
  TextInputProps,
  Keyboard,
} from 'react-native';

import { addBreadcrumb } from '@src/lib/log';
import { useColor } from '@src/styles';
import { useT } from '@src/lib/i18n';
import { AccessibleInput } from '@src/hooks/useAccessibleInput';
import { useAccessibilityContext } from '@src/components/AccessibilityContext';

type Props = Omit<TextInputProps, 'value' | 'onChangeText'> & {
  component?: React.FunctionComponent<TextInputProps>;
  disabled?: boolean;
  error?: string;
  hint?: string;
  initialContentHeight?: number;
  inputStyle?: StyleProp<TextStyle>;
  label?: string;
  labelColor?: string;
  onChangeValue: (value: string) => void;
  value?: string | null;
  variant?: 'contained' | 'flat';
};

export const getStyles = (Color: ReturnType<typeof useColor>['Color']) => ({
  outlined: {
    backgroundColor: Color.surfaceColor,
    borderColor: '#C6C6D4',
    borderRadius: 30,
    borderWidth: 1,
    color: Color.text,
    fontFamily: 'OpenSansRegular',
    marginVertical: 4,
    padding: 16,
  },
  flat: {
    borderBottomWidth: 1,
    borderColor: '#C6C6D4',
    color: Color.text,
    fontFamily: 'OpenSansRegular',
    padding: 10,
    paddingVertical: 16,
  },
  focused: {
    borderColor: '#4A8EF4',
    elevation: 4,
    shadowColor: '#2060C2',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
});

export const TextInput = forwardRef<RNTextInput, Props>(function TextInput(
  {
    accessibilityLabel,
    component,
    hint,
    inputStyle,
    error,
    disabled,
    label,
    labelColor,
    variant,
    value,
    style,
    onChangeValue,
    onFocus,
    onBlur,
    onSubmitEditing,
    secureTextEntry,
    textContentType,
    autoCompleteType,
    placeholder,
    ...props
  },
  ref,
) {
  const [isFocused, setIsFocused] = useState(Platform.OS === 'web' && props.autoFocus);
  const { Color } = useColor();
  const [multilineInitialized, setMultilineInitialized] = useState(
    !props.multiline || Platform.OS !== 'web',
  );
  const [contentHeight, setContentHeight] = useState(props.initialContentHeight ?? 30);
  const [layoutHeight, setLayoutHeight] = useState(0);
  const { isKeyboardShowing, isScreenReaderEnabled } = useAccessibilityContext();

  useEffect(() => {
    if (!multilineInitialized) {
      setMultilineInitialized(true);
    }
  }, [multilineInitialized]);

  const Component = component ?? RNTextInput;

  return (
    <AccessibleInput
      accessibilityLabel={accessibilityLabel}
      placeholder={placeholder}
      error={error}
      forwardRef={ref}
      hint={hint}
      label={label}
      labelColor={labelColor}
      style={style}
      testID={props.testID}
    >
      {(accessibleProps) => (
        <>
          <Component
            {...accessibleProps}
            placeholder={
              error && value ? accessibleProps.accessibilityLabel : accessibleProps.placeholder
            }
            caretHidden={global.e2e}
            onContentSizeChange={
              props.multiline
                ? (event) => {
                    setContentHeight(Math.max(event.nativeEvent.contentSize.height, 50));
                  }
                : undefined
            }
            placeholderTextColor="#9191A3"
            // When a multiline TextInput height is larger than a single line of text, we want the
            // text/placeholder to be top aligned. If we only have one line of text height, we prefer
            // to center align so it's more aesthetically pleasing
            onLayout={(e) => {
              if (e.nativeEvent.layout.height !== layoutHeight) {
                setLayoutHeight(e.nativeEvent.layout.height);
              }
            }}
            textAlignVertical={
              props.multiline && Platform.OS === 'android' && layoutHeight > 52 ? 'top' : undefined
            }
            style={[
              props.multiline ? { paddingTop: 16, minHeight: contentHeight + 2 } : null,
              variant === 'flat' ? getStyles(Color).flat : getStyles(Color).outlined,
              { borderColor: error ? Color.error : '#C6C6D4' },
              isFocused ? getStyles(Color).focused : null,
              disabled ? { opacity: 0.7, backgroundColor: Color.styleGuide.Gray7 } : null,
              inputStyle,
            ]}
            secureTextEntry={global.e2e ? undefined : secureTextEntry}
            textContentType={global.e2e ? undefined : textContentType}
            autoCompleteType={global.e2e ? undefined : autoCompleteType}
            {...props}
            value={multilineInitialized ? value || '' : ''}
            // use onChangeText if available b/c it's passed by TextInputMask
            onChangeText={(props as any).onChangeText || onChangeValue}
            onBlur={(e) => {
              setIsFocused(false);
              onBlur?.(e);
            }}
            onFocus={(e) => {
              setIsFocused(true);
              onFocus?.(e);
            }}
            onSubmitEditing={
              onSubmitEditing
                ? (e) => {
                    addBreadcrumb({
                      category: 'ui.submit',
                      message: props.testID ?? label,
                      data: {
                        componentType: 'TextInput',
                        testID: props.testID,
                        label,
                      },
                    });
                    onSubmitEditing(e);
                  }
                : undefined
            }
            editable={!disabled}
            // @ts-expect-error
            dataSet={{ nofocusoutline: '' }}
          />
          {isScreenReaderEnabled && isFocused && isKeyboardShowing ? (
            <Button title="Close keyboard" onPress={Keyboard.dismiss} />
          ) : null}
        </>
      )}
    </AccessibleInput>
  );
});

export default TextInput;

export const EmailInput = React.forwardRef<RNTextInput, Props>(function EmailInput(props, ref) {
  const t = useT();
  return (
    <TextInput
      autoCapitalize="none"
      autoCompleteType="email"
      keyboardType="email-address"
      placeholder={t('login.email_label')}
      textContentType="username"
      ref={ref}
      {...props}
    />
  );
});

export const NumberInput = React.forwardRef<
  RNTextInput,
  Omit<Props, 'value' | 'onChangeValue'> & {
    onChangeValue: (value: number | null) => void;
    value?: number | null;
  }
>(function NumberInput(props, ref) {
  const [value, setValue] = useState(props.value ? props.value.toString() : '');
  const [error, setError] = useState(false);

  useEffect(() => {
    const parsedValue = Number.parseFloat(value);
    if (parsedValue !== props.value) {
      setValue(props.value?.toString() ?? '');
    }
  }, [value, props.value]);

  return (
    <TextInput
      {...props}
      ref={ref}
      value={value.toString()}
      onChangeValue={(val) => {
        const onChangeValue = props.onChangeValue;
        if (!onChangeValue) return;
        if (val === '' && props.value !== null) {
          setError(false);
          onChangeValue(null);
          return;
        }
        const parsedValue = Number.parseFloat(val);
        const nan = Number.isNaN(parsedValue);
        if (!nan && parsedValue !== props.value) {
          onChangeValue(parsedValue);
          setError(false);
        } else if (nan) {
          setError(true);
        }
      }}
      error={error ? 'must be a number' : props.error}
    />
  );
});

export const RequiredNumberInput = React.forwardRef<
  RNTextInput,
  Omit<Props, 'value' | 'onChangeValue' | 'defaultValue'> & {
    onChangeValue: (value: number) => void;
    value?: number;
    defaultValue: number;
  }
>(function RequiredNumberInput({ defaultValue, onChangeValue, ...props }, ref) {
  const [isEmpty, setIsEmpty] = useState(false);
  return (
    <NumberInput
      {...props}
      ref={ref}
      value={isEmpty ? null : props.value}
      onChangeValue={(val) => {
        if (val === null) {
          setIsEmpty(true);
          onChangeValue(defaultValue);
        } else {
          if (isEmpty) {
            setIsEmpty(false);
          }
          onChangeValue(val);
        }
      }}
    />
  );
});
