import { useQuery, useApolloClient } from '@apollo/client';
import React, { useState, useRef } from 'react';

import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Icon } from '@src/components/Icon';
import { View } from '@src/components/View';
import { Markdown } from '@src/components/Markdown';
import { EmailInput } from '@src/components/TextInput';
import { WebPicker } from '@src/components/WebPicker';
import AuthScreenContainer from '@src/components/AuthScreenContainer';
import { Shadow, Color } from '@src/styles';
import { useForm } from '@src/hooks/useForm';
import { useAppContext } from '@src/hooks/useAppContext';
import { GetUserResponseVariables, GetUserResponseQuery } from '@src/lib/userResponse';
import { FormAnswers } from '@src/components/SchemaForm';

import SafetyDiagnostic, { safetyDiagnosticText } from '@src/screens/SafetyDiagnostic';
import SuicidalModeForm, { suicidalModeFormText } from '@src/screens/SuicidalModeForm';
import MyPlanForm, { myPlanFormText } from '@src/screens/MyPlanForm';
import { useT } from '@src/lib/i18n';
import { webPrint, webDownload } from '@src/lib/webDownload';
import { CAPS_RESULTS_EMAIL_URL } from '@src/constants';
import { JcoStackScreenProps } from '@src/types';
import { getStartingJcoModule, JcoModule } from '@src/lib/getStartingJcoModule';

const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

function sendResultsEmail(
  payload:
    | {
        format: 'pdf';
        to: string;
        patientID: string;
        clinicianName: string;
        data: File;
      }
    | {
        format: 'txt';
        to: string;
        patientID: string;
        clinicianName: string;
        data: string;
      },
) {
  const formData = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    formData.append(key, value);
  });

  return fetch(CAPS_RESULTS_EMAIL_URL, {
    method: 'POST',
    mode: 'no-cors',
    body: formData,
  });
}

function getShouldExportModule(
  maybeExportModule: JcoModule,
  firstModule: JcoModule,
  lastModule: JcoModule,
) {
  const order = ['safety', 'severity', 'mystorymyplan'];
  const firstIndex = order.indexOf(firstModule);
  const lastIndex = order.indexOf(lastModule);
  const exportIndex = order.indexOf(maybeExportModule);
  return firstIndex <= exportIndex && exportIndex <= lastIndex;
}

export default function SafetyScreeningResults(
  props: JcoStackScreenProps<'SafetyScreeningResults'>,
) {
  const { locale } = useAppContext();
  const t = useT();
  const client = useApolloClient();
  const [exported, setExported] = useState(false);
  const { data: formData, validate, bind } = useForm({ email: '', format: 'pdf' });

  const safetyDiagnosticRef = useRef(null);
  const severityDiagnosticRef = useRef(null);
  const suicidalModeRef = useRef(null);
  const myPlanRef = useRef(null);

  const firstScreen = getStartingJcoModule();
  const lastScreen = props.route.params?.lastScreen ?? 'mystorymyplan';

  const printSafetyDiagnostic = getShouldExportModule('safety', firstScreen, lastScreen);
  const printSeverityDiagnostic = getShouldExportModule('severity', firstScreen, lastScreen);
  const printMyStoryMyPlan = getShouldExportModule('mystorymyplan', firstScreen, lastScreen);

  const patientID = props.route.params?.patientID;
  const { data } = useQuery<
    {
      patientResponse: {
        patientID: string;
        clinicianName: string;
      };
    },
    GetUserResponseVariables
  >(GetUserResponseQuery, {
    variables: {
      patientID,
      context: 'caps',
      key: 'newSafetyScreening',
    },
  });
  const filename = data?.patientResponse
    ? `${data.patientResponse.patientID}_${data.patientResponse.clinicianName}`
    : 'SuicideScreening';

  async function getTextResults(): Promise<string> {
    async function getSafetyDiagnosticText() {
      const safetyDiagnostic = 'asq';
      const { data: safetyData } = await client.query<
        { patientResponse: FormAnswers },
        GetUserResponseVariables
      >({
        query: GetUserResponseQuery,
        variables: {
          patientID,
          context: 'caps',
          key: `diagnostic_${safetyDiagnostic}`,
        },
      });

      return `${t('safety_screening_results.screening_number', {
        num: 1,
      })}: ${safetyDiagnosticText(t, safetyDiagnostic, safetyData.patientResponse)}`;
    }

    async function getSeverityDiagnosticText() {
      const severityDiagnostic = 'cssrs';
      const { data: severityData } = await client.query<
        { patientResponse: FormAnswers },
        GetUserResponseVariables
      >({
        query: GetUserResponseQuery,
        variables: {
          patientID,
          context: 'caps',
          key: `diagnostic_${severityDiagnostic}`,
        },
      });

      return `${t('safety_screening_results.screening_number', {
        num: 2,
      })}: ${safetyDiagnosticText(t, severityDiagnostic, severityData.patientResponse)}`;
    }

    async function getMyStoryMyPlanText() {
      const suicidalMode = await suicidalModeFormText({
        client,
        patientID,
        locale,
        t,
      });
      const myPlan = await myPlanFormText({
        client,
        patientID,
        locale,
        t,
      });

      return `${t('safety_screening_results.safety_planning_header')}

1. ${suicidalMode}

----------

2. ${myPlan}`.trim();
    }

    return [
      printSafetyDiagnostic ? await getSafetyDiagnosticText() : undefined,
      printSeverityDiagnostic ? await getSeverityDiagnosticText() : undefined,
      printMyStoryMyPlan ? await getMyStoryMyPlanText() : undefined,
    ]
      .filter((a) => !!a)
      .join(
        `

----------------------------------

`,
      )
      .trim();
  }

  function getPdf() {
    return import('@src/lib/pdf').then(({ pdfFromNodes }) =>
      pdfFromNodes([
        safetyDiagnosticRef.current,
        severityDiagnosticRef.current,
        suicidalModeRef.current,
        myPlanRef.current,
      ]),
    );
  }

  function sendEmail(): Promise<unknown> {
    if (formData.format === 'pdf') {
      return getPdf()
        .then((pdf) => {
          return new Promise<Blob>((resolve) => {
            pdf.getBlob(resolve);
          });
        })
        .then((blob) => {
          return sendResultsEmail({
            format: 'pdf',
            patientID: data!.patientResponse.patientID,
            clinicianName: data!.patientResponse.clinicianName,
            to: formData.email,
            data: new File([blob], `${filename}.pdf`, { type: blob.type }),
          });
        })
        .then(() => setExported(true));
    } else {
      return getTextResults()
        .then((results) => {
          return sendResultsEmail({
            format: 'txt',
            patientID: data!.patientResponse.patientID,
            clinicianName: data!.patientResponse.clinicianName,
            to: formData.email,
            data: results,
          });
        })
        .then(() => setExported(true));
    }
  }

  function download(): Promise<unknown> {
    if (formData.format === 'pdf') {
      return getPdf()
        .then((pdf) => pdf.download(`${filename}.pdf`))
        .then(() => sleep(2000))
        .then(() => setExported(true));
    }

    return getTextResults()
      .then((str) => webDownload(str, `${filename}.txt`))
      .then(() => sleep(2000))
      .then(() => setExported(true));
  }

  function print(): Promise<unknown> {
    if (formData.format === 'pdf') {
      return getPdf()
        .then((pdf) => pdf.print())
        .then(() => setExported(true));
    }

    return getTextResults()
      .then((str) => webPrint(str))
      .then(() => setExported(true));
  }

  return (
    <AuthScreenContainer heading="">
      <View
        testID="SafetyScreeningResults"
        style={[
          { width: 780, alignSelf: 'center', borderRadius: 30, overflow: 'hidden' },
          Shadow.overlay,
        ]}
      >
        {exported ? (
          <View
            testID="SafetyScreeningResults_exportSuccess"
            spacing={20}
            style={{ alignItems: 'center', padding: 40, backgroundColor: 'white' }}
          >
            <View row>
              <Icon name="check" color={Color.success} />
              <Text text={t('safety_screening_results.results_sent')} color={Color.success} />
            </View>
            <Markdown
              style={{
                root: { width: (null as any) as undefined },
              }}
            >
              {t('safety_screening_results.confirm_results_hint')}
            </Markdown>
            <Button
              text={t('safety_screening_results.confirm_and_delete_button')}
              onPress={() => {
                // purge cache
                client.clearStore();
                (global as any).persister.purge();
                // navigate to home screen
                props.navigation.reset({
                  routes: [{ name: 'NewSafetyScreening' }],
                });
              }}
              variant="solid"
            />
          </View>
        ) : null}
        <View
          style={{
            alignItems: 'center',
            padding: 20,
            backgroundColor: Color.styleGuide.Gray8,
            zIndex: 1,
          }}
          spacing={16}
        >
          <Text
            text={t('safety_screening_results.heading')}
            size={32}
            color={Color.tertiary}
            weight="bold"
          />
          <View row spacing={12}>
            <Text text={t('safety_screening_results.format_label')} weight="bold" />
            <WebPicker
              items={[
                { label: t('safety_screening_results.format_option_pdf'), value: 'pdf' },
                { label: t('safety_screening_results.format_option_text'), value: 'txt' },
              ]}
              {...bind('format', {
                accessibilityLabel: t('safety_screening_results.format_label'),
              })}
              style={{ width: 150 }}
              testID="SafetyScreeningResults_format"
            />
          </View>
        </View>
        <View style={{ padding: 40, backgroundColor: 'white' }} spacing={30}>
          <View style={{ alignItems: 'center' }}>
            <Markdown
              style={{
                root: { width: (null as any) as undefined, fontSize: 21 },
                strong: { fontSize: 21 },
              }}
            >
              {t('safety_screening_results.data_warning')}
            </Markdown>
          </View>
          <View>
            <Text
              text={t('safety_screening_results.email_label')}
              style={{ marginLeft: 16, marginBottom: 5 }}
              weight="bold"
            />
            <View row spacing={44}>
              <View row flex={1}>
                <View flex={1}>
                  <EmailInput
                    {...bind('email', {
                      accessibilityLabel: 'Email',
                      validator: { type: 'email' },
                    })}
                    inputStyle={{
                      borderBottomEndRadius: 0,
                      borderEndWidth: 0,
                      borderTopEndRadius: 0,
                      paddingVertical: 10,
                    }}
                  />
                </View>
                <Button
                  testID="SafetyScreeningResults_email"
                  style={{ borderTopStartRadius: 0, borderBottomStartRadius: 0 }}
                  text={t('safety_screening_results.email_button')}
                  onPress={() => {
                    if (!validate()) return;
                    return sendEmail();
                  }}
                />
              </View>
              <Button
                testID="SafetyScreeningResults_download"
                icon="file-download"
                text={t('safety_screening_results.download_button')}
                onPress={download}
              />
              <Button
                testID="SafetyScreeningResults_print"
                icon="print"
                text={t('safety_screening_results.print_button')}
                onPress={print}
              />
            </View>
          </View>
        </View>
      </View>
      <View style={{ position: 'absolute', right: -5000 }}>
        {printSafetyDiagnostic ? (
          <SafetyDiagnostic printRef={safetyDiagnosticRef} type="safety" />
        ) : null}
        {printSeverityDiagnostic ? (
          <SafetyDiagnostic printRef={severityDiagnosticRef} type="severity" />
        ) : null}
        {printMyStoryMyPlan ? (
          <>
            <SuicidalModeForm
              printRef={suicidalModeRef}
              isComplete
              navigation={props.navigation as any}
              route={props.route as any}
            />
            <MyPlanForm
              printRef={myPlanRef}
              {...props}
              navigation={props.navigation as any}
              route={props.route as any}
            />
          </>
        ) : null}
      </View>
    </AuthScreenContainer>
  );
}
