import noop from 'lodash/noop';
import { init } from 'sentry-expo';
import * as Sentry from '@sentry/react-native';

import { Environment, environment, SENTRY_DSN } from '@src/constants';
import SentryRRWeb from '@src/rrweb';
import { _configuration } from '@src/lib/NetInfo';

export const routingInstrumentation = new Sentry.ReactNavigationV5Instrumentation();
let sentry = Sentry;

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  init({
    enableInExpoDevelopment: true,
    environment: environment,
    dsn: SENTRY_DSN,
    maxBreadcrumbs: 100,
    enableAutoSessionTracking: true,
    beforeBreadcrumb(breadcrumb, hint) {
      const is204 =
        breadcrumb.category === 'xhr' &&
        breadcrumb.data?.url === _configuration.reachabilityUrl &&
        breadcrumb.data?.status_code === 204;
      const isMuxData =
        breadcrumb.category === 'xhr' &&
        breadcrumb.data?.url === 'https://ube37gkhuapjipih1qa0ul5e2.litix.io';
      return is204 || isMuxData ? null : breadcrumb;
    },
    tracesSampleRate: 1,
    integrations: [
      new Sentry.ReactNativeTracing({
        tracingOrigins: ['localhost', 'oui.dev', 'oui.health', /^\//],
        routingInstrumentation,
      }),
      SentryRRWeb && environment !== Environment.PRODUCTION
        ? new SentryRRWeb({
            maskAllInputs: false,
            collectFonts: true,
          })
        : undefined!,
    ].filter((i) => !!i),
  });
} else {
  sentry = {
    Severity: Sentry.Severity,
    addBreadcrumb: noop,
    captureException: console.warn,
    captureMessage: console.log,
    setUser: noop,
    nativeCrash: () => console.warn('Enable Sentry in development to enable nativeCrash'),
    withScope: (cb: Function) =>
      cb({ setExtra: console.log, setExtras: console.log, setTag: console.log }),
  } as any;
}

export default sentry;
