import { useApolloClient } from '@apollo/client';
import React, { useRef, useEffect, useState } from 'react';
import { Clipboard, Platform, PanResponder } from 'react-native';
// import type { Dsn } from '@sentry/types';
import Constants from 'expo-constants';
import { CommonActions as NavigationActions } from '@react-navigation/native';
import invert from 'lodash/invert';
import { captureScreen } from 'react-native-view-shot';

import {
  manifest,
  environment,
  IS_PRODUCTION,
  isHermes,
  getNativeBuildVersionAsSemver,
  APP_SLUG,
} from '@src/constants';
import Sentry from '@src/sentry';

import { useAppContext } from '@src/hooks/useAppContext';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useIsLoggedIn } from '@src/hooks/useIsLoggedIn';
import { useLogout } from '@src/hooks/useLogout';
import { usePersistedState } from '@src/hooks/usePersistedState';
import AppContext from '@src/components/AppContext';
import { View } from '@src/components/View';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { Modal } from '@src/components/Modal';
import { Checkbox } from '@src/components/Checkbox';
import { TextInput, NumberInput } from '@src/components/TextInput';
import { WorksheetListItem } from '@src/components/WorksheetListItem';
import PickerInput from '@src/components/PickerInput';
import { setDeviceInfo } from '@src/lib/setDeviceInfo';
import { mockConversationCompleteV2 } from '@src/lib/mockUserState';
import { LOCALES } from '@src/lib/i18n';
import * as Notifications from '@src/lib/notifications';
import OuiModuleNative from '@src/lib/OuiModuleNative';
import { checkForUpdateAsync, fetchUpdateAsync } from '@src/lib/checkForUpdateAsync';
import { ContentType } from '@src/types/generated_v2';
import { getConfigString } from '@src/lib/remoteConfig';

// function attachmentUrlFromDsn(dsn: Dsn, eventId: string) {
//   const { host, path, projectId, port, protocol, user } = dsn;
//   return `${protocol}://${host}${port !== '' ? `:${port}` : ''}${
//     path !== '' ? `/${path}` : ''
//   }/api/${projectId}/events/${eventId}/attachments/?sentry_key=${user}&sentry_version=7&sentry_client=custom-javascript`;
// }

const IS_SIMPLE_DEV_MENU = APP_SLUG === 'oui-aviva-staff' && IS_PRODUCTION;

function ReportErrorSubscreen(props: { onRequestClose: () => void }) {
  const [error, setError] = useState('');
  const [includeScreenshot, setIncludeScreenshot] = useState(!IS_SIMPLE_DEV_MENU);

  async function uploadScreenshot(id: string) {
    const uri = await captureScreen({ format: 'png' });
    const content = await (await fetch(`file://${uri}`)).blob();
    fetch(
      `https://storage.googleapis.com/upload/storage/v1/b/bug-reports.oui.dev/o?uploadType=media&name=${id}.png`,
      { method: 'POST', body: content },
    );

    // const formData = new FormData();
    // formData.append('screenshot', content, 'screenshot.png');
    // const dsn = Sentry.getCurrentHub().getClient()?.getDsn();
    // console.log(formData, dsn, dsn ? attachmentUrlFromDsn(dsn, id) : null);
    // if (!dsn) return;
    // fetch(attachmentUrlFromDsn(dsn, id), {
    //   method: 'POST',
    //   body: formData,
    // });
  }

  return (
    <View spacing={24} style={{ height: 400 }}>
      <TextInput
        label="Describe your bug"
        multiline
        value={error}
        onChangeValue={setError}
        placeholder="Example: When I tried clicking on the 'Save' button, the screen turned blank."
      />
      {IS_SIMPLE_DEV_MENU ? null : (
        <Checkbox
          onChangeValue={setIncludeScreenshot}
          value={includeScreenshot}
          horizontal
          label="Include screenshot?"
        />
      )}
      <Button
        text="Submit report"
        onPress={() => {
          Sentry.withScope((scope) => {
            scope.setExtras({ error });
            const id = Sentry.captureException(`DevMenu error report - ${error}`);
            Clipboard.setString(id);
            setError('');
            props.onRequestClose();
            if (Platform.OS !== 'web' && includeScreenshot) {
              setTimeout(() => {
                // id is undefined if sentry is disabled
                uploadScreenshot(id || Date.now().toString());
              }, 500);
            }
          });
        }}
      />
    </View>
  );
}

const V2_SESSION_ORDER = [
  ContentType.MYPLAN,
  ContentType.HOPE_KIT,
  ContentType.ACTIVITY_PLANNING,
  ContentType.RELAXATION,
  ContentType.SLEEP,
  ContentType.SPOT_IT,
  ContentType.TEST_IT,
  ContentType.SWITCH_IT,
  ContentType.COPING_CARDS,
  ContentType.MYPLAN_REVIEW,
  ContentType.REDUCE_RISK,
  ContentType.POST_AVIVA,
];
function UserSubscreen(props: { onNavigate: (action: NavigationActions.Action) => void }) {
  const [completeThrough, setCompleteThrough] = useState(V2_SESSION_ORDER[0]);
  const isLoggedIn = useIsLoggedIn();
  const { data: currentUser } = useCurrentUser();
  const logout = useLogout();
  const userID = currentUser?.currentUser?.user?.ID;
  const apollo = useApolloClient();

  return (
    <View spacing={8}>
      <Text text={`Logged in?: ${isLoggedIn ? 'yes' : 'no'}`} />
      {isLoggedIn ? (
        <View spacing={8}>
          <Text text={`ID: ${userID}`} />
          <Text text={`Email: ${currentUser?.currentUser?.user?.person.email}`} />
          <View spacing={8}>
            <View>
              <Text weight="bold" text="Complete sessions through:" />
              <View row spacing={16}>
                <View flex={1}>
                  <PickerInput
                    items={V2_SESSION_ORDER.map((s) => ({ label: s, value: s }))}
                    value={completeThrough}
                    onChangeValue={setCompleteThrough}
                  />
                </View>
                <Button
                  text="Submit"
                  onPress={() => {
                    const promises: Promise<unknown>[] = [];
                    for (let index = 0; index < V2_SESSION_ORDER.length; index = index + 1) {
                      const session = V2_SESSION_ORDER[index];
                      promises.push(mockConversationCompleteV2(apollo, session));
                      if (session === completeThrough) break;
                    }
                    return Promise.all(promises);
                  }}
                />
              </View>
            </View>
            <Button text="Logout" onPress={logout} alignSelf="flex-start" />
          </View>
        </View>
      ) : (
        <View spacing={8}>
          <Button
            text="Create test user"
            onPress={() => {
              props.onNavigate(NavigationActions.navigate({ name: 'CreateTestUser' }));
            }}
            alignSelf="flex-start"
          />
        </View>
      )}
    </View>
  );
}

enum Subscreen {
  None = '',
  About = 'about',
  ReportError = 'reportError',
  User = 'user',
  Flags = 'flags',
  Other = 'other',
}

export function DevMenu({
  onNavigate,
  children,
}: {
  onNavigate: (action: NavigationActions.Action) => void;
  children: React.ReactNode;
}) {
  const [sentryId, setSentryId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [subscreen, setSubscreen] = useState<Subscreen>(Subscreen.None);
  const [locale, setLocale] = useState('');
  const [flagOverrides, setFlagOverrides] = useState<{ [key: string]: any }>({});
  const openTimeoutRef = useRef(0);
  const isLoggedIn = useIsLoggedIn();
  const appContext = useAppContext();
  const [disableLocalAuthentication, setDisableLocalAuthentication] = usePersistedState(
    'disableLocalAuthentication',
    false,
  );
  const apollo = useApolloClient();
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const openDevMenu = React.useCallback(() => {
    const id = Sentry.captureMessage('DevMenu opened');
    setSentryId(id);
    checkForUpdateAsync()
      .then(({ isAvailable }) => {
        if (isAvailable) {
          fetchUpdateAsync();
          setIsUpdateAvailable(true);
        }
      })
      .catch(Sentry.captureException);
    setShowModal(true);
  }, []);

  const [panResponder] = useState(() =>
    PanResponder.create({
      onMoveShouldSetPanResponder: (evt, gestureState) => {
        return gestureState.numberActiveTouches === (Constants.isDevice ? 3 : 2);
      },
      onPanResponderGrant: (evt, gestureState) => {
        openTimeoutRef.current = (setTimeout(
          () => {
            openDevMenu();
          },
          IS_PRODUCTION ? 1500 : 500,
        ) as any) as number;
      },
      onPanResponderRelease: () => {
        clearTimeout(openTimeoutRef.current);
      },
      onPanResponderTerminate: () => {
        clearTimeout(openTimeoutRef.current);
      },
    }),
  );

  useEffect(() => {
    if (Platform.OS === 'web') {
      (global as any).showDevMenu = openDevMenu;
      (global as any).showReportErrorMenu = () => {
        openDevMenu();
        setSubscreen(Subscreen.ReportError);
      };
    }
  }, [openDevMenu]);

  const overriddenAppContext = React.useMemo(() => {
    const flags = { ...appContext.flags, ...global.flags, ...flagOverrides };
    global.flags = flags;
    global.setFlags = setFlagOverrides;
    return {
      ...appContext,
      flags,
      locale: locale || appContext.locale,
    };
  }, [appContext, flagOverrides, locale]);

  return (
    <View style={{ flex: 1 }} {...panResponder.panHandlers}>
      <AppContext.Provider value={overriddenAppContext}>
        {children}
        <Modal
          visible={showModal}
          onRequestClose={() => {
            setShowModal(false);
            setSubscreen(Subscreen.None);
          }}
          _onBack={
            subscreen && !IS_SIMPLE_DEV_MENU
              ? () => {
                  setSubscreen(Subscreen.None);
                }
              : undefined
          }
          heading={subscreen ? invert(Subscreen)[subscreen] : 'Debug menu'}
          maxHeight={300}
        >
          <View>
            {subscreen === Subscreen.About ? (
              <View spacing={8}>
                <Text text={`Environment: ${environment}`} />
                <Text
                  text={`Latest Version: ${manifest.version ?? getNativeBuildVersionAsSemver()}`}
                />
                <Text text={`Build Version: ${Constants.nativeBuildVersion}`} />
                <Text text={`Sentry ID: ${sentryId}`} />
                <Text text={`Device ID: ${Constants.installationId}`} />
                <Text text={`Published at: ${manifest.publishedTime}`} />
                {manifest.releaseChannel ? (
                  <Text text={`Release channel: ${manifest.releaseChannel}`} />
                ) : null}
                <Text text={`API: ${getConfigString('apiUrl')}`} />
                <Text text={`Hermes: ${isHermes()}`} />
              </View>
            ) : subscreen === Subscreen.ReportError ? (
              <ReportErrorSubscreen
                onRequestClose={() => {
                  setShowModal(false);
                  setSubscreen(Subscreen.None);
                }}
              />
            ) : subscreen === Subscreen.Flags ? (
              <View spacing={8}>
                <Checkbox
                  label="Disable local authentication"
                  value={disableLocalAuthentication}
                  onChangeValue={setDisableLocalAuthentication}
                  horizontal
                />
                <PickerInput
                  label="Locale"
                  items={LOCALES.map((l) => ({ label: l, value: l }))}
                  value={locale}
                  onChangeValue={setLocale}
                />
                {(Object.keys(appContext.flags) as (keyof typeof appContext.flags)[]).map((key) => {
                  const flagType = typeof appContext.flags[key];
                  const value = flagOverrides[key] ?? global.flags[key] ?? appContext.flags[key];
                  const onChangeValue = (newValue: unknown) => {
                    setFlagOverrides({ ...flagOverrides, [key]: newValue });
                  };

                  return (
                    <View row key={key}>
                      {flagType === 'boolean' ? (
                        <Checkbox
                          value={value}
                          onChangeValue={onChangeValue}
                          label={key}
                          horizontal
                          disabled={key === 'useV2'}
                        />
                      ) : flagType === 'number' ? (
                        <NumberInput value={value} onChangeValue={onChangeValue} label={key} />
                      ) : null}
                    </View>
                  );
                })}
              </View>
            ) : subscreen === Subscreen.User ? (
              <UserSubscreen onNavigate={onNavigate} />
            ) : subscreen === Subscreen.Other ? (
              <View spacing={24}>
                <Button
                  text="Hope Kit"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'HopeKit',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Activity Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'ActivityDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Thought Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'ThoughtDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Sleep Diary"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'SleepDiary',
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Clear Fresco Memory Cache"
                  onPress={() => {
                    OuiModuleNative.clearFrescoMemoryCache();
                  }}
                />
                <Button
                  text="Open gallery"
                  onPress={() => {
                    onNavigate(
                      NavigationActions.navigate({
                        name: 'MediaPicker',
                        params: { returnRoute: 'home' },
                      }),
                    );
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Start MoodRating"
                  onPress={() => {
                    onNavigate(NavigationActions.navigate({ name: 'MoodRating' }));
                    setShowModal(false);
                  }}
                />
                <Button
                  text="Copy push token"
                  onPress={() => {
                    Notifications.getPermissionsAsync().then(({ status }) => {
                      Notifications.getExpoPushTokenAsync().then(({ data: token }) => {
                        Clipboard.setString(token);
                        if (isLoggedIn) {
                          setDeviceInfo(apollo);
                        }
                      });
                    });
                  }}
                />
                <Button
                  text="JS Error"
                  onPress={() => {
                    try {
                      ((global as any).qwer as any)();
                    } catch (e) {
                      Sentry.captureException(e);
                    }
                  }}
                />
                <Button
                  text="Crash"
                  onPress={() => {
                    Sentry.nativeCrash();
                  }}
                />
              </View>
            ) : (
              <View style={{ marginTop: -20 }}>
                {isUpdateAvailable ? (
                  <View row style={{ paddingVertical: 10, justifyContent: 'space-between' }}>
                    <Text text="Update available (force-quit to attempt update) " />
                  </View>
                ) : null}
                {Object.entries(Subscreen).map(([key, value]) => {
                  return value ? (
                    <View
                      key={key}
                      style={{
                        borderBottomWidth: 1,
                        borderColor: '#eee',
                        marginHorizontal: -20,
                        paddingHorizontal: 20,
                      }}
                    >
                      <WorksheetListItem text={key} onPress={() => setSubscreen(value)} />
                    </View>
                  ) : null;
                })}
              </View>
            )}
          </View>
        </Modal>
      </AppContext.Provider>
    </View>
  );
}
