import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import * as FileSystem from 'expo-file-system';

import Sentry from '@src/sentry';
import { addBreadcrumb } from '@src/lib/log';
import {
  SessionUri,
  initializeResumableUpload,
  checkResumableUpload,
  uploadResumableChunk,
} from '@src/lib/resumableUpload';
import { retry } from '@lib/retry';

const CANCELED_UPLOAD_URI = '__CANCELED__UPLOAD__' as SessionUri;
const ASYNC_STORAGE_KEY = 'resumableUploadManagerData';

type PendingUpload<T = unknown> = {
  sourceUri: string;
  sessionUri: SessionUri;
  cacheKey?: string;
  metaData?: T;
};
type Data = { pendingUploads: PendingUpload[] };

const DEFAULT_DATA = { pendingUploads: [] };
const MAX_UPLOADS_IN_FLIGHT = 4;

let cachedData: Data | null = null;
let blobCache: Record<string, Blob | undefined> = {};
let uploadsInFlight = new Set<string>();

async function loadData(): Promise<Data> {
  if (cachedData) return cachedData;
  if (Platform.OS === 'web') {
    cachedData = DEFAULT_DATA;
  } else {
    const persistedData = await AsyncStorage.getItem(ASYNC_STORAGE_KEY);
    cachedData = persistedData ? (JSON.parse(persistedData) as Data) : DEFAULT_DATA;
  }
  return cachedData;
}

async function saveData(data: Data) {
  cachedData = data;
  if (Platform.OS !== 'web') {
    await AsyncStorage.setItem(ASYNC_STORAGE_KEY, JSON.stringify(data));
  }
}

async function getSessionUriForUri(uri: string) {
  const data = await loadData();
  const uploadEntry = data.pendingUploads.find((pending) => pending.sourceUri === uri);
  return uploadEntry?.sessionUri;
}

async function getBlobForUri(uri: string) {
  const cachedBlob = blobCache[uri];
  if (cachedBlob) return cachedBlob;
  const blob = await (await fetch(uri)).blob();
  blobCache[uri] = blob;
  return blob;
}

async function completeUpload(uri: string) {
  const data = await loadData();
  addBreadcrumb({
    category: 'resumableUploadManager',
    message: uri === CANCELED_UPLOAD_URI ? 'canceledUpload' : 'completeUpload',
    data: {
      uploadsInFlight: uploadsInFlight.size,
      MAX_UPLOADS_IN_FLIGHT,
      uri,
      pendingUploadsLength: data.pendingUploads.length,
    },
  });
  uploadsInFlight.delete(uri);
  const newData = {
    pendingUploads: data.pendingUploads.filter((pending) => pending.sourceUri !== uri),
  };
  await saveData(newData);
  if (uri.endsWith('.toupload')) {
    await FileSystem.deleteAsync(uri, { idempotent: true });
  }
  await startPendingUploads();
}

export function getPendingUploadByCacheKey<T>(cacheKey: string) {
  return cachedData?.pendingUploads.find((up) => up.cacheKey === cacheKey) as
    | PendingUpload<T>
    | undefined;
}

async function startUpload(uri: string, startByte?: number) {
  addBreadcrumb({
    category: 'resumableUploadManager',
    message: 'startUpload',
    data: {
      uploadsInFlight: uploadsInFlight.size,
      MAX_UPLOADS_IN_FLIGHT,
      uri,
      startByte,
      isInFlightUri: uploadsInFlight.has(uri),
    },
  });
  if (uploadsInFlight.size >= MAX_UPLOADS_IN_FLIGHT && !uploadsInFlight.has(uri)) return;
  uploadsInFlight.add(uri);
  const sessionUri = await getSessionUriForUri(uri);
  if (!sessionUri) {
    Sentry.captureMessage('resumableUploadManager startUpload missing sessionUri', {
      extra: {
        uploadsInFlight: uploadsInFlight.size,
        MAX_UPLOADS_IN_FLIGHT,
        uri,
        startByte,
        isInFlightUri: uploadsInFlight.has(uri),
      },
    });
    return;
  }

  if (sessionUri === CANCELED_UPLOAD_URI) {
    return completeUpload(uri);
  }

  if (typeof startByte !== 'number') {
    const { isComplete, lastByte } = await checkResumableUpload(sessionUri);
    if (isComplete) return await completeUpload(uri);
    startByte = lastByte + 1;
  }
  const blob = await getBlobForUri(uri);
  const { isComplete, lastByte } = await uploadResumableChunk(sessionUri, blob, startByte);

  const progressPayload = isComplete
    ? { progress: blob.size, total: blob.size, percent: 100 }
    : {
        progress: lastByte,
        total: blob.size,
        percent: Math.round((lastByte / blob.size) * 100),
      };

  addBreadcrumb({
    category: 'resumableUploadManager',
    message: 'startUpload result',
    data: {
      uploadsInFlight: uploadsInFlight.size,
      MAX_UPLOADS_IN_FLIGHT,
      uri,
      isComplete,
      startByte,
      lastByte,
      progressPayload,
    },
  });

  progressListeners[uri]?.forEach((cb) => cb(progressPayload));
  if (isComplete) {
    await completeUpload(uri);
  } else {
    startUploadWithRetries(uri, lastByte + 1);
  }
}

async function startUploadWithRetries(uri: string, startByte?: number) {
  try {
    await retry(
      async () => {
        try {
          return await startUpload(uri, startByte);
        } catch (e: any) {
          if ([400, 401].includes(e.status)) {
            Sentry.captureException(e, { extra: { uri, startByte } });
            const data = await loadData();
            await saveData({
              pendingUploads: data.pendingUploads.filter((pending) => pending.sourceUri !== uri),
            });
          } else {
            throw e;
          }
        }
      },
      {
        timeout: 60000,
        logger: (message) => {
          Sentry.addBreadcrumb({
            message: 'startUploadWithRetries retry result',
            data: { uri, startByte, message },
          });
        },
      },
    );
  } catch (e: any) {
    Sentry.captureException(e, { extra: { uri, startByte } });
    Sentry.captureException('ResumableUploadManager error', {
      extra: { uri, startByte, err: e.toString() },
    });
  }
}

export async function startPendingUploads() {
  const data = await loadData();
  for (let i = 0; i < data.pendingUploads.length; i++) {
    if (uploadsInFlight.size >= MAX_UPLOADS_IN_FLIGHT) break;
    const uri = data.pendingUploads[i].sourceUri;
    if (uploadsInFlight.has(uri)) continue;
    startUploadWithRetries(uri);
  }
}

type ProgressCallback = (payload: { progress: number; total: number; percent: number }) => void;
const progressListeners: Record<string, ProgressCallback[] | undefined> = {};
export function addListener(uri: string, callback: ProgressCallback) {
  if (!progressListeners[uri]) {
    progressListeners[uri] = [];
  }
  progressListeners[uri]?.push(callback);
  return () => {
    const remainingListeners = progressListeners[uri]?.filter((l) => l !== callback) ?? [];
    if (remainingListeners.length) {
      progressListeners[uri] = remainingListeners;
    } else {
      delete progressListeners[uri];
    }
  };
}

export async function cancelUploadFile(uri: string) {
  const data = await loadData();
  const newData = {
    pendingUploads: data.pendingUploads.map((pending) =>
      pending.sourceUri === uri ? { ...pending, sessionUri: CANCELED_UPLOAD_URI } : pending,
    ),
  };
  await saveData(newData);
}

export async function uploadFile(
  uri: string,
  sessionUri?: SessionUri,
  cacheOptions?: { cacheKey: string; metaData: unknown },
) {
  const fileName = uri.split('/').reverse()[0];
  let blob = await getBlobForUri(uri);

  // Workaround for https://github.com/facebook/react-native/issues/27099
  if (Platform.OS === 'ios' && blob.type === 'image/jpeg') {
    const originalUri = uri;
    uri = `${FileSystem.cacheDirectory}resumableUploadManager-${fileName}.toupload`;
    await FileSystem.copyAsync({ from: originalUri, to: uri });
    blob = new Blob([await getBlobForUri(uri)], { type: 'image/jpeg' });
  }

  try {
    sessionUri = sessionUri ?? (await initializeResumableUpload(blob, fileName));
    const data = await loadData();
    await saveData({
      pendingUploads: [
        ...data.pendingUploads,
        {
          sourceUri: uri,
          sessionUri,
          cacheKey: cacheOptions?.cacheKey,
          metaData: cacheOptions?.metaData,
        },
      ],
    });
  } catch (e) {
    Sentry.captureException(e);
  }
  startUploadWithRetries(uri, 0);
}
