import React from 'react';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import hexToRgba from 'hex-to-rgba';

import RoundedSection from '@src/screens/RoundedSection';
import { View } from '@src/components/View';
import { Text } from '@src/components/Text';
import { SuicidalModeCard as Card } from '@src/components/MyStoryMyPlanCards';
import { SuicidalMode as SuicidalModeType } from '@src/metadata/suicidalModeQuestions';
import { Color } from '@src/styles';
import { useAppContext } from '@src/hooks/useAppContext';
import { CheckboxForm, CheckboxFormQuestion } from '@src/components/CheckboxFormInput';
import { CheckboxAnswerList } from '@src/components/CheckboxFormInput';
import { getTranslations } from '@src/lib/i18n';
import MyStoryMyPlanContainer from '@src/components/MyStoryMyPlanContainer';

import { useSuicidalModeQuery } from './SuicidalMode.graphql.generated';

export const SectionLabel = ({ title, style }: { style?: StyleProp<ViewStyle>; title: string }) => {
  return (
    <View
      style={[
        {
          elevation: 10,
          backgroundColor: 'white',
          padding: 8,
          alignSelf: 'center',
          paddingHorizontal: 40,
          zIndex: 1,
          borderRadius: 32,
        },
        style,
      ]}
    >
      <Text text={title} style={{ textAlign: 'center' }} weight="bold" color={Color.primary} />
    </View>
  );
};

export default function SuicidalMode(props: { route?: { name: string }; preview?: boolean }) {
  const { locale } = useAppContext();
  const CheckboxesWithQuestions = getTranslations<
    SuicidalModeType<CheckboxForm<CheckboxFormQuestion>>
  >(locale, 'suicidal_mode_form.questions');
  const { data } = useSuicidalModeQuery({ variables: {} });
  const suicidalModeData: SuicidalModeType | undefined = data as any;
  const isInMyStoryMyPlan = props.route?.name === 'SuicidalModeForm';

  const inner = (
    <View
      style={{
        flex: 1,
        backgroundColor: Color.secondary,
        marginHorizontal: isInMyStoryMyPlan ? -26 : -10,
        padding: 10,
      }}
    >
      <SectionLabel title="Baseline" style={[{ marginBottom: -5 }]} />
      <View style={[styles.sectionContainer]}>
        <Card title="Risk factors for suicide">
          <CheckboxAnswerList
            questions={CheckboxesWithQuestions.riskFactors}
            answers={suicidalModeData?.riskFactors}
          />
        </Card>
      </View>
      <View
        style={[
          {
            width: 3,
            alignSelf: 'center',
            backgroundColor: Color.tertiary,
            height: 30,
          },
        ]}
      />
      <View
        style={[
          styles.sectionContainer,
          {
            alignSelf: 'center',
          },
        ]}
      >
        <View row style={{ minWidth: '80%' }}>
          <Card title="Activating Events">
            <CheckboxAnswerList
              questions={CheckboxesWithQuestions.activatingEvents}
              answers={suicidalModeData?.activatingEvents}
            />
          </Card>
        </View>
      </View>
      <View
        style={[
          {
            width: 3,
            alignSelf: 'center',
            backgroundColor: Color.tertiary,
            height: 30,
          },
        ]}
      />
      <View
        style={[
          {
            borderWidth: 3,
            width: 20,
            alignSelf: 'center',
            borderColor: Color.tertiary,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            height: 20,
            transform: [{ rotateZ: '45deg' }],
            marginTop: -20,
            marginBottom: 5,
          },
        ]}
      />
      <SectionLabel title="Suicidal crisis" style={[{ marginBottom: -5 }]} />
      <View style={[styles.sectionContainer]} spacing={8}>
        <View row spacing={8}>
          <Card title="Thoughts">
            <CheckboxAnswerList
              questions={CheckboxesWithQuestions.cognitive}
              answers={suicidalModeData?.cognitive}
            />
          </Card>
          <Card title="Emotions">
            <CheckboxAnswerList
              questions={CheckboxesWithQuestions.emotional}
              answers={suicidalModeData?.emotional}
            />
          </Card>
        </View>
        <View row spacing={8}>
          <Card title="Behaviors">
            <CheckboxAnswerList
              questions={CheckboxesWithQuestions.behavioral}
              answers={suicidalModeData?.behavioral}
            />
          </Card>
          <Card title="Physical">
            <CheckboxAnswerList
              questions={CheckboxesWithQuestions.physiological}
              answers={suicidalModeData?.physiological}
            />
          </Card>
        </View>
      </View>
    </View>
  );

  return isInMyStoryMyPlan ? (
    <MyStoryMyPlanContainer
      progress={4}
      heading="Suicidal Mode Framework"
      testID="SuicidalModeForm"
      onNext={() => {
        (props as any).navigation.push('MyPlanExplanation');
      }}
    >
      {inner}
    </MyStoryMyPlanContainer>
  ) : (
    <RoundedSection
      color="white"
      secondaryColor={Color.secondary}
      title="Suicidal Mode"
      preview={props.preview}
    >
      {inner}
    </RoundedSection>
  );
}
const styles = StyleSheet.create({
  disabled: { borderColor: hexToRgba(Color.tertiary, 0.3) },
  sectionContainer: {
    borderWidth: 3,
    borderColor: Color.tertiary,
    borderRadius: 8,
    padding: 8,
  },
});
