import { ApolloClient, ApolloProvider, useApolloClient } from '@apollo/client';
import React from 'react';
import { AppState, AppStateStatus, Platform, StatusBar, Linking as RNLinking } from 'react-native';
import {
  DefaultTheme,
  DarkTheme,
  CommonActions as NavigationActions,
  getStateFromPath,
  InitialState,
  getPathFromState,
  getActionFromState,
  NavigationContainerRef,
  NavigationContainer,
  NavigationState,
} from '@react-navigation/native';
import { stringify } from 'query-string';
import { MenuProvider, Menu, renderers } from 'react-native-popup-menu';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import LogBox from '@src/lib/LogBox';
import * as SplashScreen from '@src/lib/splashScreen';
import Sentry, { routingInstrumentation } from '@src/sentry';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { AppearanceProvider } from 'react-native-appearance';
import { locale } from 'expo-localization';

import AppContext, { DeeplinkOptions } from '@src/components/AppContext';
import { View } from '@src/components/View';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { DevMenu } from '@src/components/DevMenu';
import { OrientationProvider } from '@src/components/OrientationContext';
import { APP_SLUG, FLAGS } from '@src/constants';
import { parseUrl } from '@src/lib/parseUrl';
import { Severity, addBreadcrumb, setUserProperties } from '@src/lib/log';
import { Shadow, useColor } from '@src/styles';
import { setDeviceInfo } from '@src/lib/setDeviceInfo';
import * as Notifications from '@src/lib/notifications';
import { shouldShowWebBlocker, AppError, initApp } from '@src/screens/Loading';
import { LogoutProvider } from '@src/hooks/useLogout';
import { setPersistedState } from '@src/hooks/usePersistedState';
import { AuthParamList, DeeplinkConfigShape, DetoxNotificationData } from '@src/types';
import OuiModuleNative from '@src/lib/OuiModuleNative';
import { PermissionsManagerProvider } from '@src/lib/permissionsManager';
import AsyncStorage from '@react-native-community/async-storage';
import { startPendingUploads } from '@src/lib/resumableUploadManager';
import { AccessibilityProvider } from '@src/components/AccessibilityContext';
import { InternetConnectivityProvider } from '@src/components/InternetConnectivityProvider';
import { checkForUpdateAsync } from '@src/lib/checkForUpdateAsync';
import { CurrentUserQuery, queryCurrentUser } from '@src/hooks/useCurrentUser.graphql.generated';

const LATEST_NAVIGATION_STATE_KEY = `${APP_SLUG}:lastNavigationState`;

Menu.setDefaultRenderer(renderers.Popover);
Menu.setDefaultRendererProps({ placement: 'bottom', anchorStyle: [Shadow.default] });
// TODO(rn5) build a theme from styles.ts
DefaultTheme.colors.background = 'white';
LogBox.ignoreLogs([
  'Accessing view manager configs',
  'componentWillMount',
  'componentWillReceiveProps',
]);

const AUTH_DEEPLINK_CONFIG: DeeplinkConfigShape<keyof AuthParamList> = {
  Reauthenticate: 'reauthenticate',
  Login: 'auth/login',
  RequestResetPassword: 'auth/requestreset',
  ResetPassword: 'auth/reset',
  SignUp: 'auth/signup',
};

type AppInnerProps = {
  app: React.ElementType;
  initialPath: string | ((data: { ouiUser: CurrentUserQuery['currentUser'] }) => string);
  welcomePath?: string;
  deeplinkConfig: { screens: DeeplinkOptions };
};
const AppContainerInner = React.forwardRef<NavigationContainerRef, AppInnerProps>((props, ref) => {
  const [initialNavigationState, setInitialNavigationState] = React.useState<InitialState>();
  const { flags } = React.useContext(AppContext);
  const { scheme } = useColor();
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const navigator = React.useRef<NavigationContainerRef>(null);
  const initialNotification = React.useRef<Notifications.NotificationResponse>();
  const handleNotificationRef = React.useRef<typeof handleNotification>();
  const Main = props.app;
  const deeplinkConfigRef = React.useRef<{ screens: DeeplinkOptions }>(props.deeplinkConfig);
  const routeNameRef = React.useRef<string>();
  const latestStateRef = React.useRef<NavigationState>();
  const apollo = useApolloClient();
  const initialPath = props.initialPath;
  const [forceOffline, setForceOffline] = React.useState(false);

  React.useImperativeHandle(ref, () => navigator.current!);

  const handleNotification = React.useCallback(
    (notification: Notifications.NotificationResponse, skipInitialCheck = false) => {
      console.log({
        notification: JSON.parse(JSON.stringify(notification)),
        skipInitialCheck,
        navigator: !!navigator.current,
        initialNotification,
        loading,
      });
      if (!skipInitialCheck) {
        if (loading) {
          initialNotification.current = notification;
          return;
        }
      }

      addBreadcrumb({ category: 'notification', data: notification });
      Notifications.dismissNotificationAsync(notification.notification.request.identifier);
      const content = notification.notification.request.content;
      const data: {
        type: 'navigate';
        payload: { name?: string; routeName: string; params?: object };
      } = JSON.parse(content.body!);
      if (data.type === 'navigate') {
        const payload = {
          name: data.payload.name ?? data.payload.routeName,
          params: data.payload.params,
        };
        const paramsStr = payload.params ? stringify(payload.params) : '';
        const state = getStateFromPath(
          `${payload.name}${paramsStr ? `?${paramsStr}` : ''}`,
          deeplinkConfigRef.current,
        )!;
        if (navigator.current) {
          navigator.current?.dispatch(getActionFromState(state)!);
        } else {
          setInitialNavigationState(state);
        }
      }
    },
    [loading],
  );
  handleNotificationRef.current = handleNotification;

  React.useEffect(() => {
    const onAppState = (status: AppStateStatus) => {
      addBreadcrumb({ category: 'app-state', data: { status }, level: Severity.Debug });
    };
    const onFocus = () => {
      addBreadcrumb({ category: 'app-state-focus', level: Severity.Debug });
    };
    const onBlur = () => {
      addBreadcrumb({ category: 'app-state-blur', level: Severity.Debug });
    };
    const onMemoryWarning = (data: unknown) => {
      addBreadcrumb({ category: 'app-state-memory', data: { data }, level: Severity.Debug });
      OuiModuleNative.clearFrescoMemoryCache();
    };

    startPendingUploads();
    AppState.addEventListener('change', onAppState);
    AppState.addEventListener('memoryWarning', onMemoryWarning);
    if (Platform.OS === 'android') {
      AppState.addEventListener('blur', onBlur);
      AppState.addEventListener('focus', onFocus);
    }

    return () => {
      AppState.removeEventListener('change', onAppState);
      AppState.removeEventListener('memoryWarning', onMemoryWarning);
      if (Platform.OS === 'android') {
        AppState.removeEventListener('blur', onBlur);
        AppState.removeEventListener('focus', onFocus);
      }
    };
  }, []);

  React.useEffect(() => {
    const handleOpenURL = ({ url }: { url: string }) => {
      const parsedUrl = parseUrl(url);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      addBreadcrumb({
        category: 'navigation',
        message: 'handleOpenURL',
        data: { url, parsedUrl, initialURLState },
      });

      if (global.e2e && parsedUrl?.path === 'DETOX_NOTIFICATION') {
        const data: DetoxNotificationData = JSON.parse(parsedUrl.params.data);
        if (data.type === 'NETWORK') {
          setForceOffline(!data.payload.isInternetReachable);
        }

        return;
      }

      if (parsedUrl?.params.skipLocalAuthenticationPrompt) {
        setPersistedState(
          'SeenLocalAuthenticationPrompt',
          JSON.parse(parsedUrl.params.skipLocalAuthenticationPrompt),
        );
      }
    };

    let handleNotificationSubscription: ReturnType<typeof Notifications.addNotificationResponseReceivedListener>;
    if (Platform.OS !== 'web') {
      handleNotificationSubscription = Notifications.addNotificationResponseReceivedListener(
        handleNotification,
      );
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
        }),
      });
    }
    RNLinking.addEventListener('url', handleOpenURL);

    return () => {
      RNLinking.removeEventListener('url', handleOpenURL);
      if (handleNotificationSubscription) {
        handleNotificationSubscription.remove();
      }
      Notifications.setNotificationHandler(null);
    };
  }, [handleNotification]);

  const init = React.useCallback(async () => {
    try {
      const { user: currentUser, reauthenticate, initialURL } = await initApp();

      let productUser: CurrentUserQuery['currentUser'] | null = null;

      if (currentUser) {
        try {
          let r = await queryCurrentUser(apollo, undefined, {
            fetchPolicy: 'cache-first',
          });
          // If the user has changed from the previously cached user, we should wipe the local
          // store state so that a different user's information is not shown.
          // This scenario typically shouldn't happen since the only way to switch users should
          // be via a signOut/signIn, but it's an extra layer of protection in case somehow the
          // user does change without going through the normal cleanup flow.
          if (r.data.currentUser?.user?.person.email !== currentUser.uid) {
            await apollo.clearStore();
            r = await queryCurrentUser(apollo, undefined, {
              fetchPolicy: 'network-only',
            });
          }
          productUser = r.data.currentUser;
          if (APP_SLUG === 'oui-aviva' && productUser?.user?.__typename === 'Patient') {
            setUserProperties({
              productVersion: productUser.user.productVersion,
              productVariant: productUser.user.productVariant,
              onboardingVariant: productUser.user.onboardingVariant,
            });
          }
        } catch (e) {
          Sentry.captureException(e, (scope) => {
            scope.setExtras({ location: 'preload productUser' });
            return scope;
          });
        }
        setDeviceInfo(apollo);
      }

      const parsedUrl = parseUrl(initialURL);
      const initialURLState = parsedUrl?.path
        ? getStateFromPath(parsedUrl.pathWithQuery, deeplinkConfigRef.current)
        : undefined;

      Sentry.addBreadcrumb({
        message: 'init',
        data: {
          initialURLState,
          parsedUrl,
          initialURL,
        },
      });

      const persistedStateStr = await AsyncStorage.getItem(LATEST_NAVIGATION_STATE_KEY);

      const getPrimaryNavigationState = (isReauth: boolean = false): InitialState | null => {
        if (persistedStateStr) {
          AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY);
          const persistedState: NavigationState = JSON.parse(persistedStateStr);
          return persistedState;
        } else if (initialNotification.current) {
          return null;
        } else if (
          initialURL &&
          parsedUrl &&
          initialURLState &&
          !(isReauth && parsedUrl.isAuthDeeplink)
        ) {
          return initialURLState;
        } else if (shouldShowWebBlocker()) {
          return getStateFromPath('WebBlocker', deeplinkConfigRef.current)!;
        }

        return getStateFromPath(
          typeof initialPath === 'function' ? initialPath({ ouiUser: productUser }) : initialPath,
          deeplinkConfigRef.current,
        )!;
      };

      const primaryNavigation = (isReauth: boolean = false) => {
        const setInitialStateOrDispatch = (state: InitialState) => {
          if (navigator.current) {
            navigator.current.dispatch(NavigationActions.reset(state));
          } else {
            setInitialNavigationState(state);
          }
        };

        if (initialNotification.current) {
          console.log('handleNotificationRef primaryNav');
          handleNotificationRef.current!(initialNotification.current, true);
          initialNotification.current = undefined;
        } else {
          setInitialStateOrDispatch(getPrimaryNavigationState(isReauth)!);
        }
      };

      if (APP_SLUG === 'oui-jco') {
        primaryNavigation();
        setLoading(false);
        return;
      }

      // if existing credentials, go to App Home / deeplink
      // if previously logged in, but currently logged out show Login instead of Welcome
      // if app was opened normally and no user present, show "Welcome"
      const primaryState = getPrimaryNavigationState(true);
      if (reauthenticate && primaryState?.routes?.[0]?.name !== 'Reauthenticate') {
        const params: AuthParamList['Reauthenticate'] = primaryState
          ? {
              reauthenticate: 'true',
              replace: 'true',
              reauthPath: getPathFromState(primaryState, deeplinkConfigRef.current),
            }
          : {
              reauthenticate: 'true',
              onReauthentication: () => primaryNavigation(true),
            };

        setInitialNavigationState({
          routes: [
            {
              name: 'Reauthenticate',
              params,
            },
          ],
        });
      } else if (currentUser) {
        primaryNavigation();
      } else if (parsedUrl?.isAuthDeeplink) {
        primaryNavigation();
      } else {
        setInitialNavigationState(getStateFromPath(props.welcomePath ?? 'Welcome'));
      }

      setLoading(false);
      setError(false);
    } catch (e) {
      Sentry.captureException(e);
      setError(true);
    } finally {
      if (Platform.OS !== 'web') {
        SplashScreen.hideAsync();
      }
    }
  }, [props.welcomePath, initialPath, apollo]);

  React.useEffect(() => {
    init();
  }, [init]);

  return error ? (
    <AppError retry={init} />
  ) : loading ? null : (
    <>
      <StatusBar
        barStyle={flags.allowDarkTheme && scheme === 'dark' ? 'light-content' : 'dark-content'}
        translucent
        backgroundColor="#00000000"
      />
      <PermissionsManagerProvider
        onOpenSettings={() => {
          if (!latestStateRef.current) return Promise.resolve();
          return AsyncStorage.setItem(
            LATEST_NAVIGATION_STATE_KEY,
            JSON.stringify(latestStateRef.current),
          );
        }}
        onCloseSettings={() => {
          return AsyncStorage.removeItem(LATEST_NAVIGATION_STATE_KEY);
        }}
      >
        <InternetConnectivityProvider forceOffline={forceOffline}>
          <NavigationContainer
            linking={{
              prefixes: [
                'aviva://',
                'avivastaff://',
                'https://oui.health',
                'https://oui.dev',
                'https://*.oui.dev',
                'https://*.oui.health',
              ],
              config: deeplinkConfigRef.current,
            }}
            documentTitle={{
              // we disable document title handling for two reasons
              // 1) it messes up screen capture in detox-puppeteer (b/c we select the video source by doc title)
              // 2) we need to create a better custom formatter and it's not immediately obvious what the logic is
              enabled: false,
            }}
            initialState={initialNavigationState}
            theme={flags.allowDarkTheme && scheme === 'dark' ? DarkTheme : DefaultTheme}
            onReady={() => {
              routingInstrumentation.registerNavigationContainer(navigator as any);
            }}
            onStateChange={(state) => {
              latestStateRef.current = state;
              // console.log('onStateChange', state);
              // if (state) console.log(getPathFromState(state, deeplinkConfigRef.current));
              if (!state) return;
              const currentRoute = navigator.current?.getCurrentRoute();
              const currentScreen = currentRoute?.name;
              if (routeNameRef.current !== currentScreen) {
                routeNameRef.current = currentScreen;
              }
              addBreadcrumb({
                category: 'navigation',
                data: {
                  state,
                  path: getPathFromState(state, deeplinkConfigRef.current),
                  name: currentRoute?.name,
                  params: currentRoute?.params,
                },
                level: Severity.Debug,
              });
            }}
            ref={navigator}
          >
            <Main />
          </NavigationContainer>
        </InternetConnectivityProvider>
      </PermissionsManagerProvider>
    </>
  );
});

function WebUpdateAvailableToast({ onDismiss }: { onDismiss: () => void }) {
  const { Color } = useColor();
  return (
    <View
      style={[
        {
          position: 'absolute',
          bottom: 20,
          right: 20,
          width: 300,
          padding: 20,
          borderRadius: 20,
          backgroundColor: Color.grayBackground,
        },
        Shadow.default,
      ]}
      spacing={10}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <Text text="Update available" weight="semibold" />
        <Icon accessibilityLabel="Dismiss" name="close" size={14} onPress={onDismiss} />
      </View>
      <Text
        text="A new version of the website is available. Please refresh to get the latest features and improvements."
        size={13}
      />
      <Button
        variant="text"
        text="Update"
        alignSelf="flex-end"
        compact
        onPress={() => {
          global.window.location.reload();
        }}
      />
    </View>
  );
}

export default class AppContainer extends React.Component<
  AppInnerProps & {
    apollo: ApolloClient<unknown>;
  },
  { hasError: boolean; updateAvailable: boolean; navigator: NavigationContainerRef | null }
> {
  state = { hasError: false, updateAvailable: false, navigator: null };
  lastUpdateCheckAt = Date.now();
  deeplinkConfig?: { screens: DeeplinkOptions };

  static getDerivedStateFromError() {
    return { navigator: null, hasError: true };
  }

  componentDidMount() {
    if (Platform.OS === 'web' && APP_SLUG !== 'oui-aviva') {
      AppState.addEventListener('change', (nextStatus) => {
        if (nextStatus === 'active' && Date.now() - this.lastUpdateCheckAt > 60 * 60 * 1000) {
          this.lastUpdateCheckAt = Date.now();
          checkForUpdateAsync().then(({ isAvailable }) => {
            if (isAvailable) {
              this.setState({ updateAvailable: true });
            }
          });
        }
      });
    }
    this.deeplinkConfig = {
      screens: {
        ...this.props.deeplinkConfig.screens,
        ...AUTH_DEEPLINK_CONFIG,
      },
    };
  }

  componentDidCatch(e: Error, info: object) {
    Sentry.withScope((scope) => {
      scope.setExtra('info', info);
      Sentry.captureException(e);
    });
  }

  dispatch = (action: NavigationActions.Action) => {
    const navigator: NavigationContainerRef = this.state.navigator!;
    navigator?.dispatch(action);
  };

  render() {
    return (
      <AppearanceProvider>
        <AppContext.Provider
          value={{
            navigationContainer: this.state.navigator,
            flags: FLAGS,
            locale,
            deeplinkConfig: this.deeplinkConfig,
          }}
        >
          <AccessibilityProvider>
            <SafeAreaProvider>
              <ActionSheetProvider>
                <ApolloProvider client={this.props.apollo}>
                  <OrientationProvider>
                    <LogoutProvider>
                      <MenuProvider>
                        <DevMenu onNavigate={this.dispatch}>
                          {this.state.hasError ? (
                            <AppError
                              retry={() => this.setState({ hasError: false })}
                              type="runtime"
                            />
                          ) : (
                            <AppContainerInner
                              app={this.props.app}
                              ref={(r) => {
                                if (!this.state.navigator && r) {
                                  this.setState({ navigator: r });
                                }
                              }}
                              welcomePath={this.props.welcomePath}
                              initialPath={this.props.initialPath}
                              deeplinkConfig={
                                this.deeplinkConfig ?? {
                                  screens: {
                                    ...this.props.deeplinkConfig.screens,
                                    ...AUTH_DEEPLINK_CONFIG,
                                  },
                                }
                              }
                            />
                          )}
                          {this.state.updateAvailable ? (
                            <WebUpdateAvailableToast
                              onDismiss={() => this.setState({ updateAvailable: false })}
                            />
                          ) : null}
                        </DevMenu>
                      </MenuProvider>
                    </LogoutProvider>
                  </OrientationProvider>
                </ApolloProvider>
              </ActionSheetProvider>
            </SafeAreaProvider>
          </AccessibilityProvider>
        </AppContext.Provider>
      </AppearanceProvider>
    );
  }
}
