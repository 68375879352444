import { useMutation, useQuery, gql as gqlDeprecated } from '@apollo/client';
import React, { useRef } from 'react';
import { StyleSheet } from 'react-native';
import produce from 'immer';
import hexToRgba from 'hex-to-rgba';
import debounce from 'lodash/debounce';

import ApolloClient from '@src/lib/apollo';
import { View } from '@src/components/View';
import { Button } from '@src/components/Button';
import { SectionLabel } from '@src/screens/SuicidalMode';
import { SuicidalModeCard as Card } from '@src/components/MyStoryMyPlanCards';
import CheckboxFormInput, {
  getTextListFromAnswerEntries,
  CheckboxForm,
  CheckboxFormQuestion,
  CheckboxAnswerList,
} from '@src/components/CheckboxFormInput';
import { Color } from '@src/styles';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';
import { useAppContext } from '@src/hooks/useAppContext';
import { useForm } from '@src/hooks/useForm';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import {
  SaveSuicidalModalAnswer,
  SaveSuicidalModalAnswerVariables,
} from './__generated__/SaveSuicidalModalAnswer';
import {
  GetSuicidalModeAnswers,
  GetSuicidalModeAnswersVariables,
} from './__generated__/GetSuicidalModeAnswers';
import { SuicidalMode } from '@src/metadata/suicidalModeQuestions';
import MyStoryMyPlanContainer from '@src/components/MyStoryMyPlanContainer';
import { useT, TranslationFunction, getTranslations } from '@src/lib/i18n';

const MUTATION = gqlDeprecated`
  mutation SaveSuicidalModalAnswer($patientID: UserID!, $key: String!, $value: Any!) {
    respondForPatient(patientID: $patientID, context: "suicidalMode", key: $key, data: $value)
  }
`;

const QUERY = gqlDeprecated`
  query GetSuicidalModeAnswers($patientID: UserID!) {
    activatingEvents: patientResponse(
      patientID: $patientID
      context: "suicidalMode"
      key: "activatingEvents"
    )
    behavioral: patientResponse(patientID: $patientID, context: "suicidalMode", key: "behavioral")
    cognitive: patientResponse(patientID: $patientID, context: "suicidalMode", key: "cognitive")
    emotional: patientResponse(patientID: $patientID, context: "suicidalMode", key: "emotional")
    physiological: patientResponse(
      patientID: $patientID
      context: "suicidalMode"
      key: "physiological"
    )
    riskFactors: patientResponse(patientID: $patientID, context: "suicidalMode", key: "riskFactors")
  }
`;

const DEFAULT_SUICIDAL_MODE = {
  activatingEvents: {},
  behavioral: {},
  cognitive: {},
  emotional: {},
  physiological: {},
  riskFactors: {},
};

function getNextSection(
  current: keyof SuicidalMode | '',
  data: SuicidalMode,
): keyof SuicidalMode | '' {
  if (Object.keys(data.behavioral).length) {
    return '';
  }

  if (current == '') {
    return 'riskFactors';
  } else if (current === 'riskFactors') {
    return 'activatingEvents';
  } else if (current === 'activatingEvents') {
    return 'cognitive';
  } else if (current === 'cognitive') {
    return 'emotional';
  } else if (current === 'emotional') {
    return 'physiological';
  } else if (current === 'physiological') {
    return 'behavioral';
  }
  return '';
}

function getCheckboxFormTitle(t: TranslationFunction, section: keyof SuicidalMode) {
  if (section === 'riskFactors') {
    return t('suicidal_mode_form.risk_factors.card_title');
  } else if (section === 'activatingEvents') {
    return t('suicidal_mode_form.activating_events.card_title');
  } else if (section === 'cognitive') {
    return t('suicidal_mode_form.cognitive.card_title');
  } else if (section === 'emotional') {
    return t('suicidal_mode_form.emotional.card_title');
  } else if (section === 'physiological') {
    return t('suicidal_mode_form.physiological.card_title');
  } else if (section === 'behavioral') {
    return t('suicidal_mode_form.behavioral.card_title');
  }
  return '';
}

function getHeading(t: TranslationFunction, section: keyof SuicidalMode | '', active: boolean) {
  if (!active) return t('suicidal_mode_form.heading');
  switch (section) {
    case 'riskFactors':
      return t('suicidal_mode_form.risk_factors.heading');
    case 'activatingEvents':
      return t('suicidal_mode_form.activating_events.heading');
    case 'cognitive':
      return t('suicidal_mode_form.cognitive.heading');
    case 'behavioral':
      return t('suicidal_mode_form.behavioral.heading');
    case 'emotional':
      return t('suicidal_mode_form.emotional.heading');
    case 'physiological':
      return t('suicidal_mode_form.physiological.heading');
    default:
      return t('suicidal_mode_form.heading');
  }
}

export function getPlaceholderText(t: TranslationFunction, section: keyof SuicidalMode): string {
  switch (section) {
    case 'riskFactors':
      return t('suicidal_mode_form.risk_factors.description');
    case 'activatingEvents':
      return t('suicidal_mode_form.activating_events.description');
    case 'cognitive':
      return t('suicidal_mode_form.cognitive.description');
    case 'behavioral':
      return t('suicidal_mode_form.behavioral.description');
    case 'emotional':
      return t('suicidal_mode_form.emotional.description');
    case 'physiological':
      return t('suicidal_mode_form.physiological.description');
  }
}

export async function suicidalModeFormText({
  locale,
  client,
  t,
  patientID,
}: {
  locale: string;
  client: ApolloClient<unknown>;
  t: TranslationFunction;
  patientID: string;
}): Promise<string> {
  const { data } = await client.query<SuicidalMode, GetSuicidalModeAnswersVariables>({
    query: QUERY,
    variables: {
      patientID: patientID,
    },
  });
  const CheckboxesWithQuestions = getTranslations<SuicidalMode<CheckboxForm<CheckboxFormQuestion>>>(
    locale,
    'suicidal_mode_form.questions',
  );
  function sectionToText(section: keyof SuicidalMode) {
    const output = getTextListFromAnswerEntries({
      questions: CheckboxesWithQuestions[section],
      answers: data[section],
    }).map((text) => `- ${text}`);

    return `
${getCheckboxFormTitle(t, section)}
${output.join('\n')}
`.trim();
  }

  return `
${t('suicidal_mode_form.heading')}

${t('suicidal_mode_form.baseline_section').toUpperCase()}

${sectionToText('riskFactors')}

|
▼

${sectionToText('activatingEvents')}

|
▼

${t('suicidal_mode_form.crisis_section').toUpperCase()}

${sectionToText('cognitive')}

${sectionToText('emotional')}

${sectionToText('physiological')}

${sectionToText('behavioral')}
`.trim();
}

const SuicidalModeForm = (
  props: JcoMyStoryMyPlanScreenProps<
    'SuicidalModeForm',
    {
      printRef?: React.MutableRefObject<unknown>;
      isComplete?: boolean;
    }
  >,
) => {
  const { locale } = useAppContext();
  const t = useT();
  const CheckboxesWithQuestions = getTranslations<SuicidalMode<CheckboxForm<CheckboxFormQuestion>>>(
    locale,
    'suicidal_mode_form.questions',
  );
  const patientID = props.route.params?.patientID;
  const { data: savedData } = useQuery<GetSuicidalModeAnswers, GetSuicidalModeAnswersVariables>(
    QUERY,
    {
      variables: {
        patientID: patientID,
      },
    },
  );
  const [saveUserResponse] = useMutation<SaveSuicidalModalAnswer, SaveSuicidalModalAnswerVariables>(
    MUTATION,
  );
  const saveUserResponseDebounced = useRef(debounce(saveUserResponse, 500));
  const savedDataWithoutNulls = savedData
    ? produce(savedData, (draft) => {
        Object.entries(draft).forEach(([key, value]) => {
          if (value === null) delete (draft as any)[key]; // eslint-disable-line @typescript-eslint/no-explicit-any
        });
      })
    : {};
  const { bind, data: form } = useForm<SuicidalMode>({
    ...DEFAULT_SUICIDAL_MODE,
    ...savedDataWithoutNulls,
  });
  const selected = props.route.params?.suicidalModeSelected ?? '';
  const setSelected = (val: keyof SuicidalMode | '') => {
    props.navigation.push('SuicidalModeForm', {
      ...props.route.params,
      suicidalModeSelected: val,
    });
  };
  const nextSelected =
    props.route.params?.suicidalModeNextSelected ??
    (props.isComplete ? '' : getNextSection(selected, form));
  const complete = useSetMyStoryMyPlanStepComplete('SuicidalModeFormComplete');

  const isCognitiveActive = nextSelected === 'cognitive';
  const isEmotionalActive = nextSelected === 'emotional';
  const isBehavioralActive = nextSelected === 'behavioral';
  const isPhysiologicalActive = nextSelected === 'physiological';
  const isActivatingEventsActive = nextSelected === 'activatingEvents';
  const isRiskFactorsActive = nextSelected === 'riskFactors';
  const isComplete = selected === '' && nextSelected === '';
  const isTopSectionDisabled = !!(
    nextSelected &&
    !isCognitiveActive &&
    !isEmotionalActive &&
    !isBehavioralActive &&
    !isPhysiologicalActive
  );
  const isMiddleSectionDisabled = !!(nextSelected && !isActivatingEventsActive);
  const isBottomSectionDisabled = !!(nextSelected && !isRiskFactorsActive);

  const { value, onChangeValue } = selected
    ? bind(selected, { label: undefined })
    : { value: undefined, onChangeValue: () => {} };

  const onSaveValue = (newValue: CheckboxForm) => {
    saveUserResponseDebounced.current({
      variables: {
        patientID,
        key: selected,
        value: newValue,
      },
    });
    onChangeValue(newValue);
  };

  return (
    <MyStoryMyPlanContainer
      progress={4}
      heading={getHeading(t, selected || nextSelected, !!selected)}
      testID="SuicidalModeForm"
    >
      <View
        ref={(r) => {
          if (props.printRef) props.printRef.current = r;
        }}
        style={{
          padding: 20,
          backgroundColor: Color.secondary,
          borderRadius: 20,
          flex: 1,
        }}
      >
        {selected ? (
          <>
            <CheckboxFormInput
              questions={CheckboxesWithQuestions[selected]}
              formTitle={getCheckboxFormTitle(t, selected)}
              placeholder={getPlaceholderText(t, selected)}
              value={value!}
              onChangeValue={onSaveValue}
            />
          </>
        ) : (
          <>
            <SectionLabel
              title={t('suicidal_mode_form.baseline_section')}
              style={[
                { marginBottom: -5 },
                nextSelected && !isRiskFactorsActive ? { opacity: 0.3 } : null,
              ]}
            />
            <View
              style={[
                styles.sectionContainer,
                {
                  alignSelf: 'center',
                  minWidth: '80%',
                },
                isBottomSectionDisabled ? styles.disabled : null,
              ]}
            >
              <View row>
                <Card
                  buttonText={t('suicidal_mode_form.start_button')}
                  title={t('suicidal_mode_form.risk_factors.card_title')}
                  disabled={!!(nextSelected && !isRiskFactorsActive)}
                  onPress={() => setSelected('riskFactors')}
                  isActive={isRiskFactorsActive}
                  testID="SuicidalModeForm_riskFactorsCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.riskFactors}
                    answers={form.riskFactors}
                  />
                </Card>
              </View>
            </View>

            <View
              style={[
                {
                  width: 3,
                  alignSelf: 'center',
                  backgroundColor: Color.tertiary,
                  height: 60,
                },
                isBottomSectionDisabled || isMiddleSectionDisabled ? { opacity: 0.3 } : null,
              ]}
            />

            <View
              style={[
                styles.sectionContainer,
                {
                  alignSelf: 'center',
                  minWidth: '50%',
                },
                isMiddleSectionDisabled ? styles.disabled : null,
              ]}
            >
              <View row>
                <Card
                  disabled={!!(nextSelected && !isActivatingEventsActive)}
                  isActive={isActivatingEventsActive}
                  title={t('suicidal_mode_form.activating_events.card_title')}
                  onPress={() => setSelected('activatingEvents')}
                  testID="SuicidalModeForm_activatingEventsCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.activatingEvents}
                    answers={form.activatingEvents}
                  />
                </Card>
              </View>
            </View>

            <View
              style={[
                {
                  width: 3,
                  alignSelf: 'center',
                  backgroundColor: Color.tertiary,
                  height: 60,
                },
                isTopSectionDisabled || isMiddleSectionDisabled ? { opacity: 0.3 } : null,
              ]}
            />
            <View
              style={[
                {
                  borderWidth: 3,
                  width: 30,
                  alignSelf: 'center',
                  borderColor: Color.tertiary,
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  height: 30,
                  transform: [{ rotateZ: '45deg' }],
                  marginTop: -30,
                  marginBottom: 5,
                },
                isTopSectionDisabled || isMiddleSectionDisabled ? { opacity: 0.3 } : null,
              ]}
            />

            <SectionLabel
              title={t('suicidal_mode_form.crisis_section')}
              style={[
                { marginBottom: -5 },
                nextSelected &&
                !(
                  isBehavioralActive ||
                  isCognitiveActive ||
                  isEmotionalActive ||
                  isPhysiologicalActive
                )
                  ? { opacity: 0.3 }
                  : null,
              ]}
            />
            <View
              style={[styles.sectionContainer, isTopSectionDisabled ? styles.disabled : null]}
              spacing={8}
            >
              <View row spacing={8} style={{ alignItems: 'stretch' }}>
                <Card
                  title={t('suicidal_mode_form.cognitive.card_title')}
                  isActive={isCognitiveActive}
                  disabled={!!(nextSelected && !isCognitiveActive)}
                  onPress={() => setSelected('cognitive')}
                  testID="SuicidalModeForm_cognitiveCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.cognitive}
                    answers={form.cognitive}
                  />
                </Card>
                <Card
                  isActive={isEmotionalActive}
                  title={t('suicidal_mode_form.emotional.card_title')}
                  disabled={!!(nextSelected && !isEmotionalActive)}
                  onPress={() => setSelected('emotional')}
                  testID="SuicidalModeForm_emotionalCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.emotional}
                    answers={form.emotional}
                  />
                </Card>
              </View>
              <View row spacing={8} style={{ alignItems: 'stretch' }}>
                <Card
                  disabled={!!(nextSelected && !isPhysiologicalActive)}
                  isActive={isPhysiologicalActive}
                  title={t('suicidal_mode_form.physiological.card_title')}
                  onPress={() => setSelected('physiological')}
                  testID="SuicidalModeForm_physiologicalCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.physiological}
                    answers={form.physiological}
                  />
                </Card>
                <Card
                  isActive={isBehavioralActive}
                  title={t('suicidal_mode_form.behavioral.card_title')}
                  disabled={!!(nextSelected && !isBehavioralActive)}
                  onPress={() => setSelected('behavioral')}
                  testID="SuicidalModeForm_behavioralCard"
                >
                  <CheckboxAnswerList
                    questions={CheckboxesWithQuestions.behavioral}
                    answers={form.behavioral}
                  />
                </Card>
              </View>
            </View>
          </>
        )}
      </View>

      <View row style={{ paddingVertical: 16, justifyContent: 'space-between' }}>
        <Button
          text={t('suicidal_mode_form.back_button')}
          style={{ borderWidth: 0, opacity: selected === '' ? 0 : 1 }}
          disabled={selected === ''}
          onPress={() => {
            props.navigation.goBack();
          }}
        />
        <Button
          testID="SuicidalModeForm_continue"
          text={t('suicidal_mode_form.continue_button')}
          variant={isComplete ? 'solid' : 'contained'}
          onPress={() => {
            if (isComplete) {
              complete();
              props.navigation.push('MyPlanExplanation', {
                patientID: props.route.params.patientID,
              });
              return;
            }

            if (selected) {
              saveUserResponseDebounced.current.cancel();
              saveUserResponse({
                variables: {
                  patientID,
                  key: selected,
                  value: form[selected],
                },
              }).then(() =>
                props.navigation.push('SuicidalModeForm', {
                  ...props.route.params,
                  suicidalModeSelected: '',
                  suicidalModeNextSelected: getNextSection(selected, form),
                }),
              );
            } else if (nextSelected) {
              setSelected(nextSelected);
            }
          }}
        />
      </View>
    </MyStoryMyPlanContainer>
  );
};

const styles = StyleSheet.create({
  disabled: { borderColor: hexToRgba(Color.tertiary, 0.3) },
  sectionContainer: {
    borderWidth: 3,
    borderColor: Color.tertiary,
    borderRadius: 8,
    padding: 8,
  },
});

export default SuicidalModeForm;
