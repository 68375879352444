import { useApolloClient } from '@apollo/client';
import React, { useState, useContext } from 'react';
import Constants from 'expo-constants';
import AsyncStorage from '@react-native-community/async-storage';

import Sentry from '@src/sentry';
import { signOut } from '@src/lib/auth';
import {
  clearPersistedState,
  setPersistedState,
  usePersistedState,
} from '@src/hooks/usePersistedState';
import { useLogoutMutation } from '@src/screens/useLogout.graphql.generated';

export const LogoutContext = React.createContext({
  isLoggingOut: false,
  setIsLoggingOut: (value: boolean) => {},
});

export function LogoutProvider(props: { children: React.ReactNode }) {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  return (
    <LogoutContext.Provider value={{ isLoggingOut, setIsLoggingOut }}>
      {props.children}
    </LogoutContext.Provider>
  );
}

export function useLogout() {
  const { setIsLoggingOut } = useContext(LogoutContext);
  const client = useApolloClient();
  const [logout] = useLogoutMutation();
  const [disableLocalAuthentication, setDisableLocalAuthentication] = usePersistedState(
    'disableLocalAuthentication',
    false,
  );

  return () => {
    return logout({ variables: { deviceID: Constants.installationId } })
      .catch((e) => Sentry.captureException(e))
      .then(() => setIsLoggingOut(true))
      .then(() => signOut())
      .catch((e) => Sentry.captureException(e))
      .then(() => client.clearStore())
      .then(() => {
        const _disableLocalAuthentication = disableLocalAuthentication;
        clearPersistedState();
        setDisableLocalAuthentication(_disableLocalAuthentication);
      })
      .then(() => AsyncStorage.setItem('lastSeen', Date.now().toString()))
      .then(() => setPersistedState('SeenLocalAuthenticationPrompt', false))
      .catch((e) => Sentry.captureException(e))
      .then(() => setIsLoggingOut(false));
  };
}
