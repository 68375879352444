import { useCallback } from 'react';
import IntlMessageFormat, { PrimitiveType } from 'intl-messageformat';
import get from 'lodash/get';
import set from 'lodash/set';

import { useAppContext } from '@src/hooks/useAppContext';
import enUS from '@src/messages/en-US.json';
import es from '@src/messages/es.json';

const INVALID_FLAG = '🚫';
const DEFAULT_LOCALE = 'en-US';
const MESSAGES: { [key: string]: any } = {
  en: enUS,
  es,
};
const FLAGS: { [key: string]: string } = {
  en: ' 🇺🇸',
  es: '🇲🇽',
};

type Variables = Record<string, PrimitiveType>;

export const LOCALES = Object.keys(MESSAGES);

export function registerTranslations(locale: string, path: string, value: object) {
  set(MESSAGES, [locale, path].join('.'), value);
}

export function getTranslations<T extends object>(locale: string, path: string): T {
  return (
    get(MESSAGES, [locale, path].join('.')) ??
    get(MESSAGES, [locale.split('-')[0], path].join('.')) ??
    get(MESSAGES, [DEFAULT_LOCALE, path].join('.'))
  );
}

function wrapInFlags(text: string, locale: string) {
  const flag = FLAGS[locale] ?? FLAGS[locale.split('-')[0]] ?? locale;
  return `${flag}${text}${flag}`;
}

export function useT() {
  const {
    flags: { showI18NFlags, showI18NKeys },
    locale,
  } = useAppContext();
  return useCallback(
    (key: string, variables?: Variables) => {
      return showI18NKeys ? key : t(key, variables, { locale, wrapInFlags: showI18NFlags });
    },
    [showI18NKeys, showI18NFlags, locale],
  );
}

function getMessageTemplate(locale: string, key: string): string {
  const parts = locale.split('-');
  return get(MESSAGES?.[locale], key) ?? get(MESSAGES?.[parts[0]], key);
}

export function t(
  key: string,
  variables?: Variables,
  options?: { wrapInFlags?: boolean; locale: string },
) {
  const messageTemplate = getMessageTemplate(options?.locale ?? DEFAULT_LOCALE, key);
  if (messageTemplate) {
    const template = new IntlMessageFormat(messageTemplate);
    const result = template.format(variables);
    return options?.wrapInFlags ? wrapInFlags(result, options?.locale ?? DEFAULT_LOCALE) : result;
  }
  const fallbackMessageTemplate = getMessageTemplate(DEFAULT_LOCALE, key);
  if (fallbackMessageTemplate) {
    const template = new IntlMessageFormat(fallbackMessageTemplate);
    const result = template.format(variables);
    return options?.wrapInFlags ? wrapInFlags(result, DEFAULT_LOCALE) : result;
  }

  return `${INVALID_FLAG}${key}${INVALID_FLAG}`;
}

export type TranslationFunction = typeof t;
