import { Notifications } from 'expo';
import * as Permissions from 'expo-permissions';
import type {
  addNotificationReceivedListener as _addNotificationReceivedListener,
  addNotificationResponseReceivedListener as _addNotificationResponseReceivedListener,
  getExpoPushTokenAsync as _getExpoPushTokenAsync,
  getDevicePushTokenAsync as _getDevicePushTokenAsync,
  setNotificationChannelAsync as _setNotificationChannelAsync,
  requestPermissionsAsync as _requestPermissionsAsync,
  getPermissionsAsync as _getPermissionsAsync,
} from 'expo-notifications';

export const getExpoPushTokenAsync: typeof _getExpoPushTokenAsync = async () => {
  const data = await Notifications.getExpoPushTokenAsync();
  return { type: 'expo', data };
};

export const getDevicePushTokenAsync: typeof _getDevicePushTokenAsync = async () => {
  const result = await Notifications.getDevicePushTokenAsync({});
  return {
    type: result.type as 'ios' | 'android',
    data: result.data,
  };
};

export const addNotificationResponseReceivedListener: typeof _addNotificationResponseReceivedListener = () => {
  return { remove: () => {} };
};

export const setNotificationChannelAsync: typeof _setNotificationChannelAsync = async (
  identifier,
  config,
) => {
  await Notifications.createChannelAndroidAsync(identifier, {
    ...config,
    sound: config.sound !== null,
    name: config.name!,
    description: config.description ?? undefined,
  });
  return {} as any;
};

export const getPermissionsAsync: typeof _getPermissionsAsync = async () => {
  return await Permissions.getAsync(Permissions.NOTIFICATIONS);
};

export const requestPermissionsAsync: typeof _requestPermissionsAsync = async () => {
  return await Permissions.askAsync(Permissions.NOTIFICATIONS);
};

export const addNotificationReceivedListener: typeof _addNotificationReceivedListener = () => {
  return { remove: () => {} };
};

export const setNotificationHandler = () => {};
export const dismissNotificationAsync = () => {};
