import React from 'react';

import MyStoryMyPlanContainer, { ExplanationVideos } from '@src/components/MyStoryMyPlanContainer';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { useT } from '@src/lib/i18n';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';

export default function SuicidalModeExplanation(
  props: JcoMyStoryMyPlanScreenProps<'SuicidalModeExplanation'>,
) {
  const t = useT();
  const complete = useSetMyStoryMyPlanStepComplete('SuicidalModeExplanationComplete');
  return (
    <MyStoryMyPlanContainer
      progress={3}
      heading={t('suicidal_mode_explanation.heading')}
      subheading={t('suicidal_mode_explanation.description')}
      onNext={() => {
        complete();
        props.navigation.navigate('SuicidalModeForm', props.route.params);
      }}
      testID="SuicidalModeExplanation"
    >
      <ExplanationVideos
        videos={[
          {
            label: t('suicidal_mode_explanation.voiceover_label'),
            uri:
              'https://storage.googleapis.com/asset.oui.dev/voiceover/Onboarding_SuicideMode.mp4',
            caption: t('suicidal_mode_explanation.voiceover_caption'),
            posterSource: require('../assets/posters/Suicidal_Mode.jpg'),
          },
          {
            label: t('suicidal_mode_explanation.vignette_label'),
            uri:
              'https://storage.googleapis.com/asset.oui.dev/vignette/Vet_Vignette_Suicidal_Mode_RC01.mp4',
            caption: t('suicidal_mode_explanation.vignette_caption'),
            posterSource: require('../assets/posters/Vignette_Suicidal_Mode.jpg'),
          },
        ]}
      />
    </MyStoryMyPlanContainer>
  );
}
