import React, { useMemo } from 'react';
import { Platform, StyleProp, ViewStyle, TextStyle } from 'react-native';
import RNPickerSelect, { PickerSelectProps as PickerProps } from 'react-native-picker-select';
import noop from 'lodash/noop';

import { TextInput } from '@src/components/TextInput';
import { Icon } from '@src/components/Icon';
import { View } from '@src/components/View';
import { Color } from '@src/styles';
import { AccessibleInput } from '@src/hooks/useAccessibleInput';
import { APP_SLUG } from '@src/constants';
import { useAppContext } from '@src/hooks/useAppContext';

const FLAT_STYLE = {
  borderBottomColor: '#eee',
  borderBottomWidth: 1,
  color: Color.text,
  padding: 10,
  paddingRight: 34,
  paddingVertical: 16,
} as TextStyle;
const CONTAINED_STYLE = {
  borderColor: '#C6C6D4',
  backgroundColor: Color.surfaceColor,
  borderRadius: 30,
  borderWidth: 1,
  color: Color.text,
  marginVertical: 4,
  padding: 16,
  paddingRight: 34,
} as TextStyle;

export function PickerInput<T>({
  accessibilityLabel,
  error,
  label,
  onChangeValue,
  placeholder,
  style,
  testID,
  variant,
  ...props
}: Omit<PickerProps, 'placeholder' | 'onValueChange' | 'style'> & {
  accessibilityLabel?: string;
  error?: string;
  testID?: string;
  placeholder?: string;
  variant?: 'contained' | 'flat';
  label?: string;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onChangeValue?: (value: T) => void;
}) {
  const inputStyle = variant === 'flat' ? FLAT_STYLE : CONTAINED_STYLE;
  const selectedLabel = useMemo(() => {
    return props.items.find((item) => item.value === props.value)?.label;
  }, [props.items, props.value]);
  const {
    flags: { useV2 },
  } = useAppContext();

  const items = useMemo(() => {
    return props.items.map((i) => ({ ...i, key: i.value }));
  }, [props.items]);

  if (global.e2e && !useV2 && !(Platform.OS === 'web' && APP_SLUG === 'oui-aviva-staff')) {
    return (
      <View style={style}>
        <TextInput
          testID={testID}
          label={label}
          placeholder={placeholder}
          onChangeValue={onChangeValue as any}
          value={props.value}
        />
      </View>
    );
  }

  return (
    <AccessibleInput
      accessibilityLabel={accessibilityLabel}
      error={error}
      label={label}
      style={style}
      testID={testID}
    >
      {(accessibleProps) => (
        <RNPickerSelect
          {...props}
          items={items}
          pickerProps={{
            testID: testID,
          }}
          useNativeAndroidPickerStyle={false}
          touchableWrapperProps={{
            ...accessibleProps,
            accessible: true,
            accessibilityLabel: `Dropdown list. ${selectedLabel ? `${selectedLabel}.` : ''} ${
              accessibleProps.accessibilityLabel ?? ''
            } Double tap to change`,
            testID: testID ? `${testID}_touchable` : undefined,
          }}
          textInputProps={{
            importantForAccessibility: 'no',
          }}
          touchableDoneProps={{
            testID: testID ? `${testID}_doneButton` : undefined,
          }}
          placeholder={
            placeholder ? { label: placeholder, value: '__placeholder__', color: '#9191A3' } : {}
          }
          onValueChange={
            onChangeValue
              ? (value, idx) => {
                  const newValue =
                    value === '__placeholder__'
                      ? undefined
                      : props.items[placeholder ? idx - 1 : idx].value;
                  // This callback runs if props.value changes triggering a change in the underlying component
                  // However, if props.value is already equal to the new value, there is no point in
                  // calling onChangeValue
                  if (newValue !== props.value) {
                    onChangeValue(newValue);
                  }
                }
              : noop
          }
          style={{
            inputIOS: inputStyle,
            inputAndroid: inputStyle,
            iconContainer: {
              top: 2,
              bottom: 0,
              justifyContent: 'center',
              right: 16,
              ...(Platform.OS === 'web' ? { pointerEvents: 'none' } : undefined),
            },
            headlessAndroidPicker:
              Platform.OS === 'web'
                ? ({
                    color: Color.text,
                  } as any)
                : undefined,
          }}
          Icon={() => (
            <Icon
              accessibilityLabel={undefined}
              name="chevron-down"
              size={14}
              color={Color.styleGuide.Gray4}
            />
          )}
        />
      )}
    </AccessibleInput>
  );
}

export default PickerInput;
