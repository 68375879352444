// This file is automatically generated. See gqlcodegen.yml for details

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Action: [
      'ActivityAddAction',
      'ActivityRateAction',
      'CopingCardAddAction',
      'CopingCardReviewAction',
      'HopeKitAddAction',
      'HopeKitReviewAction',
      'MyPlanReviewAction',
      'RelaxAction',
      'SleepDiaryEntryMorningAction',
      'SleepDiaryEntryNightAction',
      'StaticAction',
      'ThoughtDiaryEntrySpotAction',
      'ThoughtDiaryEntrySwitchAction',
      'ThoughtDiaryEntryTestAction',
    ],
    CareTeamEntity: ['Organization', 'Practitioner'],
    HopeKitItem: ['HopeKitImage', 'HopeKitQuote', 'HopeKitVideo'],
    OuiUserType: ['Patient', 'Practitioner', 'Registrar'],
    PendingAction: [
      'PendingActivityAddAction',
      'PendingActivityRateAction',
      'PendingCopingCardAddAction',
      'PendingCopingCardReviewAction',
      'PendingHopeKitAddAction',
      'PendingHopeKitReviewAction',
      'PendingMyPlanReviewAction',
      'PendingRelaxAction',
      'PendingSleepDiaryEntryMorningAction',
      'PendingSleepDiaryEntryNightAction',
      'PendingThoughtDiaryEntrySpotAction',
      'PendingThoughtDiaryEntrySwitchAction',
      'PendingThoughtDiaryEntryTestAction',
    ],
    Practice: ['ActivityPractice', 'SleepDiaryEntryPractice', 'ThoughtDiaryEntryPractice'],
    UserEntity: ['Patient', 'Practitioner', 'Registrar'],
  },
};
export default result;
