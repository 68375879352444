import React from 'react';
import { StatusBar, SafeAreaView } from 'react-native';

import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { ScrollView } from '@src/components/ScrollView';
import { APP_SLUG } from '@src/constants';
import { View } from '@src/components/View';
import { Text } from '@src/components/Text';
import LoginPlant from '@src/assets/Login_plant.svg';
import LoginFlower from '@src/assets/Login_flower.svg';
import FullLogo from '@src/assets/Full_logo.svg';
import JcoIcon from '@src/assets/jcoicon.svg';
import { useColor } from '@src/styles';

export default function AuthScreenContainer(props: {
  testID?: string;
  heading: string | null;
  children: React.ReactNode;
  _scrollView?: any;
}) {
  const { height } = useWindowDimensions();
  const { Color, scheme } = useColor();
  const ScrollViewComponent = props._scrollView ?? ScrollView;

  return (
    <>
      <SafeAreaView style={{ flex: 1, zIndex: 1 }}>
        <StatusBar barStyle={scheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
        <ScrollViewComponent
          contentContainerStyle={{ paddingTop: height / 12, flexGrow: 1 }}
          testID={`${props.testID}_scrollView`}
        >
          <View style={{ alignItems: 'center' }}>
            {APP_SLUG === 'oui-jco' ? (
              <JcoIcon color={Color.accent} height={height / 8} accessibilityLabel="Logo" />
            ) : (
              <FullLogo
                height={height / 8}
                accessibilityLabel="Aviva Logo"
                accessibilityRole="image"
              />
            )}
          </View>
          <View
            style={{
              paddingHorizontal: 20,
              flex: 1,
              marginTop: 20,
            }}
            testID={props.testID}
          >
            {props.heading ? (
              <Text
                accessibilityRole="header"
                text={props.heading}
                size={28}
                weight="bold"
                style={{ marginTop: 20, marginBottom: 10, textAlign: 'center' }}
              />
            ) : null}
            {props.children}
          </View>
        </ScrollViewComponent>
      </SafeAreaView>
      <View
        style={{
          position: 'absolute',
          right: 0,
          left: 0,
          bottom: 0,
          flexDirection: 'row',
          alignSelf: 'stretch',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          paddingRight: 40,
        }}
      >
        <LoginPlant height={height / 6} accessibilityLabel={undefined} />
        <LoginFlower height={height / 6} accessibilityLabel={undefined} />
      </View>
    </>
  );
}
