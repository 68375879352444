// This file is automatically generated. See gqlcodegen.yml for details
import type * as Types from '@lib/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type SetPushTokenMutationVariables = Types.Exact<{
  deviceID: Types.Scalars['String'];
  platform: Types.Scalars['String'];
  platformPushToken?: Types.Maybe<Types.Scalars['String']>;
  expoPushToken?: Types.Maybe<Types.Scalars['String']>;
}>;

export type SetPushTokenMutation = { readonly setDeviceInfo: boolean };

export const SetPushTokenDocument = gql`
  mutation SetPushToken(
    $deviceID: String!
    $platform: String!
    $platformPushToken: String
    $expoPushToken: String
  ) {
    setDeviceInfo(
      deviceID: $deviceID
      platform: $platform
      platformPushToken: $platformPushToken
      expoPushToken: $expoPushToken
    )
  }
`;

/**
 * __useSetPushTokenMutation__
 *
 * To run a mutation, you first call `useSetPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPushTokenMutation, { data, loading, error }] = useSetPushTokenMutation({
 *   variables: {
 *      deviceID: // value for 'deviceID'
 *      platform: // value for 'platform'
 *      platformPushToken: // value for 'platformPushToken'
 *      expoPushToken: // value for 'expoPushToken'
 *   },
 * });
 */
export function useSetPushTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SetPushTokenMutation, SetPushTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetPushTokenMutation, SetPushTokenMutationVariables>(
    SetPushTokenDocument,
    options,
  );
}
export type SetPushTokenMutationHookResult = ReturnType<typeof useSetPushTokenMutation>;

export function performSetPushToken(
  client: Apollo.ApolloClient<unknown>,
  variables: SetPushTokenMutationVariables,
  options?: Omit<
    Apollo.MutationOptions<SetPushTokenMutation, SetPushTokenMutationVariables>,
    'mutation' | 'variables'
  >,
) {
  return client.mutate<SetPushTokenMutation, SetPushTokenMutationVariables>({
    mutation: SetPushTokenDocument,
    variables,
    ...options,
  });
}
