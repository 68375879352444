import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useFocusEffect } from '@react-navigation/native';

import Sentry from '@src/sentry';
import { useIsLoggedIn } from '@src/hooks/useIsLoggedIn';
import { Claims, getClaimsSync } from '@src/lib/auth';
import { Environment, environment } from '@src/constants';
import { ContentType, ProductVersion } from '@src/types/generated_v2';
import {
  useCurrentUserQuery,
  useOrganizationsQuery,
  useSelfPatientLazyQuery,
  useSelfPatientQuery,
} from '@src/hooks/useCurrentUser.graphql.generated';

export function useCurrentUser() {
  const [claims, setClaims] = useState<Claims | null>(getClaimsSync);
  const isLoggedIn = useIsLoggedIn();
  const { data, ...rest } = useCurrentUserQuery({
    skip: !isLoggedIn || !claims?.product || claims?.product === '1.0.0' || claims?.admin,
  });

  useEffect(() => {
    if (data?.currentUser?.user?.__typename === 'Patient') {
      if (
        global.flags.useV2 &&
        [ProductVersion.AVIVA_1_0, ProductVersion.AVIVA_1_1].includes(
          data.currentUser.user.productVersion,
        )
      ) {
        global.setFlags?.((flags) => ({ ...flags, useV2: false }));
      } else if (
        !global.flags.useV2 &&
        data.currentUser.user.productVersion === ProductVersion.AVIVA_2_0
      ) {
        global.setFlags?.((flags) => ({ ...flags, useV2: true }));
      }
    }
  }, [data?.currentUser?.user]);

  useEffect(() => {
    setClaims(getClaimsSync());
  }, [isLoggedIn]);

  const result = useMemo(() => {
    return { data, claims, ...rest };
  }, [data, claims, rest]);

  return result;
}

export function useCurrentPatientID() {
  const { data: user } = useCurrentUser();
  if (user?.currentUser?.role === 'PATIENT' && user?.currentUser?.user?.ID) {
    return user?.currentUser?.user?.ID;
  }
  return null;
}

export function useProgress() {
  const { data } = useCurrentUser();
  const ID = data?.currentUser?.user?.ID;
  return useSelfPatientQuery({
    variables: { patientID: ID! },
    skip: !ID,
  });
}

export function useProgressFetcher() {
  const { data } = useCurrentUser();
  const ID = data?.currentUser?.user?.ID;
  const [fetch] = useSelfPatientLazyQuery({
    variables: { patientID: ID! },
    fetchPolicy: 'network-only',
  });
  return fetch;
}

export function useProgressByContent() {
  const { data, ...rest } = useProgress();
  const progress = useMemo(() => {
    const items = data?.patientByPatientID?.progress ?? [];
    return items.reduce<Partial<Record<ContentType, typeof items[number]>>>((carry, p) => {
      carry[p.content as ContentType] = p;
      return carry;
    }, {});
  }, [data]);
  return { data: progress, ...rest };
}

export function useProgressComplete(
  contentName: ContentType,
  completeOrNotComplete: boolean = true,
): {
  loaded?: boolean;
  completeResult: boolean;
  maybeCompleteResult?: boolean;
  refetch: () => Promise<unknown>;
} {
  const [stale, setStale] = useState(false);
  const staleRef = useRef(stale);
  staleRef.current = stale;
  const { refetch, data } = useProgress();

  const progressEntry = data?.patientByPatientID?.progress.find((e) => e.content === contentName);
  const completeResult =
    (!completeOrNotComplete && !progressEntry) ||
    progressEntry?.completed === completeOrNotComplete;

  const completeResultRef = useRef(completeResult);
  completeResultRef.current = completeResult;

  useFocusEffect(
    useCallback(() => {
      if (completeResultRef.current !== true) {
        if (staleRef.current) {
          try {
            refetch?.()
              .then(() => {
                setStale(false);
              })
              .catch((e) => {
                setStale(false);
              });
          } catch (e) {
            if (environment !== Environment.DEVELOPMENT) {
              Sentry.captureException(e);
            }
          }
        }
        return () => {
          // if completeResult is already in it's target end state, we dont need to worry about
          // marking as stale to refetch later.
          setStale(!completeResultRef.current);
        };
      }
      return;
    }, [refetch]),
  );

  return stale
    ? { completeResult: false, maybeCompleteResult: false, loaded: false, refetch }
    : {
        completeResult,
        maybeCompleteResult: progressEntry
          ? progressEntry.completed === completeOrNotComplete
          : undefined,
        refetch,
        loaded: !!data?.patientByPatientID?.progress,
      };
}

export function useOrganizations() {
  return useOrganizationsQuery();
}
