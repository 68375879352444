import React from 'react';
import { TouchableOpacity } from 'react-native';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import { Color } from '@src/styles';
import { View } from '@src/components/View';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';

export function WorksheetListItem(props: {
  text: string;
  description?: string;
  completedAt?: string;
  onPress: () => void;
  buttonProps?: Partial<React.ComponentProps<typeof Button>>;
  testID?: string;
}) {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{ paddingVertical: 0 }}
      accessibilityRole="menuitem"
      testID={props.testID}
    >
      <View row style={{ justifyContent: 'space-between' }}>
        <View spacing={4} flex={1}>
          <Text text={props.text} size={17} weight="bold" numberOfLines={2} />
          {props.description ? (
            <Text text={props.description} numberOfLines={1} color={Color.styleGuide.Gray4} />
          ) : null}
          {props.completedAt ? (
            <Text
              text={`Completed ${format(parseISO(props.completedAt), 'MMM dd')}`}
              size={15}
              color={Color.styleGuide.Gray3}
            />
          ) : null}
        </View>
        <Button
          alignSelf="center"
          icon="chevron-right"
          onPress={props.onPress}
          variant="text"
          accessibilityLabel="Go to item"
          {...props.buttonProps}
        />
      </View>
    </TouchableOpacity>
  );
}
