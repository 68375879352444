import { onError, ErrorLink } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import type { ConnectionParamsOptions } from 'subscriptions-transport-ws';
import {
  ApolloCache,
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  Observable,
  Operation,
  UriFunction,
  split,
} from '@apollo/client';
import { invariant } from 'ts-invariant';
import 'cross-fetch/polyfill';
import Sentry from '@src/sentry';
const createUploadLink: (options: HttpOptions) => HttpLink = require('apollo-upload-client')
  .createUploadLink;

import { parseJwt } from '@lib/parseJwt';
import { IS_PRODUCTION } from '@src/constants';

export { HttpLink };

export interface PresetConfig {
  connectToDevTools?: boolean;
  request?: (operation: Operation) => Promise<void> | void;
  uri?: string | UriFunction;
  onError?: ErrorLink.ErrorHandler;
  cache?: ApolloCache<any>;
  defaultOptions?: DefaultOptions;
  preHttpLink?: ApolloLink;
  subscriptionOptions?: { uri: string; connectionParams: ConnectionParamsOptions };
}

// https://github.com/Haegin/apollo-sentry-link/blob/master/src/index.js
const operationInfo = (operation: Operation) => {
  const token = operation.getContext().headers['authorization'];
  const hasToken = !!token;
  const tokenData: { [key: string]: any } = {};
  const now = Math.floor(Date.now() / 1000);
  const requestID = operation.getContext().headers['X-RequestID'];
  if (hasToken) {
    const data = parseJwt(token.split(' ')[1]);
    tokenData.iat = data.iat;
    tokenData.exp = data.exp;
    tokenData.expired = now > data.exp;
    if (tokenData.expired) {
      Sentry.withScope((scope) => {
        scope.setTag('requestID', requestID);
        scope.setExtras({
          ...tokenData,
          name: operation.operationName,
          requestID,
        });
        Sentry.captureMessage('expired JWT token');
      });
    }
  }

  const variables = IS_PRODUCTION ? undefined : operation.variables;

  return {
    requestID,
    type: (operation.query.definitions.find((defn: any) => defn.operation)! as any).operation,
    token: {
      hasToken,
      ...tokenData,
    },
    name: operation.operationName,
    dateNow: now,
    variables,
  };
};

export default class DefaultClient<TCache> extends ApolloClient<TCache> {
  constructor(config: PresetConfig = {}) {
    const {
      connectToDevTools,
      request,
      uri,
      onError: errorCallback,
      defaultOptions,
      preHttpLink,
      subscriptionOptions,
    } = config;

    let { cache } = config;

    if (!cache) {
      cache = new InMemoryCache();
    }

    const errorLink = errorCallback
      ? onError(errorCallback)
      : onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
              // tslint:disable-next-line
              invariant.warn(
                `[GraphQL error]: Message: ${message}, Location: ` + `${locations}, Path: ${path}`,
              ),
            );
          }
          if (networkError) {
            // tslint:disable-next-line
            invariant.warn(`[Network error]: ${networkError}`);
          }
        });

    const requestHandler = request
      ? new ApolloLink(
          (operation, forward) =>
            new Observable((observer) => {
              let handle: any;
              Promise.resolve(operation)
                .then((oper) => request(oper))
                .then(() => {
                  handle = forward!(operation).subscribe({
                    next: observer.next.bind(observer),
                    error: observer.error.bind(observer),
                    complete: observer.complete.bind(observer),
                  });
                })
                .catch(observer.error.bind(observer));

              return () => {
                if (handle) {
                  handle.unsubscribe();
                }
              };
            }),
        )
      : false;

    let httpLink: ApolloLink = createUploadLink({
      uri: uri || '/graphql',
      fetch,
      fetchOptions: {},
      credentials: 'same-origin',
      headers: {},
    });

    if (subscriptionOptions) {
      const wsLink = new WebSocketLink({
        uri: subscriptionOptions.uri,
        options: {
          reconnect: true,
          lazy: true,
          ...subscriptionOptions,
        },
      });

      httpLink = split(
        // split based on operation type
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      );
    }

    // https://github.com/Haegin/apollo-sentry-link/blob/master/src/index.js
    const sentryLink = new ApolloLink((operation, forward) => {
      Sentry.addBreadcrumb({
        type: 'http',
        category: 'graphql',
        data: operationInfo(operation),
        level: Sentry.Severity.Debug,
      });
      return forward!(operation);
    });

    const retryLink = new RetryLink({
      attempts: {
        max: 3,
      },
    });

    const link = ApolloLink.from(
      [errorLink, retryLink, requestHandler, sentryLink, preHttpLink, httpLink].filter(
        (x) => !!x,
      ) as ApolloLink[],
    );

    super({
      connectToDevTools,
      cache,
      link,
      defaultOptions,
    });
  }
}
