import React, { useEffect, forwardRef, useState, useRef } from 'react';
import {
  Animated,
  AppState,
  AppStateStatus,
  Image,
  ImageURISource,
  Modal,
  Platform,
  SafeAreaView,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  StatusBar,
  AccessibilityInfo,
} from 'react-native';
import { Video } from 'expo-av';
import { LinearGradient } from 'expo-linear-gradient';
import noop from 'lodash/noop';
import hexToRgba from 'hex-to-rgba';
import Url from 'url-parse';
import { activateKeepAwake, deactivateKeepAwake } from 'expo-keep-awake';
import * as VideoThumbnails from 'expo-video-thumbnails';
import * as FileSystem from 'expo-file-system';
// @ts-expect-error
import computeMd5 from 'blueimp-md5';
import formatDuration from 'date-fns/formatDuration';
import muxReactNativeVideo, {
  MuxVideo as MuxVideoType,
  MuxOptions,
} from 'mux-react-native-video-sdk';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { Icon } from '@src/components/Icon';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { AspectRatio } from '@src/components/AspectRatio';
import AppContext from '@src/components/AppContext';
import { Shadow, Color } from '@src/styles';
import Sentry from '@src/sentry';
import RNVideo, { TextTrackType } from '@src/components/RNVideo';
import { RNSlider as Slider } from '@src/components/RNSlider';
import { fetchIsConnected } from '@src/lib/NetInfo';
import { Subtitles } from '@src/components/Subtitles';
import { preloadPersistedState, setPersistedState } from '@src/hooks/usePersistedState';
import { TranscriptModal } from '@src/components/TranscriptModal';
import { APP_SLUG, manifest, MUX_ENV_KEY } from '@src/constants';
import { getClaimsSync } from '@src/lib/auth';
import { getMuxVTTUri, isMuxUrl } from '@src/lib/muxUrl';

const MuxVideo = muxReactNativeVideo(RNVideo);

type Props = {
  _enableSkipToEnd?: boolean;
  allowMini?: boolean;
  aspectRatio?: number | null;
  autoPlay?: boolean;
  hideControls?: boolean;
  initialPositionMillis?: number;
  isAudio?: boolean;
  miniPlayer?: boolean;
  miniPlayerControlsColor?: string;
  onClose?: () => void;
  onEnd?: () => void;
  onError?: () => void;
  onFullscreenUpdate?: (event: { fullscreenUpdate: number }) => void;
  onProgress?: (currentTimeMillis: number) => void;
  posterSource?: ImageURISource | number;
  resizeMode?: 'contain' | 'cover';
  subtitleUri?: string;
  title?: string;
  uri: string;
};

type State = {
  durationMillis: number;
  initialized: boolean;
  isClosedCaptionEnabled: boolean;
  isHeightConstrained: boolean;
  isLoaded: boolean;
  isMuted: boolean;
  isPlaying: boolean;
  isShowingControls: boolean;
  isShowingTranscript: boolean;
  loadRetryAttempt: number;
  miniPlayer: boolean;
  positionMillis: number;
};

const MAX_RETRY_ATTEMPTS = 5;
const VideoLinearGradient = React.memo(() => (
  <LinearGradient
    colors={['transparent', 'rgba(0,0,0,0.6)']}
    style={StyleSheet.absoluteFillObject}
    start={[0, 0.75]}
    end={[0, 1]}
  />
));

function isClosedCaptionAvailableForUri(uri: string | number) {
  return typeof uri === 'string' && (isMuxUrl(uri) || uri.match(/voiceover|vignette/));
}

// Adapted from
// https://github.com/ihmpavel/expo-video-player/blob/3448d17d0773f967082b194453742ebc2f6f88f9/lib/index.tsx#L433
export const getMMSSFromMillis = (
  millis: number,
  duration: number,
  { accessible }: { accessible: boolean } = { accessible: false },
) => {
  const totalSeconds = millis / 1000;
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60);

  const durationLongerThanTenMinutes = duration / 1000 / 60 >= 10;

  const padWithZero = (n: number, isMinuteValue: boolean) => {
    const str = n.toString();
    if ((!isMinuteValue && n < 10) || (n < 10 && durationLongerThanTenMinutes)) {
      return '0' + str;
    }
    return str;
  };

  if (accessible) {
    if (minutes === 0 && seconds === 0) return '0 seconds';
    return formatDuration({ minutes, seconds });
  }

  return padWithZero(minutes, true) + ':' + padWithZero(seconds, false);
};

// @ts-ignore
const DEMO_AUDIO = 'http://www.hochmuth.com/mp3/Beethoven_12_Variation.mp3';
// @ts-ignore
const DEMO_VIDEO = 'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4';

function isAudioURI(uri: string) {
  if (uri.endsWith('.mp3')) return true;
  if (uri.endsWith('.mp4')) return false;
  const { query } = new Url(uri, {}, { parser: true });
  return query.contentType?.includes('audio');
}

function getCachedUri<T extends string | number>(uri: T): T extends number ? null : string {
  type Return = T extends number ? null : string;
  if (Platform.OS === 'web' || typeof uri === 'number') return null as Return;
  const extension = (uri as string).split('.').reverse()[0];
  const hash = computeMd5(uri);
  return `${FileSystem.documentDirectory}mediaplayer-preload/${hash}.${extension}` as Return;
}

export async function preloadMedia(uri: string) {
  if (Platform.OS === 'web') return;
  await FileSystem.makeDirectoryAsync(`${FileSystem.documentDirectory}mediaplayer-preload`, {
    intermediates: true,
  });
  const cachedUri = getCachedUri(uri);
  const { exists } = await FileSystem.getInfoAsync(cachedUri);
  if (!exists) {
    const result = await FileSystem.downloadAsync(uri, cachedUri);
    Sentry.addBreadcrumb({ message: 'preloadMedia', data: result });
  }
}

export default class MediaPlayer extends React.Component<Props, State> {
  static defaultProps = {
    uri: DEMO_VIDEO,
    autoPlay: true,
    allowMini: true,
    miniPlayerControlsColor: 'black',
  };
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  controlsOpacity = new Animated.Value(this.props.hideControls ? 0 : 1);
  hideControlsTimeout = 0 as any;
  keepAwakeTag = `KeepAwake:MediaPlayer:${Math.random()}`;

  source?: { uri: string };
  video = React.createRef<Video>();
  rnVideo = React.createRef<MuxVideoType>();
  isScreenReaderEnabled: boolean = false;
  hls?: any;
  hlsCallback?: () => void;

  state: State = {
    durationMillis: 0,
    initialized: false,
    isClosedCaptionEnabled: false,
    isHeightConstrained: false,
    isLoaded: false,
    isMuted: false,
    isPlaying: this.props.autoPlay || false,
    isShowingControls: !this.props.hideControls,
    isShowingTranscript: false,
    loadRetryAttempt: 0,
    miniPlayer: this.props.miniPlayer ?? isAudioURI(this.props.uri),
    positionMillis: 0,
  };

  componentDidMount() {
    if (Platform.OS === 'web') {
      import('hls.js').then((result) => {
        this.hls = result.default;
        this.hlsCallback?.();
      });
    }

    if (this.state.isPlaying) {
      activateKeepAwake(this.keepAwakeTag);
    }

    AccessibilityInfo.isScreenReaderEnabled().then((isScreenReaderEnabled) => {
      this.isScreenReaderEnabled = isScreenReaderEnabled;
      if (isScreenReaderEnabled) {
        this.toggleControls(true);
        clearTimeout(this.hideControlsTimeout);
      }
    });

    Sentry.addBreadcrumb({ message: 'MediaPlayer uri', data: { uri: this.props.uri } });
    AppState.addEventListener('change', this.onAppStateChange);
    if (this.props.autoPlay && !this.props.hideControls) {
      this.hideControlsTimeout = setTimeout(() => {
        this.setState({ isShowingControls: false });
        Animated.timing(this.controlsOpacity, {
          useNativeDriver: Platform.OS !== 'web',
          toValue: 0,
        }).start();
      }, 2000);
    }

    preloadPersistedState('enableClosedCaptions').then((value) => {
      if (typeof value === 'boolean') {
        this.setState({ isClosedCaptionEnabled: value });
      }
    });

    const sourceUri = getCachedUri(this.props.uri as string | number);
    if (sourceUri) {
      FileSystem.getInfoAsync(sourceUri).then(({ exists, ...rest }) => {
        Sentry.addBreadcrumb({ message: 'MediaPlayer cache', data: { exists, ...rest } });
        if (exists) {
          this.source = { uri: sourceUri };
        }
        this.setState({ initialized: true });
      });
    } else {
      this.setState({ initialized: true });
    }

    // https://github.com/expo/expo/pull/6610
    if (Platform.OS === 'web') {
      // @ts-ignore
      const isIE11 = !!window['MSStream'];
      document.addEventListener(
        isIE11 ? 'MSFullscreenChange' : 'fullscreenchange',
        this.onFullscreenChange,
      );
    }
  }

  componentWillUnmount() {
    if (this.state.isPlaying) {
      deactivateKeepAwake(this.keepAwakeTag);
    }

    AppState.removeEventListener('change', this.onAppStateChange);
    clearTimeout(this.hideControlsTimeout);
    deactivateKeepAwake(this.keepAwakeTag);

    if (Platform.OS === 'web') {
      // @ts-ignore
      const isIE11 = !!window['MSStream'];
      document.addEventListener(
        isIE11 ? 'MSFullscreenChange' : 'fullscreenchange',
        this.onFullscreenChange,
      );
    }
  }

  onVideoRef = (r: Video | null) => {
    // @ts-expect-error
    this.video.current = r;
    const source = this.props.uri;
    if (Platform.OS === 'web' && r && typeof source === 'string' && source.endsWith('.m3u8')) {
      this.hlsCallback = () => {
        const hls = new this.hls();
        // @ts-expect-error
        hls.attachMedia(r._nativeRef.current._video);
        hls.on(this.hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(source);
          hls.on(this.hls.Events.MANIFEST_PARSED, () => {
            if (this.props.autoPlay) {
              r.playAsync();
            }
          });
        });
        this.hlsCallback = undefined;
      };
      if (this.hls) this.hlsCallback();
    }
  };

  onFullscreenChange = (event: Event) => {
    if (!this.props.onFullscreenUpdate) return;

    if (event.target === (this.video?.current?._nativeRef?.current as any)?._video) {
      if (document.fullscreenElement) {
        this.props.onFullscreenUpdate({ fullscreenUpdate: 1 } as any);
      } else {
        this.props.onFullscreenUpdate({ fullscreenUpdate: 3 } as any);
      }
    }
  };

  onAppStateChange = (nextAppState: AppStateStatus) => {
    // People often expect videos to continue playing when switching tabs on web
    // and this is also currently forcing videos to autopause when fullscreening
    // because the state changes to "background" temporarily.
    // We could do some pause / resume state tracking here to auto play when
    // nextState returns to active but more trouble than it's worth.
    if (nextAppState !== 'active' && Platform.OS !== 'web') {
      this.setIsPlayingAsync(false);
    }
  };

  async presentFullscreenPlayer() {
    const ref = this.video.current || this.rnVideo.current;
    if (ref) {
      try {
        await ref.presentFullscreenPlayer();
      } catch (e) {}
    }
  }

  async dismissFullscreenPlayer() {
    const ref = this.video.current || this.rnVideo.current;
    if (ref) {
      try {
        await ref.dismissFullscreenPlayer();
      } catch (e) {}
    }
  }

  async setIsPlayingAsync(isPlaying: boolean) {
    if (this.video.current) {
      if (isPlaying) {
        await this.video.current.playAsync();
      } else {
        await this.video.current.pauseAsync();
      }
    }
    this.setState({ isPlaying });
    if (this.context.flags.useRNVideo) {
      if (isPlaying) {
        activateKeepAwake(this.keepAwakeTag);
      } else {
        deactivateKeepAwake(this.keepAwakeTag);
      }
    }
  }

  setPositionAsync(positionMillis: number) {
    if (this.video.current) {
      this.video.current.setPositionAsync(positionMillis);
    } else if (this.rnVideo.current) {
      this.rnVideo.current.seek(positionMillis / 1000);
    }
  }

  toggleControls = (forceShow?: boolean) => {
    if (this.state.miniPlayer) {
      if (forceShow) return;
      this.setState({ miniPlayer: false });
    } else {
      if (this.isScreenReaderEnabled || (forceShow && this.state.isShowingControls)) return;
      clearTimeout(this.hideControlsTimeout);
      this.setState({ isShowingControls: !this.state.isShowingControls }, () => {
        Animated.timing(this.controlsOpacity, {
          useNativeDriver: Platform.OS !== 'web',
          // in the setState callback, isShowingControls will already be updated to the destination state
          toValue: this.state.isShowingControls ? 1 : 0,
        }).start();
      });
    }
  };

  renderVolumeButton() {
    return (
      <View>
        <Icon
          accessibilityLabel={this.state.isMuted ? 'Unmute' : 'Mute'}
          color={this.state.miniPlayer ? this.props.miniPlayerControlsColor : 'white'}
          name={this.state.isMuted ? 'volume-off' : 'volume-on'}
          size={20}
          onPress={() => {
            if (this.video.current) {
              if (this.state.isMuted) {
                this.video.current.setIsMutedAsync(false);
              } else {
                this.video.current.setIsMutedAsync(true);
              }
            }
            this.setState({ isMuted: !this.state.isMuted });
          }}
        />
      </View>
    );
  }

  renderClosedCaptionsToggle() {
    return (
      <View row spacing={14}>
        <Icon
          accessibilityLabel={
            this.state.isClosedCaptionEnabled ? 'Disable captions' : 'Enable captions'
          }
          color="white"
          name={this.state.isClosedCaptionEnabled ? 'closed-caption' : 'closed-caption-off'}
          size={20}
          onPress={() => {
            setPersistedState('enableClosedCaptions', !this.state.isClosedCaptionEnabled);
            this.setState({ isClosedCaptionEnabled: !this.state.isClosedCaptionEnabled });
          }}
        />
        {this.renderShowTranscriptButton()}
      </View>
    );
  }

  renderShowTranscriptButton() {
    return (
      <View>
        <Icon
          accessibilityLabel="Show transcript"
          color="white"
          name="transcript"
          size={20}
          onPress={() => {
            this.setState({ isShowingTranscript: true });
          }}
        />
      </View>
    );
  }

  renderMiniPlayerToggle() {
    return (
      <View>
        <Icon
          accessibilityLabel={this.state.miniPlayer ? 'Maximize player' : 'Minimize player'}
          color={this.state.miniPlayer ? this.props.miniPlayerControlsColor : 'white'}
          name={this.state.miniPlayer ? 'maximize' : 'minimize'}
          size={20}
          onPress={() => {
            this.setState({ miniPlayer: !this.state.miniPlayer });
          }}
        />
      </View>
    );
  }

  renderPlayPauseButton() {
    return (
      <View>
        <Icon
          testID={this.state.isPlaying ? 'MediaPlayer_pauseButton' : 'MediaPlayer_playButton'}
          accessibilityLabel={this.state.isPlaying ? 'Pause' : 'Play'}
          color={this.state.miniPlayer ? this.props.miniPlayerControlsColor : 'white'}
          name={this.state.isPlaying ? 'pause' : 'play'}
          size={20}
          onPress={() => {
            if (!this.state.isPlaying && this.state.durationMillis === this.state.positionMillis) {
              this.setPositionAsync(0);
              this.setIsPlayingAsync(true);
            } else {
              this.setIsPlayingAsync(!this.state.isPlaying);
            }
          }}
        />
      </View>
    );
  }

  isAudio() {
    return typeof this.props.uri === 'number' || this.props.isAudio || isAudioURI(this.props.uri);
  }

  renderVideo() {
    const isAudio = this.isAudio();
    const source: number | { uri: string } =
      this.source ??
      (typeof this.props.uri === 'number'
        ? (this.props.uri as any as number)
        : { uri: this.props.uri });
    const style = [
      {
        backgroundColor: isAudio ? 'transparent' : 'black',
      },
      this.state.miniPlayer ? { height: '100%' } : { height: '100%', width: '100%' },
      isAudio && !this.props.subtitleUri ? { height: 0, width: 0 } : null,
    ];

    const onError = async (errorString?: string) => {
      const canReattempt = this.state.loadRetryAttempt < MAX_RETRY_ATTEMPTS;
      const isConnected = await fetchIsConnected();
      if (canReattempt) {
        Sentry.withScope((scope) => {
          scope.setExtras({
            cachedSource: this.source,
            isConnected,
            loadRetryAttempt: this.state.loadRetryAttempt,
            MAX_RETRY_ATTEMPTS,
          });
          Sentry.captureMessage(`MediaPlayer error: ${errorString}`, Sentry.Severity.Warning);
        });
        if (this.source?.uri) {
          await FileSystem.deleteAsync(this.source.uri);
          this.source = undefined;
        }
        setTimeout(() => {
          this.setState({ loadRetryAttempt: this.state.loadRetryAttempt + 1 });
        }, this.state.loadRetryAttempt * 100);
        // return so we don't bubble the error up until our reattempts have been exhausted
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtras({ isConnected });
        Sentry.captureException(errorString);
      });
      this.props.onError?.();
    };

    if (!this.state.initialized) return null;

    if (this.context.flags.useRNVideo) {
      return (
        <MuxVideo
          accessible={false}
          importantForAccessibility="no-hide-descendants"
          key={this.state.loadRetryAttempt.toString()}
          ref={this.rnVideo}
          resizeMode={this.props.resizeMode ?? Video.RESIZE_MODE_CONTAIN}
          poster={typeof this.props.posterSource === 'string' ? this.props.posterSource : undefined}
          disableFocus
          mixWithOthers="mix"
          paused={!this.state.isPlaying}
          ignoreSilentSwitch="ignore"
          muted={this.state.isMuted}
          source={source}
          selectedTextTrack={
            isClosedCaptionAvailableForUri(this.props.uri) && this.state.isClosedCaptionEnabled
              ? { type: 'index', value: 0 }
              : undefined
          }
          textTracks={
            isClosedCaptionAvailableForUri(this.props.uri) &&
            // mux includes captions in m3u8 manifest file
            !isMuxUrl(this.props.uri)
              ? [
                  {
                    title: 'English Subtitles',
                    language: 'en',
                    type: TextTrackType.VTT,
                    uri: this.props.uri.replace('.mp4', '.vtt'),
                  },
                ]
              : undefined
          }
          style={style}
          onLoad={(data) => {
            Sentry.addBreadcrumb({ message: 'MediaPlayer (RNVideo) onLoad', data });
            const positionMillis =
              this.props.initialPositionMillis ?? (data.currentTime ?? 0) * 1000;
            if (this.props.initialPositionMillis) {
              this.setPositionAsync(this.props.initialPositionMillis);
            }
            this.setState({
              isLoaded: true,
              durationMillis: data.duration * 1000,
              positionMillis,
            });
          }}
          onProgress={({ currentTime }) => {
            const positionMillis = currentTime * 1000;
            this.setState({ positionMillis });
            this.props.onProgress?.(positionMillis);
          }}
          onError={(result) => {
            onError(result.error.errorString ?? JSON.stringify(result.error));
          }}
          onEnd={() => {
            Sentry.addBreadcrumb({
              message: 'MediaPlayer onEnd',
              data: {
                isPlaying: this.state.isPlaying,
                positionMillis: this.state.positionMillis,
                durationMillis: this.state.durationMillis,
              },
            });
            this.setState({ isPlaying: false, positionMillis: this.state.durationMillis });
            this.props.onEnd?.();
          }}
          onFullscreenPlayerWillPresent={() => {
            const fullscreenUpdate = Video.FULLSCREEN_UPDATE_PLAYER_WILL_PRESENT;
            this.props.onFullscreenUpdate?.({ fullscreenUpdate });
          }}
          onFullscreenPlayerWillDismiss={() => {
            const fullscreenUpdate = Video.FULLSCREEN_UPDATE_PLAYER_WILL_DISMISS;
            this.props.onFullscreenUpdate?.({ fullscreenUpdate });
          }}
          onFullscreenPlayerDidPresent={() => {
            const fullscreenUpdate = Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT;
            this.props.onFullscreenUpdate?.({ fullscreenUpdate });
          }}
          onFullscreenPlayerDidDismiss={() => {
            const fullscreenUpdate = Video.FULLSCREEN_UPDATE_PLAYER_DID_DISMISS;
            this.props.onFullscreenUpdate?.({ fullscreenUpdate });
          }}
          muxOptions={
            {
              application_name: APP_SLUG,
              application_version: manifest.version,
              data: {
                viewer_user_id: getClaimsSync()?.user_id,
                env_key: MUX_ENV_KEY,
                video_id: this.props.uri.split('/').reverse()[0],
                video_title: this.props.title ?? this.props.uri.split('/').reverse()[0],
              },
            } as MuxOptions
          }
        />
      );
    }

    return (
      <Video
        key={this.state.loadRetryAttempt.toString()}
        ref={this.video}
        resizeMode={this.props.resizeMode ?? Video.RESIZE_MODE_CONTAIN}
        posterSource={this.props.posterSource}
        posterStyle={this.props.posterSource ? { width: '100%', height: '100%' } : undefined}
        usePoster={!!this.props.posterSource}
        onFullscreenUpdate={this.props.onFullscreenUpdate}
        shouldPlay={this.props.autoPlay}
        source={source}
        status={{ positionMillis: this.props.initialPositionMillis || 0 }}
        style={style}
        onLoad={(status) => {
          Sentry.addBreadcrumb({ message: 'MediaPlayer onLoad', data: status });
        }}
        onPlaybackStatusUpdate={(status) => {
          if (status.isLoaded) {
            if (status.isPlaying && !this.state.isPlaying) {
              activateKeepAwake(this.keepAwakeTag);
            } else if (!status.isPlaying && this.state.isPlaying) {
              deactivateKeepAwake(this.keepAwakeTag);
            }

            this.setState({
              durationMillis: status.durationMillis || 0,
              isLoaded: true,
              isMuted: status.isMuted,
              isPlaying: status.isPlaying,
              positionMillis: status.positionMillis || 0,
            });

            this.props.onProgress?.(status.positionMillis);
            if (status.didJustFinish) {
              Sentry.addBreadcrumb({ message: 'MediaPlayer onEnd (didJustFinish)' });
              this.props.onEnd?.();
            }
          } else if (status.error) {
            onError(status.error);
          }
        }}
      />
    );
  }

  render() {
    const textStyle = this.state.miniPlayer
      ? {
          color: this.props.miniPlayerControlsColor,
        }
      : {
          color: 'white',
          textShadowColor: 'black',
          textShadowOffset: { width: 0, height: 0 },
          textShadowRadius: 2,
        };
    const isAudio = this.isAudio();
    const aspectRatio =
      this.props.aspectRatio !== null
        ? this.props.aspectRatio ??
          (isAudio ? 1 : this.props.uri.toString().includes('v1_1') ? 9 / 16 : 16 / 9)
        : null;

    return (
      <View
        onLayout={
          aspectRatio === null
            ? undefined
            : (event) => {
                const layout = event.nativeEvent.layout;
                const isHeightConstrained = layout.width / layout.height > aspectRatio + 0.05; // tolerance
                if (isHeightConstrained !== this.state.isHeightConstrained) {
                  this.setState({ isHeightConstrained });
                }
              }
        }
        row
        style={{
          height: this.state.miniPlayer ? 70 : undefined,
          minHeight: 70,
          width: '100%',
        }}
      >
        {this.state.isLoaded ? null : (
          <View
            style={[
              StyleSheet.absoluteFillObject,
              {
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
              },
            ]}
          >
            <ActivityIndicator />
          </View>
        )}
        <TouchableOpacity
          testID="MediaPlayer_videoContainer"
          activeOpacity={1}
          disabled={this.props.hideControls}
          style={{
            flexGrow: this.state.miniPlayer ? 0 : 1,
            maxWidth: '100%',
            backgroundColor: 'black',
            alignItems: this.state.isHeightConstrained ? 'center' : undefined,
          }}
          onPress={() => this.toggleControls()}
          accessible={false}
          importantForAccessibility="no"
        >
          {aspectRatio === null ? (
            this.renderVideo()
          ) : (
            <AspectRatio aspectRatio={aspectRatio}>{this.renderVideo()}</AspectRatio>
          )}
          {this.props.subtitleUri ? (
            <View style={{ position: 'absolute', right: 10, left: 10, bottom: 50 }}>
              <Subtitles
                positionMillis={this.state.positionMillis}
                uri={this.props.subtitleUri}
                enabled={this.state.isClosedCaptionEnabled}
              />
            </View>
          ) : null}
          {!this.state.miniPlayer ? (
            <Animated.View
              style={[StyleSheet.absoluteFillObject, { opacity: this.controlsOpacity }]}
            >
              <VideoLinearGradient />
            </Animated.View>
          ) : null}
        </TouchableOpacity>
        {this.props._enableSkipToEnd ? (
          <View
            style={{
              position: 'absolute',
              top: 5,
              left: 5,
              // for android
              zIndex: 5,
            }}
            testID="MediaPlayer_skipToEnd"
          >
            <Icon
              accessibilityLabel="Skip"
              color="rgba(0,0,0,0.6)"
              name="skip-next"
              size={20}
              onPress={() => {
                this.setIsPlayingAsync(false);
                this.props.onEnd?.();
              }}
            />
          </View>
        ) : null}
        {!this.state.miniPlayer ? (
          <Animated.View
            pointerEvents={this.state.isShowingControls ? 'box-none' : 'none'}
            style={{
              position: 'absolute',
              top: 4,
              right: 4,
              opacity: this.controlsOpacity,
              backgroundColor: 'rgba(255,255,255,0.3)',
              borderRadius: 50,
            }}
          >
            {this.props.onClose ? (
              <Icon
                accessibilityLabel="Close"
                color="rgba(0,0,0,0.6)"
                name="close"
                size={20}
                onPress={this.props.onClose}
              />
            ) : null}
          </Animated.View>
        ) : null}
        <Animated.View
          pointerEvents={
            this.state.isShowingControls || this.state.miniPlayer ? 'box-none' : 'none'
          }
          style={[
            {
              opacity: this.state.miniPlayer ? 1 : this.controlsOpacity,
              padding: this.state.miniPlayer ? 12 : 6,
              flex: 1,
              justifyContent: 'space-between',
            },
            this.state.miniPlayer
              ? null
              : {
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                },
          ]}
        >
          {this.props.title ? (
            <Text text={this.props.title} weight="bold" size={20} style={textStyle} />
          ) : (
            <View />
          )}
          <View row spacing={14}>
            {this.state.miniPlayer ? null : this.renderPlayPauseButton()}
            <Text
              accessibilityRole="none"
              style={textStyle}
              text={getMMSSFromMillis(this.state.positionMillis, this.state.durationMillis)}
              size={12}
            />
            <View style={{ flex: 1 }}>
              <Slider
                style={{ height: 20 }}
                animateTransitions={true}
                accessible={true}
                accessibilityLabel={`${getMMSSFromMillis(
                  this.state.positionMillis,
                  this.state.durationMillis,
                  { accessible: true },
                )} elapsed of ${getMMSSFromMillis(
                  this.state.durationMillis,
                  this.state.durationMillis,
                  { accessible: true },
                )}`}
                accessibilityRole="adjustable"
                accessibilityActions={[
                  { name: 'increment', label: 'increment' },
                  { name: 'decrement', label: 'decrement' },
                ]}
                onAccessibilityAction={(event) => {
                  switch (event.nativeEvent.actionName) {
                    case 'increment': {
                      const newValue = Math.min(
                        this.state.positionMillis + 10000,
                        this.state.durationMillis,
                      );
                      this.setPositionAsync(newValue);
                      if (!this.state.isPlaying) {
                        this.setState({ positionMillis: newValue });
                      }
                      if (Platform.OS === 'ios') {
                        AccessibilityInfo.announceForAccessibility('seeked forward');
                      }
                      break;
                    }
                    case 'decrement': {
                      const newValue = Math.max(this.state.positionMillis - 10000, 0);
                      this.setPositionAsync(newValue);
                      if (Platform.OS === 'ios') {
                        AccessibilityInfo.announceForAccessibility('seeked backward');
                      }
                      break;
                    }
                  }
                }}
                disabled={false}
                minimumValue={0}
                maximumValue={this.state.durationMillis}
                minimumTrackTintColor={this.state.miniPlayer ? Color.primary : 'white'}
                maximumTrackTintColor={
                  this.state.miniPlayer ? hexToRgba(Color.primary, 0.3) : 'rgba(255,255,255, 0.3)'
                }
                value={this.state.positionMillis}
                thumbStyle={[
                  {
                    width: 10,
                    height: 10,
                    backgroundColor: 'white',
                  },
                  Shadow.default,
                ]}
                onValueChange={noop}
                onSlidingComplete={(v) => this.setPositionAsync(v)}
              />
            </View>
            <Text
              accessibilityRole="none"
              style={textStyle}
              text={getMMSSFromMillis(this.state.durationMillis, this.state.durationMillis)}
              size={12}
            />
            {this.state.miniPlayer ? null : this.renderVolumeButton()}
            {this.state.miniPlayer
              ? null
              : this.props.subtitleUri || isClosedCaptionAvailableForUri(this.props.uri)
              ? this.renderClosedCaptionsToggle()
              : this.props.allowMini
              ? this.renderMiniPlayerToggle()
              : null}
          </View>
        </Animated.View>
        {this.state.miniPlayer ? (
          <View row spacing={14} style={{ padding: 12 }}>
            {this.renderPlayPauseButton()}
            {this.renderVolumeButton()}
          </View>
        ) : null}
        {this.state.isShowingTranscript ? (
          <TranscriptModal
            uri={
              this.props.subtitleUri ??
              (isMuxUrl(this.props.uri)
                ? getMuxVTTUri(this.props.uri)!
                : this.props.uri.replace('.mp4', '.vtt'))
            }
            onRequestClose={() => this.setState({ isShowingTranscript: false })}
          />
        ) : null}
      </View>
    );
  }
}

type FullscreenMediaPlayerProps = {
  borderRadius?: number;
  initialPositionMillis?: number;
  posterInitialPositionMillis?: number;
  posterSource?: ImageURISource | number;
  caption?: string | React.ReactNode;
  uri: string;
  previewAspectRatio?: number;
  mediaAspectRatio?: number | null;
};

const posterCache: { [key: string]: string | undefined } = {};

function getPosterCacheKey({
  uri,
  initialPositionMillis = 0,
}: {
  uri: string;
  initialPositionMillis?: number;
}) {
  return `${uri}::${initialPositionMillis}`;
}

export const FullscreenMediaPlayer = forwardRef<MediaPlayer, FullscreenMediaPlayerProps>(function (
  props,
  ref,
) {
  const [isSimulatorFullscreen, setIsSimulatorFullscreen] = useState(false);
  const player = useRef<MediaPlayer>(null);
  const useBuiltInFullscreenPlayer = Platform.OS === 'web'; // Constants.isDevice; https://github.com/ouihealth/oui/issues/605
  const [previewUri, setPreviewUri] = useState(posterCache[getPosterCacheKey(props)] ?? '');
  React.useImperativeHandle(ref, () => player.current!);

  useEffect(() => {
    async function generateThumbnail(allowCache = true) {
      const cacheKey = getPosterCacheKey({
        uri: props.uri,
        initialPositionMillis: props.posterInitialPositionMillis ?? props.initialPositionMillis,
      });
      if (Platform.OS !== 'web' && !posterCache[cacheKey]) {
        let cacheExists = false;
        const cachedUri = getCachedUri(props.uri);
        let cacheFileResult: FileSystem.FileInfo;
        try {
          try {
            cacheFileResult = await FileSystem.getInfoAsync(cachedUri);
            cacheExists = cacheFileResult.exists;
          } catch (e) {
            Sentry.captureException(e);
          }
          const thumbnailSourceUri = cacheExists && allowCache ? cachedUri : props.uri;
          const result = await VideoThumbnails.getThumbnailAsync(thumbnailSourceUri, {
            time: props.posterInitialPositionMillis ?? props.initialPositionMillis ?? 0,
          });
          posterCache[cacheKey] = result.uri;
          setPreviewUri(result.uri);
        } catch (e) {
          Sentry.withScope((scope) => {
            scope.setExtras({ cacheFileResult, cacheExists, cachedUri, uri: props.uri });
            Sentry.captureException(e);
          });
          if (cacheExists) {
            generateThumbnail(false);
          }
        }
      }
    }

    if (!isAudioURI(props.uri)) {
      generateThumbnail();
    }
  }, [props.uri, props.initialPositionMillis, props.posterInitialPositionMillis]);

  return (
    <TouchableOpacity
      style={{
        borderWidth: 1,
        borderColor: Color.styleGuide.Gray6,
        borderRadius: props.borderRadius,
      }}
      onPress={async () => {
        if (useBuiltInFullscreenPlayer) {
          if (player.current) {
            await player.current.presentFullscreenPlayer();
            await player.current.setIsPlayingAsync(true);
          }
        } else {
          setIsSimulatorFullscreen(true);
        }
      }}
    >
      <View pointerEvents="none">
        {!useBuiltInFullscreenPlayer && isSimulatorFullscreen ? (
          <Modal visible={true} onRequestClose={() => setIsSimulatorFullscreen(false)}>
            <StatusBar backgroundColor="black" barStyle="light-content" />
            <TouchableOpacity
              accessible={false}
              importantForAccessibility="no"
              activeOpacity={1}
              style={{ flex: 1, justifyContent: 'center', backgroundColor: 'black' }}
              onPress={() => setIsSimulatorFullscreen(false)}
            >
              <SafeAreaView
                style={[
                  {
                    backgroundColor: 'rgba(0,0,0,0.6)',
                  },
                  isSimulatorFullscreen
                    ? undefined
                    : {
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                      },
                ]}
              >
                <View style={{ padding: 10, alignItems: 'flex-start' }}>
                  <Icon
                    accessibilityLabel="Exit fullscreen"
                    name="close"
                    onPress={() => setIsSimulatorFullscreen(false)}
                    color="white"
                  />
                </View>
              </SafeAreaView>
              <SafeAreaView style={{ flex: 1 }}>
                <TouchableOpacity
                  accessible={false}
                  accessibilityLabel={undefined}
                  accessibilityRole={undefined}
                  activeOpacity={1}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: isSimulatorFullscreen ? 1 : undefined,
                  }}
                >
                  <MediaPlayer
                    aspectRatio={props.mediaAspectRatio}
                    uri={props.uri}
                    allowMini={false}
                    autoPlay={true}
                    hideControls={false}
                    onEnd={() => setIsSimulatorFullscreen(false)}
                  />
                </TouchableOpacity>
              </SafeAreaView>
            </TouchableOpacity>
            {props.caption ? (
              <SafeAreaView
                style={[
                  {
                    backgroundColor: 'black',
                  },
                  isSimulatorFullscreen
                    ? undefined
                    : {
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        left: 0,
                      },
                ]}
              >
                <ScrollView style={{ maxHeight: 150 }} contentContainerStyle={{ padding: 10 }}>
                  {typeof props.caption === 'string' ? (
                    <Text text={props.caption} color="white" textAlign="center" weight="semibold" />
                  ) : (
                    props.caption
                  )}
                </ScrollView>
              </SafeAreaView>
            ) : null}
          </Modal>
        ) : null}
        {useBuiltInFullscreenPlayer ? (
          <MediaPlayer
            initialPositionMillis={props.initialPositionMillis}
            uri={props.uri}
            ref={player}
            allowMini={false}
            autoPlay={false}
            hideControls={true}
            onFullscreenUpdate={({ fullscreenUpdate }) => {
              if (fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_DID_DISMISS) {
                if (player.current) {
                  player.current.setIsPlayingAsync(false);
                }
              } else if (fullscreenUpdate === Video.FULLSCREEN_UPDATE_PLAYER_DID_PRESENT) {
                if (player.current) {
                  player.current.setIsPlayingAsync(true);
                }
              }
            }}
            onEnd={() => {
              if (player.current) {
                player.current.setPositionAsync(0);
                player.current.dismissFullscreenPlayer();
              }
            }}
          />
        ) : null}
        <View row style={useBuiltInFullscreenPlayer ? StyleSheet.absoluteFillObject : null}>
          <AspectRatio
            aspectRatio={props.previewAspectRatio ?? 16 / 9}
            style={{
              width: '100%',
              flexGrow: 1,
            }}
          >
            <View
              style={{
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {props.posterSource ?? previewUri ? (
                <Image
                  resizeMethod="resize"
                  source={props.posterSource ?? { uri: previewUri }}
                  style={[
                    StyleSheet.absoluteFillObject,
                    { height: '100%', width: '100%', borderRadius: props.borderRadius },
                  ]}
                />
              ) : isAudioURI(props.uri) ? (
                <View
                  pointerEvents="none"
                  style={[
                    StyleSheet.absoluteFillObject,
                    {
                      zIndex: 1,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingTop: 10,
                      paddingLeft: 20,
                    },
                  ]}
                  row
                >
                  <Icon name="music-note" size={80} color="rgba(0,0,0,0.1)" />
                  <Icon
                    name="music-note"
                    size={80}
                    color="rgba(0,0,0,0.1)"
                    style={{ position: 'relative', left: -30, top: -10 }}
                  />
                </View>
              ) : (
                <View
                  style={[
                    StyleSheet.absoluteFillObject,
                    {
                      zIndex: 1,
                      top: 0,
                      bottom: 0,
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent: 'center',
                    },
                  ]}
                >
                  <ActivityIndicator size="small" />
                </View>
              )}
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 75,
                  height: 75,
                  backgroundColor: 'rgba(255,255,255,0.8)',
                  borderRadius: 75 / 2,
                }}
              >
                <Icon color={Color.accent} name="play" size={40} />
              </View>
            </View>
          </AspectRatio>
        </View>
      </View>
    </TouchableOpacity>
  );
});
