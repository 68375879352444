import { ApolloLink, Observable, gql as gqlDeprecated } from '@apollo/client';
import { Kind, ArgumentNode, getOperationAST } from 'graphql';

const RESPONSEQUERY = gqlDeprecated`
  query PatientResponse($context: String!, $key: String!, $patientID: UserID!) {
    patientResponse(context: $context, key: $key, patientID: $patientID)
  }
`;

export const localPatientResponseLink = new ApolloLink((operation, forward) => {
  // console.log(
  //   `starting request for ${operation.operationName}`,
  //   operation,
  //   getOperationAST(operation.query, null),
  // );
  const localCache = operation.getContext().cache;
  const gqlOperation = getOperationAST(operation.query, null);

  function getArgumentValue(argument?: ArgumentNode) {
    if (!argument) return undefined;
    if (argument.value.kind === Kind.STRING) {
      return argument.value.value;
    } else if (argument.value.kind === Kind.VARIABLE) {
      return operation.variables[argument.value.name.value];
    }
  }

  if (gqlOperation?.operation === 'mutation') {
    let handleReturn: Record<string, boolean> = {};
    const selections = gqlOperation?.selectionSet.selections;
    selections?.forEach((selection) => {
      if (selection.kind === Kind.FIELD && selection.name.value === 'respondForPatient') {
        handleReturn[selection.alias?.value ?? selection.name.value] = true;
        // console.log('respondForPatient');
        // console.log('selection', selection);
        const patientID = getArgumentValue(
          selection.arguments?.find((a) => a.name.value === 'patientID'),
        );
        const context = getArgumentValue(
          selection.arguments?.find((a) => a.name.value === 'context'),
        );
        const key = getArgumentValue(selection.arguments?.find((a) => a.name.value === 'key'));
        const data = getArgumentValue(selection.arguments?.find((a) => a.name.value === 'data'));
        // console.log(
        //   localCache.readQuery({
        //     query: RESPONSEQUERY,
        //     variables: {
        //       patientID,
        //       context,
        //       key,
        //     },
        //   }),
        // );
        localCache.writeQuery({
          query: RESPONSEQUERY,
          variables: {
            patientID,
            context,
            key,
          },
          data: { patientResponse: data },
        });
      }
    });
    if (Object.keys(handleReturn).length) {
      return Observable.from([{ data: handleReturn }]);
    }
  }
  return forward(operation).map((data) => {
    // console.log(`ending request for ${operation.operationName}`, data);
    return data;
  });
});
