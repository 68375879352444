import React from 'react';
import { TouchableWithoutFeedback } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import Constants from 'expo-constants';
import { createStackNavigator } from '@react-navigation/stack';
import { CachePersistor } from 'apollo3-cache-persist';

import { Color } from '@src/styles';
import { JcoStackParamList, JcoMyStoryMyPlanStackParamList } from '@src/types';
import MyPlanExplanation from '@src/screens/MyPlanExplanation';
import MyPlanForm from '@src/screens/MyPlanForm';
import MyStoryMyPlanOverview from '@src/screens/MyStoryMyPlanOverview';
import PatientNarrativeExplanation from '@src/screens/PatientNarrativeExplanation';
import PatientNarrativeForm from '@src/screens/PatientNarrativeForm';
import SuicidalModeExplanation from '@src/screens/SuicidalModeExplanation';
import SuicidalModeForm from '@src/screens/SuicidalModeForm';
import AppContainer from '@src/components/AppContainer';
import { createApolloClient, createApolloCache } from '@src/lib/apolloClient';
import ApolloClient from '@src/lib/apollo';

import { CliniciansHeader } from '@src/components/CliniciansHeader';
import { View } from '@src/components/View';

import NewSafetyScreening from '@src/screens/NewSafetyScreening';
import SafetyScreeningResults from '@src/screens/SafetyScreeningResults';
import SafetyDiagnostic from '@src/screens/SafetyDiagnostic';
import CAPS_Logo from '@src/assets/CAPS_Logo.svg';
import { DEFAULT_HEADER_MODE } from '@src/constants';
import { localPatientResponseLink } from '@src/lib/localPatientResponseLink';
import { getConfigString } from '@src/lib/remoteConfig';

const MyStoryMyPlanStack = createStackNavigator<JcoMyStoryMyPlanStackParamList>();
const MyStoryMyPlan = () => (
  <MyStoryMyPlanStack.Navigator
    initialRouteName="MyStoryMyPlanOverview"
    headerMode={DEFAULT_HEADER_MODE}
    screenOptions={(props) => ({
      headerTitleAlign: 'center',
      headerBackTitleVisible: false,
      headerTintColor: 'white',
      headerStyle: { backgroundColor: Color.tertiary, height: 88 - Constants.statusBarHeight },
      headerTitle: () => <CliniciansHeader {...(props as any)} />,
      headerLeft: () => null,
    })}
    // initialRouteParams={{ patientID: '00000000-0000-0000-0000-000000000001' }}
  >
    <MyStoryMyPlanStack.Screen name="MyPlanExplanation" component={MyPlanExplanation} />
    <MyStoryMyPlanStack.Screen name="MyPlanForm" component={MyPlanForm} />
    <MyStoryMyPlanStack.Screen name="MyStoryMyPlanOverview" component={MyStoryMyPlanOverview} />
    <MyStoryMyPlanStack.Screen
      name="PatientNarrativeExplanation"
      component={PatientNarrativeExplanation}
    />
    <MyStoryMyPlanStack.Screen name="PatientNarrativeForm" component={PatientNarrativeForm} />
    <MyStoryMyPlanStack.Screen name="PatientOnboardingEnd" component={MyStoryMyPlanOverview} />
    <MyStoryMyPlanStack.Screen name="SuicidalModeExplanation" component={SuicidalModeExplanation} />
    <MyStoryMyPlanStack.Screen name="SuicidalModeForm" component={SuicidalModeForm} />
  </MyStoryMyPlanStack.Navigator>
);

const CliniciansStack = createStackNavigator<JcoStackParamList>();
const Clinicians = () => (
  <CliniciansStack.Navigator
    initialRouteName="NewSafetyScreening"
    headerMode={DEFAULT_HEADER_MODE}
    screenOptions={({ navigation, route }) => ({
      headerTitle: '',
      headerTitleAlign: 'center',
      cardStyle: { backgroundColor: Color.grayBackground },
      headerTintColor: 'white',
      headerStyle: { backgroundColor: Color.tertiary },
      headerLeft: () => {
        const params: object = (route as any).params;

        return (
          <TouchableWithoutFeedback
            onPress={() =>
              navigation.navigate(
                'NewSafetyScreening',
                Object.keys(params || {}).reduce((carry: any, key) => {
                  carry[key] = undefined;
                  return carry;
                }, {}),
              )
            }
          >
            <View row spacing={12} style={{ marginLeft: 20 }}>
              <CAPS_Logo color="white" height={20} width={80} accessibilityLabel="CAPS" />
            </View>
          </TouchableWithoutFeedback>
        );
      },
    })}
  >
    <CliniciansStack.Screen name="NewSafetyScreening" component={NewSafetyScreening} />
    <CliniciansStack.Screen name="SafetyDiagnostic" component={SafetyDiagnostic} />
    <CliniciansStack.Screen name="SeverityDiagnostic" component={SafetyDiagnostic} />
    <CliniciansStack.Screen name="SafetyScreeningResults" component={SafetyScreeningResults} />
    <CliniciansStack.Screen
      name="MyStoryMyPlan"
      component={MyStoryMyPlan}
      options={{ headerShown: false }}
    />
  </CliniciansStack.Navigator>
);

const DEEPLINK_CONFIG = {
  NewSafetyScreening: '',
  SafetyDiagnostic: 'SafetyDiagnostic',
  SeverityDiagnostic: 'SeverityDiagnostic',
  SafetyScreeningResults: 'SafetyScreeningResults',
  MyStoryMyPlan: {
    path: 'MyStoryMyPlan',
  },
};

export default class App extends React.Component {
  apollo!: ApolloClient<object>;
  state = { loaded: false };
  componentDidMount() {
    const cache = createApolloCache();
    const persister = new CachePersistor({
      cache,
      storage: AsyncStorage,
    });
    persister
      .restore()
      .then(() => {
        this.apollo = createApolloClient(getConfigString('apiUrl'), {
          connectToDevTools: true,
          cache,
          preHttpLink: localPatientResponseLink,
          defaultOptions: {
            query: { fetchPolicy: 'cache-only' },
            watchQuery: {
              fetchPolicy: 'cache-only',
              nextFetchPolicy: 'cache-only',
              returnPartialData: true,
            },
          },
        });
      })
      .then(() => this.setState({ loaded: true }));
    (global as any).persister = persister;
  }
  render() {
    return this.state.loaded ? (
      <AppContainer
        apollo={this.apollo}
        app={() => <Clinicians />}
        deeplinkConfig={{ screens: DEEPLINK_CONFIG }}
        welcomePath="NewSafetyScreening"
        initialPath="NewSafetyScreening"
      />
    ) : null;
  }
}
