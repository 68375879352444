import React from 'react';
import { locale } from 'expo-localization';
import { FLAGS } from '@src/constants';
import { NavigationContainerRef } from '@react-navigation/native';
import { DeeplinkConfigShape } from '@src/types/navigation';

export type DeeplinkOptions = DeeplinkConfigShape<string>;

export default React.createContext<{
  deeplinkConfig?: { screens: DeeplinkOptions };
  flags: typeof FLAGS;
  locale: string;
  navigationContainer: NavigationContainerRef | null;
}>({
  deeplinkConfig: undefined,
  flags: FLAGS,
  locale,
  navigationContainer: null,
});
