import { useQuery, gql as gqlDeprecated } from '@apollo/client';
import React from 'react';
import { StatusBar, TouchableOpacity } from 'react-native';
import hexToRgba from 'hex-to-rgba';

import { View } from '@src/components/View';
import { Text } from '@src/components/Text';
import Check from '@src/assets/check.svg';
import {
  CliniciansHeaderStatus,
  CliniciansHeaderStatusVariables,
} from './__generated__/CliniciansHeaderStatus';
import { useT } from '@src/lib/i18n';
import { JcoMyStoryMyPlanScreenProps, JcoMyStoryMyPlanStackParamList } from '@src/types';

const StatusQuery = gqlDeprecated`
  query CliniciansHeaderStatus($patientID: UserID!) {
    MyStoryMyPlanOverview: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "MyStoryMyPlanOverviewComplete"
    )
    PatientNarrativeExplanation: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "PatientNarrativeExplanationComplete"
    )
    PatientNarrativeForm: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "PatientNarrativeFormComplete"
    )
    SuicidalModeExplanation: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "SuicidalModeExplanationComplete"
    )
    SuicidalModeForm: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "SuicidalModeFormComplete"
    )
    MyPlanExplanation: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "MyPlanExplanationComplete"
    )
    MyPlanForm: patientResponse(
      patientID: $patientID
      context: "MyStoryMyPlan"
      key: "MyPlanFormComplete"
    )
  }
`;

const DONE_COLOR = '#bed3c3';
const HORIZONTAL_SPACING = 3;
const VERTICAL_SPACING = 3;

function colorForStatus(status?: 'active' | 'complete') {
  return status === 'active'
    ? 'white'
    : status === 'complete'
    ? DONE_COLOR
    : 'rgba(255,255,255, 0.5)';
}

function ProgressOrb({ isComplete, isActive }: { isComplete: boolean; isActive: boolean }) {
  const backgroundColor = colorForStatus(isComplete ? 'complete' : isActive ? 'active' : undefined);
  return (
    <View
      style={{
        width: 19,
        height: 19,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: isActive ? hexToRgba(DONE_COLOR, 0.5) : 'transparent',
      }}
    >
      <View
        style={{
          width: 15,
          height: 15,
          borderRadius: 8,
          backgroundColor,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isComplete ? <Check accessibilityLabel="Completed" /> : null}
      </View>
    </View>
  );
}

function ProgressBarSegment(props: { status?: 'active' | 'complete'; onPress?: () => void }) {
  const backgroundColor = colorForStatus(props.status);
  return (
    <TouchableOpacity
      hitSlop={{ top: 16, bottom: 20, left: 0, right: 0 }}
      style={{ borderRadius: 4, backgroundColor, height: 5, width: 65 }}
      disabled={props.status === 'active'}
      onPress={props.onPress}
    />
  );
}

function Title(props: { text: string; status?: 'active' | 'complete' }) {
  return (
    <Text
      text={props.text}
      color={colorForStatus(props.status || 'active')}
      weight="bold"
      style={{ marginLeft: HORIZONTAL_SPACING, fontSize: 15 }}
    />
  );
}

const ROUTE_ORDER = [
  'MyStoryMyPlanOverview',
  'PatientNarrativeExplanation',
  'PatientNarrativeForm',
  'SuicidalModeExplanation',
  'SuicidalModeForm',
  'MyPlanExplanation',
  'MyPlanForm',
];

export function CliniciansHeader(
  props: JcoMyStoryMyPlanScreenProps<keyof JcoMyStoryMyPlanStackParamList>,
) {
  const routeName = props.route.name;
  const currentIndex = ROUTE_ORDER.indexOf(routeName);
  const t = useT();

  const { data } = useQuery<CliniciansHeaderStatus, CliniciansHeaderStatusVariables>(StatusQuery, {
    variables: { patientID: props.route.params?.patientID! },
  });

  function getStatusForRoute(...routes: string[]) {
    const statuses = routes.map((route) => {
      const routeIndex = ROUTE_ORDER.indexOf(route);
      if (routeIndex === currentIndex) return 'active' as const;
      if (data && (data as any)[route]) return 'complete' as const;
      return undefined;
    });

    if (statuses.length === 1) return statuses[0];
    if (statuses.every((s) => s === 'complete')) return 'complete' as const;
    if (statuses.some((s) => s === 'active')) return 'active' as const;

    return undefined;
  }

  function getPropsForSegment(route: keyof JcoMyStoryMyPlanStackParamList) {
    return {
      status: getStatusForRoute(route),
      onPress: () => props.navigation.navigate(route, props.route.params),
    };
  }

  return (
    <View row spacing={HORIZONTAL_SPACING}>
      <StatusBar barStyle="light-content" />
      <View spacing={VERTICAL_SPACING}>
        <Title
          text={t('mystory_myplan_header.overview')}
          status={getStatusForRoute('MyStoryMyPlanOverview')}
        />
        <View row spacing={HORIZONTAL_SPACING}>
          <ProgressOrb
            isComplete={!!(data && data['MyStoryMyPlanOverview'])}
            isActive={getStatusForRoute('MyStoryMyPlanOverview') === 'active'}
          />
          <ProgressBarSegment {...getPropsForSegment('MyStoryMyPlanOverview')} />
        </View>
      </View>
      <View spacing={VERTICAL_SPACING}>
        <Title
          text={t('mystory_myplan_header.mystory')}
          status={getStatusForRoute('PatientNarrativeExplanation')}
        />
        <View row spacing={HORIZONTAL_SPACING}>
          <ProgressOrb
            isComplete={
              !!(data && data['PatientNarrativeForm'] && data['PatientNarrativeExplanation'])
            }
            isActive={
              getStatusForRoute('PatientNarrativeForm', 'PatientNarrativeExplanation') === 'active'
            }
          />
          <ProgressBarSegment {...getPropsForSegment('PatientNarrativeExplanation')} />
          <ProgressBarSegment {...getPropsForSegment('PatientNarrativeForm')} />
        </View>
      </View>
      <View spacing={VERTICAL_SPACING}>
        <Title
          text={t('mystory_myplan_header.suicidal_mode')}
          status={getStatusForRoute('SuicidalModeForm')}
        />
        <View row spacing={HORIZONTAL_SPACING}>
          <ProgressOrb
            isComplete={!!(data && data['SuicidalModeForm'] && data['SuicidalModeExplanation'])}
            isActive={getStatusForRoute('SuicidalModeForm', 'SuicidalModeExplanation') === 'active'}
          />
          <ProgressBarSegment {...getPropsForSegment('SuicidalModeExplanation')} />
          <ProgressBarSegment {...getPropsForSegment('SuicidalModeForm')} />
        </View>
      </View>
      <View spacing={VERTICAL_SPACING}>
        <Title text={t('mystory_myplan_header.myplan')} status={getStatusForRoute('MyPlanForm')} />
        <View row spacing={HORIZONTAL_SPACING}>
          <ProgressOrb
            isComplete={!!(data && data['MyPlanForm'] && data['MyPlanExplanation'])}
            isActive={getStatusForRoute('MyPlanForm', 'MyPlanExplanation') === 'active'}
          />
          <ProgressBarSegment {...getPropsForSegment('MyPlanExplanation')} />
          <ProgressBarSegment {...getPropsForSegment('MyPlanForm')} />
        </View>
      </View>
    </View>
  );
}
