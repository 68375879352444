import { Platform } from 'react-native';
import MMKVStorage from 'react-native-mmkv-storage';

const mmkv = new MMKVStorage.Loader().withEncryption().initialize();

type Scope = 'apollo' | 'bot';

const mmkvInstances: Record<string, typeof mmkv | undefined> = {};

export const getMmkv = (scope: Scope) => {
  if (mmkvInstances[scope]) return mmkvInstances[scope]!;
  const newInstance = new MMKVStorage.Loader().withInstanceID(scope).withEncryption().initialize();
  mmkvInstances[scope] = newInstance;
  return newInstance;
};

export const clearAll = async () => {
  if (Platform.OS === 'web') return;
  const instanceIds = await mmkv.getAllMMKVInstanceIDs();
  return Promise.all(
    instanceIds.map((instanceId) => {
      return getMmkv(instanceId as Scope).clearStore();
    }),
  );
};
