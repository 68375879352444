const queryString = require('query-string'); // eslint-disable-line
import { URI_PREFIX } from '@src/constants';

export const urlToPathAndParams = (url: string, uriPrefix: RegExp | string) => {
  const searchMatch = url.match(/^(.*)\?(.*)$/);
  const [, urlWithoutQuery, query] = searchMatch || [null, url, {}];
  const params = queryString.parse(query);
  // @ts-expect-error
  let path = urlWithoutQuery?.split(uriPrefix)[1];
  if (path === undefined) {
    path = urlWithoutQuery;
  }
  if (path === '/') {
    path = '';
  }
  if (path[path.length - 1] === '/') {
    path = path.slice(0, -1);
  }
  return {
    path,
    params,
  };
};

export function parseUrl(url: string | null | undefined, uriPrefix: RegExp | string = URI_PREFIX) {
  if (!url) return null;
  const parsedUrl = urlToPathAndParams(url, uriPrefix);
  const isAuthDeeplink = !!(parsedUrl && parsedUrl.path && parsedUrl.path.startsWith('auth/'));
  return {
    ...parsedUrl,
    pathWithQuery: url.split(uriPrefix)[1] ?? '',
    isAuthDeeplink,
    isDeeplink: !!(
      url &&
      parsedUrl &&
      url !== parsedUrl.path &&
      parsedUrl.path !== '' &&
      url.slice(0, -1) !== parsedUrl.path
    ),
  };
}
