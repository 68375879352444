// https://cloud.google.com/storage/docs/performing-resumable-uploads

// Create chunks in multiples of 256 KB (256 x 1024 bytes) in size
const CHUNK_SIZE = 256 * 1024 * 4; // 1MB
const BUCKET_NAME = 'bug-reports.oui.dev';

export type SessionUri = string & { _sessionUri: true };

export async function initializeResumableUpload(blob: Blob, fileName: string): Promise<SessionUri> {
  const mimeType = blob.type;
  const size = blob.size.toString();
  const body = JSON.stringify({
    name: `resumable/${fileName}`,
  });
  const result = await fetch(
    `https://storage.googleapis.com/upload/storage/v1/b/${BUCKET_NAME}/o?uploadType=resumable`,
    {
      method: 'POST',
      body,
      headers: {
        'Content-Length': body.length.toString(),
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Upload-Content-Type': mimeType,
        'X-Upload-Content-Length': size,
      },
    },
  );

  if (result.status >= 400) {
    const resultBody = await result.text();
    const error = new Error(`initializeResumableUpload failed: (${result.status}) ${resultBody}`);
    (error as any).status = result.status;
    throw error;
  }

  return result.headers.get('location') as SessionUri;
}

function getLastByteFromRange(range: string | null) {
  if (range) {
    return Number.parseInt(range.split('-')[1], 10);
  }
  return -1;
}

export async function uploadResumableChunk(
  sessionUri: SessionUri,
  blob: Blob,
  start: number,
): Promise<{ isComplete: boolean; lastByte: number }> {
  const end = Math.min(start + CHUNK_SIZE, blob.size);
  const size = end - start;
  const headers = {
    'Content-Length': size.toString(),
    'Content-Range': `bytes ${start}-${end - 1}/${blob.size}`,
  };
  const result = await fetch(sessionUri, {
    method: 'PUT',
    headers,
    body: blob.slice(start, end),
  });

  if (result.status >= 400) {
    const body = await result.text();
    const error = new Error(`uploadResumableChunk failed: (${result.status}) ${body}`);
    (error as any).status = result.status;
    throw error;
  }

  return {
    isComplete: [200, 201].includes(result.status),
    lastByte: getLastByteFromRange(result.headers.get('range')),
  };
}

export async function checkResumableUpload(
  sessionUri: SessionUri,
): Promise<{ isComplete: boolean; lastByte: number }> {
  const result = await fetch(sessionUri, {
    method: 'PUT',
    headers: {
      'Content-Length': '0',
      'Content-Range': 'bytes */*',
    },
  });
  return {
    isComplete: [200, 201].includes(result.status),
    lastByte: getLastByteFromRange(result.headers.get('range')),
  };
}
