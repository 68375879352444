import { useMutation } from '@apollo/client';
import { useRoute } from '@react-navigation/native';

import { APP_SLUG } from '@src/constants';
import { JcoMyStoryMyPlanStackParamList, JcoMyStoryMyPlanScreenProps } from '@src/types';
import {
  SaveUserResponse,
  SaveUserResponseVariables,
  SaveUserResponseMutation,
} from '@src/lib/userResponse';

export function useSetMyStoryMyPlanStepComplete(step: string) {
  const [saveUserResponse] = useMutation<SaveUserResponse, SaveUserResponseVariables>(
    SaveUserResponseMutation,
  );
  const { params } = useRoute<
    JcoMyStoryMyPlanScreenProps<keyof JcoMyStoryMyPlanStackParamList>['route']
  >();
  const patientID = params?.patientID!;

  return () => {
    if (APP_SLUG === 'oui-aviva') return;
    saveUserResponse({
      refetchQueries: APP_SLUG === 'oui-jco' ? [] : ['CliniciansHeaderStatus'],
      variables: {
        patientID,
        context: 'MyStoryMyPlan',
        data: { complete: true },
        key: step,
      },
    });
  };
}
