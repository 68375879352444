import React from 'react';
import { SvgProps } from 'react-native-svg';

import MyStoryMyPlanContainer from '@src/components/MyStoryMyPlanContainer';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { Icon } from '@src/components/Icon';
import { View } from '@src/components/View';
import { Color } from '@src/styles';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';
import { useT } from '@src/lib/i18n';
import { APP_SLUG } from '@src/constants';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';

import OverviewMyPlan from '@src/assets/OverviewMyPlanSmall.svg';
import OverviewMyStory from '@src/assets/OverviewMyStorySmall.svg';
import OverviewSuicidalMode from '@src/assets/OverviewSuicidalModeSmall.svg';

function Card(props: {
  asset: React.StatelessComponent<SvgProps & { accessibilityLabel: string | undefined }>;
  complete: boolean;
  index: number;
  subtitle: string;
  title: string;
}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const Asset = props.asset;

  return (
    <View row={isMobile}>
      <View
        style={{
          zIndex: 1,
          transform: [isMobile ? { translateX: 0 } : { translateY: 20 }],
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          borderRadius: 20,
          backgroundColor: props.complete ? Color.styleGuide.BrandJetStreamGreen : Color.tertiary,
        }}
      >
        {props.complete ? (
          <Icon name="check" size={24} color={Color.success} />
        ) : (
          <Text text={`${props.index}`} color="white" weight="bold" />
        )}
      </View>
      {isMobile ? (
        <View
          style={{
            overflow: 'hidden',
            marginLeft: -20,
            borderRadius: 20,
            alignSelf: 'stretch',
            flex: 1,
            backgroundColor: props.complete ? '#F4F8F5' : Color.grayBackground,
            padding: 26,
            paddingHorizontal: 30,
          }}
        >
          <View
            style={{
              alignItems: 'flex-end',
              bottom: -5,
              justifyContent: 'flex-end',
              position: 'absolute',
              right: -20,
            }}
          >
            <Asset accessibilityLabel={undefined} />
          </View>
          <View>
            <Text text={props.title} weight="bold" size={17} />
            <Text text={props.subtitle} size={17} style={{ maxWidth: width - 200 }} />
          </View>
        </View>
      ) : (
        <View
          style={{
            borderRadius: 20,
            width: 205,
            height: 336,
            backgroundColor: props.complete ? '#F4F8F5' : Color.grayBackground,
          }}
        >
          <View spacing={24} style={{ marginVertical: 48, alignSelf: 'center', padding: 20 }}>
            <Text text={props.title} weight="bold" size={21} style={{ textAlign: 'center' }} />
            <Text text={props.subtitle} size={21} style={{ textAlign: 'center' }} />
          </View>
          <View style={{ flex: 1 }} />
          <View style={{ alignItems: 'flex-end' }}>
            <Asset width="100%" accessibilityLabel={undefined} />
          </View>
        </View>
      )}
    </View>
  );
}

export default function MyStoryMyPlanOverview(
  props: JcoMyStoryMyPlanScreenProps<'MyStoryMyPlanOverview' | 'PatientOnboardingEnd'>,
) {
  const t = useT();
  const complete = useSetMyStoryMyPlanStepComplete('MyStoryMyPlanOverviewComplete');
  const myStoryMyPlanComplete = props.route.name === 'PatientOnboardingEnd';
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  return (
    <MyStoryMyPlanContainer
      progress={1}
      heading={
        myStoryMyPlanComplete
          ? t('mystory_myplan_overview.heading_complete')
          : t('mystory_myplan_overview.heading')
      }
      testID="MyStoryMyPlanOverview"
    >
      <View row={!isMobile} spacing={16}>
        <Card
          complete={myStoryMyPlanComplete}
          title={t('mystory_myplan_overview.mystory_title')}
          subtitle={
            myStoryMyPlanComplete
              ? t('mystory_myplan_overview.mystory_description_complete')
              : t('mystory_myplan_overview.mystory_description')
          }
          index={1}
          asset={OverviewMyStory}
        />
        <Card
          complete={myStoryMyPlanComplete}
          title={t('mystory_myplan_overview.suicidal_mode_title')}
          subtitle={
            myStoryMyPlanComplete
              ? t('mystory_myplan_overview.suicidal_mode_description_complete')
              : t('mystory_myplan_overview.suicidal_mode_description')
          }
          index={2}
          asset={OverviewSuicidalMode}
        />
        <Card
          complete={myStoryMyPlanComplete}
          title={t('mystory_myplan_overview.myplan_title')}
          subtitle={
            myStoryMyPlanComplete
              ? t('mystory_myplan_overview.myplan_description_complete')
              : t('mystory_myplan_overview.myplan_description')
          }
          index={3}
          asset={OverviewMyPlan}
        />
      </View>
      {myStoryMyPlanComplete ? (
        APP_SLUG === 'oui-jco' ? (
          <Button
            alignSelf="center"
            text={t('mystory_myplan_overview.get_documents_button')}
            onPress={() => {
              props.navigation.navigate('SafetyScreeningResults', {
                patientID: props.route.params.patientID,
              });
            }}
            style={{ marginTop: 64 }}
            testID="MyStoryMyPlanOverview_exit"
            variant="solid"
          />
        ) : (
          <>
            <View style={{ flex: 1 }} />
            <Button
              alignSelf="center"
              text={t('mystory_myplan_overview.finish_button')}
              icon="exit-to-app"
              onPress={() => {
                if (APP_SLUG === 'oui-aviva') {
                  props.navigation.navigate('home' as any);
                  return;
                }
                props.navigation.navigate('ClinicianLock', {
                  patientID: props.route.params.patientID,
                });
              }}
              testID="MyStoryMyPlanOverview_exit"
              variant="text"
            />
          </>
        )
      ) : (
        <Button
          alignSelf="center"
          text={t('mystory_myplan_overview.continue_button')}
          iconRight="arrow-right"
          style={{ marginTop: 64 }}
          testID="MyStoryMyPlanOverview_continue"
          onPress={() => {
            complete();
            props.navigation.navigate('PatientNarrativeExplanation', props.route.params);
          }}
        />
      )}
    </MyStoryMyPlanContainer>
  );
}
