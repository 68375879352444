import { StyleSheet } from 'react-native';
import { useColorScheme } from 'react-native-appearance';
import ColorObj from 'color';

import { useAppContext } from '@src/hooks/useAppContext';

const StyleGuideColors = {
  LogoCyan: '#008689',
  LogoLilac: '#8b688d',

  BrandCarminePink: '#ae595b',
  BrandPastelPink: '#ebaca2',
  BrandJetStreamGreen: '#bed3c3',
  BrandSpaceBlue: '#202e53',

  Gray1: '#242226',
  Gray2: '#322c3a',
  Gray3: '#454154',
  Gray4: '#6a6a7c',
  Gray5: '#9191a3',
  Gray6: '#c5c5d3',
  Gray7: '#efeff4',
  Gray8: '#f7f7f9',

  Danger: '#cb3d3d',
  Success: '#128918',
  Warning: '#efc745',
};

export const Color = {
  accent: StyleGuideColors.BrandCarminePink,
  heading: '#262e44',
  primary: '#4a919e', // teal
  secondary: StyleGuideColors.BrandJetStreamGreen, // light green
  tertiary: StyleGuideColors.BrandSpaceBlue,
  text: StyleGuideColors.Gray1,
  error: StyleGuideColors.Danger,
  success: StyleGuideColors.Success,
  warning: StyleGuideColors.Warning,
  grayBackground: StyleGuideColors.Gray8,
  backgroundColor: 'white',
  surfaceColor: 'white',

  styleGuide: StyleGuideColors,
};

export const Shadow = StyleSheet.create({
  none: {
    elevation: 0,
    shadowColor: 'transparent',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0,
    shadowRadius: 5,
  },
  default: {
    elevation: 2,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  low: {
    elevation: 2,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
  medium: {
    elevation: 4,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
  },
  high: {
    elevation: 6,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 16,
  },
  overlay: {
    elevation: 16,
    shadowColor: StyleGuideColors.Gray1,
    shadowOffset: { width: 0, height: 20 },
    shadowOpacity: 0.3,
    shadowRadius: 50,
  },
});

export const card = [
  {
    backgroundColor: 'white',
    borderRadius: 16,
  },
  Shadow.high,
];

export const DarkColor: typeof Color = {
  ...Color,
  accent: new ColorObj(Color.accent).saturate(0.2).hex(),
  backgroundColor: '#000000',
  surfaceColor: '#1a1a1a',
  text: 'white',
  tertiary: 'white',
};

export function useColor() {
  const { flags } = useAppContext();
  const _scheme = useColorScheme();
  const scheme = flags.allowDarkTheme ? _scheme : 'light';
  const color = scheme === 'dark' ? DarkColor : Color;
  return { scheme, Color: color, Shadow };
}
