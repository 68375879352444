import React, { useState } from 'react';
import { Linking } from 'react-native';
import * as Permissions from 'expo-permissions';
import AsyncStorage from '@react-native-community/async-storage';

export { Permissions };

import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { useAppState } from '@src/hooks/useAppState';
import { addBreadcrumb } from '@src/lib/log';

function getPermissionLabel(permission: Permissions.PermissionType): string {
  switch (permission) {
    case Permissions.CAMERA:
      return 'Camera';
    case Permissions.CAMERA_ROLL:
    case Permissions.MEDIA_LIBRARY:
    case Permissions.MEDIA_LIBRARY_WRITE_ONLY:
    case 'cameraRoll': // For some reason CAMERA_ROLL is not this enum value but it's in PermissionType
      return 'Media Gallery';
    case Permissions.AUDIO_RECORDING:
      return 'Audio Recording';
    case Permissions.LOCATION:
      return 'Location';
    case Permissions.USER_FACING_NOTIFICATIONS:
      return 'Notifications';
    case Permissions.NOTIFICATIONS:
      return 'Notifications';
    case Permissions.CONTACTS:
      return 'Contacts';
    case Permissions.CALENDAR:
      return 'Calendar';
    case Permissions.REMINDERS:
      return 'Reminders';
    case Permissions.SYSTEM_BRIGHTNESS:
      return 'Brightness';
    case Permissions.MOTION:
      return 'Motion';
  }
}

type SettingsCallbacks = {
  onOpenSettings?: () => Promise<unknown>;
  onCloseSettings?: () => Promise<unknown>;
};

function PermissionDeniedRecoveryModal({
  permission,
  response,
  onFinalResponse,
  onOpenSettings,
  onCloseSettings,
}: DeniedContextType & SettingsCallbacks) {
  const canAskAgain = response.canAskAgain;

  useAppState(async (appState) => {
    if (appState === 'active') {
      await onCloseSettings?.();
      const result = await Permissions.getAsync(permission);
      onFinalResponse(result);
    }
  });

  return (
    <ConfirmationModal
      visible={true}
      title={`${getPermissionLabel(permission)} permission needed`}
      confirmText={canAskAgain ? 'Ask me again' : 'Go to settings'}
      confirmTestID="PermissionDeniedRecoveryModal_goToSettingsButton"
      cancelText="Deny"
      cancelTestID="PermissionDeniedRecoveryModal_denyButton"
      description={
        canAskAgain
          ? "You've previously denied this permission. To use this feature, please allow Aviva access."
          : `It looks like you've previously denied this permission.\n\nIn order to use this feature, please enable the permission on the settings screens and return to Aviva by clicking "back" at the top of the screen.`
      }
      onCancel={() => onFinalResponse(response)}
      onConfirm={async () => {
        if (canAskAgain) {
          const newResult = await askAsync(permission, { retryIfDenied: false });
          onFinalResponse(newResult);
        } else {
          await onOpenSettings?.();
          addBreadcrumb({
            category: 'permissions',
            message: 'open-native-settings',
            data: { permission },
          });
          await Linking.openSettings();
        }
      }}
    />
  );
}

type AcceptedContextType = {
  permission: undefined;
  response?: never;
  onFinalResponse?: never;
};
type DeniedContextType = {
  permission: Permissions.PermissionType;
  response: Permissions.PermissionResponse;
  onFinalResponse: (result: Permissions.PermissionResponse) => void;
};
type ContextType = AcceptedContextType | DeniedContextType;
const IntitialContext = { permission: undefined };

let setContext: (obj: ContextType) => void;

export const askAsync = async (
  permission: Permissions.PermissionType,
  { retryIfDenied = false }: { retryIfDenied?: boolean },
): Promise<Permissions.PermissionResponse> => {
  global.pauseAppState = true;
  // since AppState is paused, createSessionTimeoutNavigator doesn't get notified of the inactive
  // state when opening up the system modal and therefore doesn't update lastSeen, when coming back
  // due to the async nature of restoring appState, we may restore pauseAppState before checking
  // lastSeen for purposes of triggering reauthentication
  // https://github.com/ouihealth/oui/issues/917
  await AsyncStorage.setItem('lastSeen', Date.now().toString());
  const result = await Permissions.askAsync(permission);
  global.pauseAppState = false;

  addBreadcrumb({ category: 'permissions', message: 'ask', data: { permission, result } });

  if (result.status === Permissions.PermissionStatus.DENIED && retryIfDenied) {
    return new Promise((resolve) => {
      setContext({
        permission,
        response: result,
        onFinalResponse: (final) => {
          addBreadcrumb({
            category: 'permissions',
            message: 'final-response',
            data: { permission, result: final },
          });
          setContext({ permission: undefined });
          resolve(final);
        },
      });
    });
  }
  return result;
};

export function PermissionsManagerProvider({
  children,
  onOpenSettings,
  onCloseSettings,
}: {
  children: React.ReactNode;
} & SettingsCallbacks) {
  let context;
  [context, setContext] = useState<ContextType>(IntitialContext);

  return (
    <>
      {children}
      {context.permission ? (
        <PermissionDeniedRecoveryModal
          {...(context as DeniedContextType)}
          onOpenSettings={onOpenSettings}
          onCloseSettings={onCloseSettings}
        />
      ) : null}
    </>
  );
}
