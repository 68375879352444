import React from 'react';
import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import { StyleProp, ViewStyle, Platform, processColor, TouchableOpacity, View } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { useColor } from '@src/styles';

import add from '@src/assets/icons/add.svg';
import arrowConnect from '@src/assets/icons/cms/arrow-connect.svg';
import arrowLeft from '@src/assets/icons/arrow-left.svg';
import arrowRight from '@src/assets/icons/arrow-right.svg';
import button from '@src/assets/icons/cms/button.svg';
import calendar from '@src/assets/icons/calendar.svg';
import camera from '@src/assets/icons/camera.svg';
import caps from '@src/assets/jcoicon.svg';
import cardStack from '@src/assets/icons/card-stack.svg';
import chart from '@src/assets/icons/chart.svg';
import check from '@src/assets/icons/cms/check.svg';
import checkmark from '@src/assets/icons/checkmark.svg';
import chevronDown from '@src/assets/icons/chevron-down.svg';
import chevronLeft from '@src/assets/icons/chevron-left.svg';
import chevronRight from '@src/assets/icons/chevron-right.svg';
import chevronUp from '@src/assets/icons/chevron-up.svg';
import choice from '@src/assets/icons/cms/choice.svg';
import clipboard from '@src/assets/icons/clipboard.svg';
import clock from '@src/assets/icons/clock.svg';
import close from '@src/assets/icons/close.svg';
import closedCaptionOff from '@src/assets/icons/closed-caption-off.svg';
import cloudUpload from '@src/assets/icons/cms/cloud-upload.svg';
import crisis from '@src/assets/icons/crisis.svg';
import delete_ from '@src/assets/icons/delete.svg';
import doubleChevronDown from '@src/assets/icons/double-chevron-down.svg';
import dragHandle from '@src/assets/icons/draghandle.svg';
import dumbell from '@src/assets/icons/dumbell.svg';
import email from '@src/assets/icons/email.svg';
import exchange from '@src/assets/icons/cms/exchange.svg';
import home from '@src/assets/icons/home.svg';
import hopeKit from '@src/assets/icons/hope-kit.svg';
import image from '@src/assets/icons/cms/image.svg';
import input from '@src/assets/icons/cms/input.svg';
import location from '@src/assets/icons/location.svg';
import lock from '@src/assets/icons/lock.svg';
import maximize from '@src/assets/icons/maximize.svg';
import medical from '@src/assets/icons/medical.svg';
import mic from '@src/assets/icons/cms/mic.svg';
import minimize from '@src/assets/icons/minimize.svg';
import moon from '@src/assets/icons/moon.svg';
import pause from '@src/assets/icons/pause.svg';
import pencil from '@src/assets/icons/pencil.svg';
import people from '@src/assets/icons/people.svg';
import person from '@src/assets/icons/person.svg';
import personCircle from '@src/assets/icons/person-circle.svg';
import phone from '@src/assets/icons/phone.svg';
import pin from '@src/assets/icons/pin.svg';
import play from '@src/assets/icons/play.svg';
import png from '@src/assets/icons/cms/PNG.svg';
import quizCard from '@src/assets/icons/cms/quiz-card.svg';
import quotes from '@src/assets/icons/quotes.svg';
import relax from '@src/assets/icons/relax.svg';
import repeat from '@src/assets/icons/repeat.svg';
import riskCurveFalling from '@src/assets/icons/risk-curve-falling.svg';
import riskCurvePeak from '@src/assets/icons/risk-curve-peak.svg';
import riskCurveRising from '@src/assets/icons/risk-curve-rising.svg';
import say from '@src/assets/icons/say.svg';
import popOutMenuIndicator from '@src/assets/icons/cms/pop-out-menu-indicator.svg';
import section from '@src/assets/icons/cms/section.svg';
import send from '@src/assets/icons/send.svg';
import sendSolid from '@src/assets/icons/send-solid.svg';
import specializedExchange from '@src/assets/icons/cms/specialized-exchange.svg';
import settings from '@src/assets/icons/settings.svg';
import slider from '@src/assets/icons/cms/slider.svg';
import spotIt from '@src/assets/thoughtDiary/spotIt.svg';
import sun from '@src/assets/icons/sun.svg';
import sunrise from '@src/assets/icons/sunrise.svg';
import sunset from '@src/assets/icons/sunset.svg';
import switchIt from '@src/assets/thoughtDiary/switchIt.svg';
import testIt from '@src/assets/thoughtDiary/testIt.svg';
import text from '@src/assets/icons/cms/text.svg';
import textCard from '@src/assets/icons/cms/text-card.svg';
import textPage from '@src/assets/icons/cms/text-page.svg';
import transcript from '@src/assets/icons/transcript.svg';
import troubleshoot from '@src/assets/icons/troubleshoot.svg';
import upload from '@src/assets/icons/cms/upload.svg';
import video from '@src/assets/icons/cms/video.svg';
import videoCard from '@src/assets/icons/cms/video-card.svg';
import videoFile from '@src/assets/icons/cms/video-file.svg';
import volumeOff from '@src/assets/icons/volume-off.svg';
import volumeOn from '@src/assets/icons/volume-on.svg';
import warningSign from '@src/assets/icons/warning-sign.svg';
import widget from '@src/assets/icons/cms/widget.svg';

const CUSTOM_ICONS = {
  add,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'cms-arrow-connect': arrowConnect,
  'cms-button': button,
  calendar,
  camera,
  caps,
  'card-stack': cardStack,
  chart,
  'cms-check': check,
  checkmark,
  'chevron-down': chevronDown,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'chevron-up': chevronUp,
  'cms-choice': choice,
  clipboard,
  clock,
  close,
  'closed-caption-off': closedCaptionOff,
  'cms-cloud-upload': cloudUpload,
  crisis,
  delete: delete_,
  'double-chevron-down': doubleChevronDown,
  'drag-handle': dragHandle,
  dumbell,
  edit: pencil,
  email,
  'cms-exchange': exchange,
  home,
  'hope-kit': hopeKit,
  'cms-image': image,
  'cms-input': input,
  location,
  lock,
  medical,
  maximize,
  'cms-mic': mic,
  minimize,
  moon,
  pause,
  pencil,
  people,
  'person-circle': personCircle,
  person,
  phone,
  pin,
  play,
  'cms-png': png,
  'cms-pop-out-menu-indicator': popOutMenuIndicator,
  'cms-quiz-card': quizCard,
  quotes,
  relax,
  repeat,
  'risk-curve-falling': riskCurveFalling,
  'risk-curve-peak': riskCurvePeak,
  'risk-curve-rising': riskCurveRising,
  say,
  'cms-section': section,
  send,
  settings,
  'cms-slider': slider,
  'send-solid': sendSolid,
  'cms-specialized-exchange': specializedExchange,
  'spot-it': spotIt,
  sun,
  sunrise,
  sunset,
  'switch-it': switchIt,
  'test-it': testIt,
  'cms-text': text,
  'cms-text-page': textPage,
  'cms-text-card': textCard,
  transcript,
  troubleshoot,
  'cms-upload': upload,
  'cms-video': video,
  'cms-video-card': videoCard,
  'cms-video-file': videoFile,
  'volume-off': volumeOff,
  'volume-on': volumeOn,
  'warning-sign': warningSign,
  'cms-widget': widget,
};

export function Icon({
  _DANGER_NO_WRAPPER,
  accessibilityLabel,
  color,
  dataSet,
  disabled,
  name = 'check',
  onPress,
  size = 20,
  style,
  testID,
}: {
  _DANGER_NO_WRAPPER?: number;
  color?: string;
  dataSet?: object;
  disabled?: boolean;
  name: React.ComponentProps<typeof MaterialIcons>['name'] | keyof typeof CUSTOM_ICONS;
  size?: number;
  style?: StyleProp<ViewStyle>;
  testID?: string;
} & (
  | {
      accessibilityLabel: string | undefined;
      onPress: () => void;
    }
  | {
      accessibilityLabel?: string | undefined;
      onPress?: never;
    }
)) {
  const { Color } = useColor();
  color = color ?? Color.text;
  const CustomIcon = CUSTOM_ICONS[name as keyof typeof CUSTOM_ICONS] as React.FunctionComponent<
    SvgProps & { accessibilityLabel: string | undefined }
  > | null;
  if (CustomIcon) {
    const processedColor = Platform.select({
      ios: (processColor(color) as any) as string,
      android: color,
      web: color,
    });

    // if onPress is defined we set accessibilityLabel in the TouchableOpacity below
    const a11yProps =
      Platform.OS === 'web'
        ? {
            [('aria-label' as any) as 'accessibilityLabel']: onPress
              ? undefined
              : accessibilityLabel,
          }
        : { accessibilityLabel: onPress ? undefined : accessibilityLabel };

    const inner = (
      <CustomIcon
        {...a11yProps}
        color={processedColor}
        width={_DANGER_NO_WRAPPER ?? '100%'}
        height={_DANGER_NO_WRAPPER ?? '100%'}
        preserveAspectRatio="xMidYMid meet"
      />
    );
    if (_DANGER_NO_WRAPPER) return inner;
    return (
      <View
        testID={testID}
        style={[style, size ? { width: size, height: size } : null]}
        // @ts-expect-error
        dataSet={dataSet}
      >
        {onPress ? (
          <TouchableOpacity
            disabled={disabled}
            onPress={onPress}
            accessible
            accessibilityRole="button"
            accessibilityLabel={accessibilityLabel}
            accessibilityState={{
              disabled: disabled,
            }}
            hitSlop={{
              top: 10,
              right: 10,
              bottom: 10,
              left: 10,
            }}
            style={[{ height: '100%', width: '100%' }, disabled ? { opacity: 0.5 } : null]}
          >
            {inner}
          </TouchableOpacity>
        ) : (
          inner
        )}
      </View>
    );
  }

  return (
    <MaterialIcons
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={onPress ? 'button' : 'none'}
      color={color}
      name={name as any}
      size={size}
      style={[style, disabled ? { opacity: 0.5 } : null]}
      testID={testID}
      onPress={onPress}
    />
  );
}
