import React, { useCallback, useRef } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useColor } from '@src/styles';
import { useAccessibilityContext } from '@src/components/AccessibilityContext';

type AccessibleInputChildProps<T extends React.Component> = {
  accessibilityLabel: string;
  ref: React.RefCallback<T>;
  placeholder?: string;
};

export function useAccessibleInput<T extends React.Component>({
  error,
  label,
  placeholder,
  ref,
}: {
  error?: string;
  label?: string;
  placeholder?: string;
  ref?: React.Ref<T | null | undefined>;
}) {
  const innerRef = useRef<T | null | undefined>();
  const { isScreenReaderEnabled } = useAccessibilityContext();

  const refCallback = useCallback(
    (r: T | null) => {
      innerRef.current = r;
      if (ref) {
        if (typeof ref === 'function') {
          ref(r);
        } else if (ref) {
          (ref as any).current = r;
        }
      }
    },
    [ref],
  );

  return {
    accessibilityLabel: label?.includes('*')
      ? `${label.replace(/\*/g, '')}. required. ${error ? error : ''}`
      : `${label || placeholder}. ${error ? `${error}.` : ''}`,
    ref: refCallback,
    // https://github.com/facebook/react-native/issues/26739
    placeholder: isScreenReaderEnabled && label && placeholder !== label ? undefined : placeholder,
  };
}

type AccessibleInputProps<T extends React.Component> = {
  children: (accessibilityProps: AccessibleInputChildProps<T>) => React.ReactNode;
  error?: string;
  forwardRef?: React.Ref<T | null | undefined>;
  hint?: string;
  placeholder?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  labelColor?: string;
  labelWeight?: React.ComponentProps<typeof Text>['weight'];
} & (
  | {
      label?: string;
      accessibilityLabel?: string;
    }
  | {
      label?: () => React.ReactNode;
      accessibilityLabel: string;
    }
);

export function AccessibleInput<T extends React.Component>({
  accessibilityLabel,
  children,
  error,
  forwardRef,
  hint,
  label,
  labelColor,
  labelWeight,
  placeholder,
  style,
  testID,
}: AccessibleInputProps<T>) {
  const { Color } = useColor();

  const accessible = useAccessibleInput({
    error,
    label: accessibilityLabel ?? (typeof label === 'string' ? label : ''),
    placeholder,
    ref: forwardRef,
  });

  return (
    <View style={style}>
      {label && typeof label === 'string' ? (
        <Text
          accessibilityRole="none"
          text=""
          style={{ marginLeft: 16, marginBottom: 5 }}
          weight={labelWeight ?? 'bold'}
          color={labelColor}
        >
          {label.replace(/\*$/, '')}
          {label.endsWith('*') ? <Text text=" *" color={Color.error} weight="semibold" /> : null}
        </Text>
      ) : typeof label === 'function' ? (
        <View
          style={{ marginLeft: 16 }}
          importantForAccessibility="no-hide-descendants"
          accessibilityElementsHidden
        >
          {label()}
        </View>
      ) : null}
      {children?.(accessible)}
      {error ? (
        <Text
          accessibilityRole="none"
          text={error}
          style={{ marginLeft: 16, marginTop: 5 }}
          color={Color.error}
          testID={`${testID}_error`}
        />
      ) : hint ? (
        <Text
          accessibilityRole="none"
          text={hint}
          color={Color.styleGuide.Gray4}
          size={13}
          style={{ marginLeft: 16, marginTop: 5 }}
          testID={`${testID}_hint`}
        />
      ) : null}
    </View>
  );
}
