// https://github.com/necolas/react-native-web/issues/427#issuecomment-352474031
import React from 'react';
import { Platform, StyleProp, ViewStyle, createElement, StyleSheet, View } from 'react-native';

export class AspectRatio extends React.Component<{
  onLayout?: () => void;
  aspectRatio: number;
  style?: StyleProp<ViewStyle>;
}> {
  render() {
    const { children, onLayout, aspectRatio, style } = this.props;
    const percentage = 100 / aspectRatio;

    return Platform.OS === 'web' ? (
      <View
        onLayout={onLayout}
        style={[
          {
            display: 'block' as any,
            overflow: 'hidden',
          },
          style,
        ]}
      >
        {createElement('div', {
          style: [
            {
              display: 'block' as any,
              width: '100%',
            },
            { paddingBottom: `${percentage}%` },
          ],
        })}
        {createElement('div', {
          children,
          style: styles.content,
        })}
      </View>
    ) : (
      <View style={[{ aspectRatio: aspectRatio, maxHeight: '100%', maxWidth: '100%' }, style]}>
        {children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
});
