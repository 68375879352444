import React from 'react';
import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import noop from 'lodash/noop';

import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { Color } from '@src/styles';

export const SuicidalModeCard = ({
  buttonText,
  isActive,
  disabled,
  children,
  onPress,
  title,
  testID,
}: {
  buttonText?: string;
  disabled?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
  title: string;
  onPress?: () => void;
  testID?: string;
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={!onPress}
      style={[
        {
          elevation: 10,
          backgroundColor: 'white',
          padding: 16,
          flex: 1,
          borderRadius: 8,
          minHeight: 100,
        },
        disabled ? { opacity: 0.3 } : null,
      ]}
      testID={testID}
    >
      <Text text={title} style={{ textAlign: 'center', marginBottom: 4 }} weight="bold" />
      <View style={{ flex: 1, justifyContent: 'center' }}>
        {isActive ? (
          <Button
            testID="SuicidalModeCard_continue"
            text={buttonText || 'Continue'}
            onPress={onPress || noop}
            alignSelf="center"
            variant="solid"
            style={{ marginTop: 12 }}
          />
        ) : typeof children === 'string' ? (
          <Text text={children} color={Color.styleGuide.Gray4} style={{ textAlign: 'center' }} />
        ) : (
          children
        )}
      </View>
    </TouchableOpacity>
  );
};

export const MyPlanCard = ({
  onPress,
  children,
  disabled,
  text,
  style,
  testID,
}: {
  children?: React.ReactNode;
  disabled?: boolean;
  text?: string;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}) => {
  return (
    <TouchableOpacity
      style={[
        {
          elevation: 10,
          backgroundColor: 'white',
          margin: 4,
          padding: 16,
          flexGrow: 1,
          flexBasis: '25%',
          minHeight: 150,
          borderRadius: 8,
        },
        disabled ? { opacity: 0.3 } : null,
        style,
      ]}
      disabled={!onPress}
      onPress={onPress}
      testID={testID}
    >
      {children}
    </TouchableOpacity>
  );
};
