import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as ScreenOrientation from 'expo-screen-orientation';

const Context = React.createContext<ScreenOrientation.Orientation>(
  ScreenOrientation.Orientation.UNKNOWN,
);

export function OrientationProvider({ children }: { children?: React.ReactNode }) {
  const [currentOrientation, setCurrentOrientation] = useState(
    ScreenOrientation.Orientation.UNKNOWN,
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      ScreenOrientation.getOrientationAsync().then((orientation) =>
        setCurrentOrientation(orientation),
      );
      const subscription = ScreenOrientation.addOrientationChangeListener(
        ({ orientationInfo: { orientation } }) => {
          setCurrentOrientation(orientation);
        },
      );
      return () => ScreenOrientation.removeOrientationChangeListener(subscription);
    }
    return;
  }, []);

  return <Context.Provider value={currentOrientation}>{children}</Context.Provider>;
}

export default Context;
