import React from 'react';
import produce from 'immer';
import groupBy from 'lodash/groupBy';
import { TouchableWithoutFeedback, ScrollView } from 'react-native';

import { useAppContext } from '@src/hooks/useAppContext';
import { Shadow, Color } from '@src/styles';
import Divider from '@src/components/Divider';
import { View } from '@src/components/View';
import { Button } from '@src/components/Button';
import { Text, Subheading } from '@src/components/Text';
import TextInput from '@src/components/TextInput';
import Checkbox from '@src/components/Checkbox';
import { useT } from '@src/lib/i18n';

export type CheckboxFormAnswer = { checked: boolean; text?: string; order: number };
export type CheckboxFormQuestion = { category?: string; label: string; question?: string };
export type CheckboxForm<T = CheckboxFormAnswer | undefined> = { [key: string]: T };

function getTextFromAnswerEntry(
  key: string,
  questions: CheckboxForm<CheckboxFormQuestion>,
  answer: CheckboxFormAnswer,
): string {
  const value = answer;
  if (!value) return '';
  if (!value.checked) return '';
  // Check presense of key for backwards compatibility when changing questions
  if (!questions[key]) return '';
  return value.text
    ? value.text
        .split('\n')
        .map((text) => `${questions[key].label}: ${text}`)
        .join('\n')
    : questions[key].label;
}

export function getTextListFromAnswerEntries({
  questions,
  answers,
}: {
  questions: CheckboxForm<CheckboxFormQuestion>;
  answers?: CheckboxForm;
}) {
  return Object.entries(answers || {})
    .sort(([, a], [, b]) => {
      return a!.order < b!.order ? -1 : 1;
    })
    .map(([key, answer]) => {
      return answer?.checked ? getTextFromAnswerEntry(key, questions, answer!) : null;
    })
    .filter((text) => !!text) as string[];
}

export const CheckboxAnswerList = ({
  questions,
  answers,
  size,
  color = Color.accent,
}: {
  questions: CheckboxForm<CheckboxFormQuestion>;
  answers?: CheckboxForm;
  size?: 'large' | 'normal';
  color?: string;
}) => {
  return (
    <View spacing={4}>
      {getTextListFromAnswerEntries({ questions, answers }).map((text) => (
        <View row key={text} spacing={size === 'large' ? 16 : 8}>
          <View
            style={[
              {
                alignSelf: 'flex-start',
                borderRadius: 4,
                width: 8,
                height: 8,
                backgroundColor: color,
                marginTop: 9,
              },
              size === 'large'
                ? { borderRadius: 6, width: 12, height: 12, marginVertical: 10 }
                : null,
            ]}
          />
          <Text text={text} size={size === 'large' ? 20 : undefined} />
        </View>
      ))}
    </View>
  );
};

function getTextFromAnswer(
  questions: CheckboxForm<CheckboxFormQuestion>,
  answer: CheckboxForm,
): string {
  const parts = Object.entries(answer)
    .map(([key, value]) => {
      if (!value) return null;
      if (!value.checked) return null;
      return value.text || questions[key].label;
    })
    .filter((p) => !!p);

  return parts.join(', ');
}

const CheckboxFormInput = <T extends {}>(props: {
  accentColor?: string;
  formTitle: string;
  onChangeValue: (answer: CheckboxForm) => void;
  placeholder: string;
  questions: CheckboxForm<CheckboxFormQuestion>;
  value: CheckboxForm;
}) => {
  const t = useT();
  const options = Object.entries(props.questions);

  const optionsByCategories = Object.values(groupBy(options, ([, q]) => q.category));
  const hasCategories = optionsByCategories.length > 1;

  const halfLength = options.length / 2;
  let previousCategory = options[0][1].category;
  const splitIndex = hasCategories
    ? // NB sort mutates options but that's OK since we create options above
      options
        .sort(([, a], [, b]) => {
          if (!a.category) return 1;
          if (!b.category) return -1;
          return a.category < b.category ? -1 : 1;
        })
        .findIndex(([, question], i) => {
          const currentCategory = question.category;
          const isSecondHalfWithCategoryBreak =
            i >= halfLength && currentCategory !== previousCategory;
          previousCategory = currentCategory;
          return isSecondHalfWithCategoryBreak;
        })
    : halfLength;

  const firstHalfOptions = options.slice(0, splitIndex);
  const secondHalfOptions = options.slice(splitIndex);
  const { flags } = useAppContext();

  const renderOption = ([key, config]: typeof options[0]) => {
    const onChangeValue = (newValue: string | boolean) => {
      const highestOrder = Math.max(
        ...Object.values(props.value).map((ans) => {
          return typeof ans!.order === 'number' ? ans!.order : -1;
        }),
        0,
      );

      const currentAnswerEntry = props.value[key];

      const newOrder =
        currentAnswerEntry &&
        typeof currentAnswerEntry.order === 'number' &&
        currentAnswerEntry.order !== -1
          ? currentAnswerEntry.order
          : highestOrder + 1;

      const answerEntry =
        newValue === false
          ? { checked: false, text: '', order: -1 }
          : newValue === true
          ? { checked: true, text: '', order: newOrder }
          : { checked: true, text: newValue, order: newOrder };

      props.onChangeValue(
        produce(props.value, (draft) => {
          draft[key] = answerEntry;
        }),
      );
    };

    const freeFormPrompt = config.question;
    const entry = props.value[key] || { checked: false, text: '' };

    const checked = !!(entry && entry.checked);
    return (
      <View key={key}>
        <View row spacing={8}>
          <Checkbox
            value={checked}
            onChangeValue={onChangeValue}
            testID={`CheckboxFormInput_checkbox_${key}`}
          />
          <TouchableWithoutFeedback onPress={() => onChangeValue(!checked)} style={{ flex: 1 }}>
            <View row style={{ flex: 1, flexWrap: 'wrap' }}>
              <Text
                text={config.label}
                style={{
                  textAlign: 'left',
                }}
              />
            </View>
          </TouchableWithoutFeedback>
        </View>
        {freeFormPrompt && entry && entry.checked ? (
          <>
            {(entry.text || '').split('\n').map((section, i, sections) => (
              <View row key={`${i}${sections.length}`}>
                <View flex={1}>
                  <TextInput
                    placeholder={freeFormPrompt as string}
                    onChangeValue={(text) => {
                      onChangeValue(
                        produce(sections, (draft) => {
                          draft[i] = text;
                        }).join('\n'),
                      );
                    }}
                    value={section}
                  />
                </View>
                {i > 0 ? (
                  <Button
                    accessibilityLabel="Delete item"
                    color={Color.styleGuide.Gray5}
                    icon="close"
                    iconSize={20}
                    variant="text"
                    alignSelf="center"
                    onPress={() => {
                      onChangeValue(
                        produce(sections, (draft) => {
                          draft.splice(i, 1);
                        }).join('\n'),
                      );
                    }}
                  />
                ) : null}
              </View>
            ))}
            {key.startsWith('other') ? (
              <Button
                text={t('checkbox_form_input.add_another_button')}
                icon="add"
                variant="text"
                onPress={() => {
                  onChangeValue(`${entry.text || ''}\n`);
                }}
              />
            ) : null}
          </>
        ) : null}
      </View>
    );
  };

  return (
    <View style={[Shadow.medium, { borderRadius: 10, backgroundColor: 'white', flex: 1 }]}>
      <View style={{ minHeight: 200, padding: 20 }}>
        <Text text={props.formTitle} size={40} style={{ marginBottom: 12, textAlign: 'center' }} />
        {props.placeholder && Object.keys(props.value).length === 0 ? (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text
              text={`${props.placeholder}\n\n\n${t('checkbox_form_input.select_all_hint')}`}
              size={17}
              color={Color.styleGuide.Gray4}
              style={{ textAlign: 'center' }}
            />
          </View>
        ) : !flags.showSingleStringCheckFormSummary ? (
          <CheckboxAnswerList
            color={props.accentColor}
            size="large"
            questions={props.questions}
            answers={props.value}
          />
        ) : (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text
              text={getTextFromAnswer(props.questions, props.value)}
              size={30}
              style={{ fontStyle: 'italic' }}
            />
          </View>
        )}
      </View>
      <Divider />
      <View style={{ flex: 2, padding: 20 }}>
        <ScrollView>
          <View row style={{ alignItems: 'flex-start', justifyContent: 'space-between' }}>
            {hasCategories ? (
              <>
                <View spacing={60} style={{ flexBasis: '48%', marginRight: 12 }}>
                  {Object.entries(groupBy(firstHalfOptions, ([, q]) => q.category)).map(
                    ([category, categoryOptions]) => (
                      <View spacing={8} key={category}>
                        <Subheading text={category} />
                        {categoryOptions.map(renderOption)}
                      </View>
                    ),
                  )}
                </View>
                <View spacing={60} style={{ flexBasis: '48%' }}>
                  {Object.entries(groupBy(secondHalfOptions, ([, q]) => q.category)).map(
                    ([category, categoryOptions]) => (
                      <View spacing={8} key={category}>
                        <Subheading text={category} />
                        {categoryOptions.map(renderOption)}
                      </View>
                    ),
                  )}
                </View>
              </>
            ) : (
              <>
                <View spacing={8} flex={1} style={{ marginRight: 12 }}>
                  {firstHalfOptions.map(renderOption)}
                </View>
                <View spacing={8} flex={1}>
                  {secondHalfOptions.map(renderOption)}
                </View>
              </>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default CheckboxFormInput;
