import { useContext, useState, useEffect } from 'react';
import { isValidRoleForApp, getCurrentUser, auth } from '@src/lib/auth';

import { LogoutContext } from '@src/hooks/useLogout';

export function useIsLoggedIn() {
  const { isLoggingOut } = useContext(LogoutContext);
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCurrentUser());
  useEffect(() => {
    const unsub = auth().onAuthStateChanged((u) => {
      if (isValidRoleForApp()) {
        setIsLoggedIn(!!u);
      }
    });
    return () => {
      unsub();
    };
  }, []);
  return isLoggedIn && !isLoggingOut;
}
