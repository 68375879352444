import { useCallback, useRef, useState } from 'react';
import get from 'lodash/get';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import type { MergeWithCustomizer } from 'lodash';
import set from 'lodash/set';
import produce, { applyPatches, Patch } from 'immer';
import equals from 'fast-deep-equal';

import { REGEX } from '@src/constants';

type InputBaseProps<T> = {
  accessibilityLabel?: string;
  label?: string;
  error?: string;
  onChangeValue: (value: T) => void;
  testID: string;
  value: T;
};

type CustomFieldValidator<T> = (value: T) => string | undefined;

const EMAIL_VALIDATOR_CONFIG = {
  type: 'pattern',
  pattern: REGEX.email,
  message: 'Must be a valid email',
};

const PHONE_VALIDATOR_CONFIG = {
  type: 'pattern',
  pattern: REGEX.phone,
  message: 'Must be a valid phone number',
};

export const VALIDATORS = {
  present: (v: unknown) => (!!v ? undefined : 'Must be present'),
  length: (v: unknown, config: any) => {
    if (v) {
      if (
        typeof config.minimum === 'number' &&
        typeof config.maximum === 'number' &&
        config.minimum === config.maximum &&
        (v as any).toString().length !== config.maximum
      ) {
        return `Must be exactly ${config.minimum} characters`;
      }
      if (typeof config.minimum === 'number' && (v as any).toString().length < config.minimum) {
        return `Must be at least ${config.minimum} characters`;
      }
      if (typeof config.maximum === 'number' && (v as any).toString().length > config.maximum) {
        return `Must be at most ${config.maximum} characters`;
      }
    }
    return !!v ? undefined : 'Must be present';
  },
  pattern: (v: unknown, config: any) => {
    if (!v && config.allowBlank) return;
    if (!v) {
      return config.message;
    }
    if (typeof v === 'string') {
      const pattern = config.pattern;
      return v.match(pattern) ? undefined : config.message;
    }
  },
  email: (v: unknown, config?: object) =>
    VALIDATORS.pattern(v, { ...config, ...EMAIL_VALIDATOR_CONFIG }),
  phone: (v: unknown, config?: object) =>
    VALIDATORS.pattern(v, { ...config, ...PHONE_VALIDATOR_CONFIG }),
  number: (v: unknown) => {
    if (!v) {
      return 'Must be present';
    }
    const parsed = Number(v);
    if (!Number.isFinite(parsed)) {
      return 'Must be a number';
    }
    return;
  },
};

type FieldValidator<T> =
  | CustomFieldValidator<T>
  | { type: 'present' }
  | { type: 'email'; allowBlank?: boolean }
  | { type: 'phone'; allowBlank?: boolean }
  | { type: 'number' }
  | { type: 'length'; minimum?: number; maximum?: number }
  | { type: 'pattern'; pattern: string | RegExp; message: string; allowBlank?: true };
type FieldValidatorEntry = {
  label?: string;
  validator: FieldValidator<any> | Array<FieldValidator<any>>;
};

type FieldOptions<T> = {
  defaultValue?: T;
  validator?: FieldValidator<T> | Array<FieldValidator<T>>;
} & (
  | {
      accessibilityLabel?: string;
      label: string | undefined;
    }
  | {
      accessibilityLabel: string;
      label?: string | undefined;
    }
);

type FormOptions<T> = {
  validate?: (data: T, errors: { [key: string]: any }) => object;
  merger?: MergeWithCustomizer;
};

export type Bind<T> =
  | (<K>(pathFn: () => [string[], K], options?: FieldOptions<K>) => InputBaseProps<K>)
  | (<P1 extends keyof NonNullable<T>>(
      path: P1 | [P1],
      options?: FieldOptions<NonNullable<T>[P1]>,
    ) => InputBaseProps<NonNullable<T>[P1]>)
  | (<P1 extends keyof NonNullable<T>, P2 extends keyof NonNullable<NonNullable<T>[P1]>>(
      path: [P1, P2],
      options?: FieldOptions<NonNullable<NonNullable<T>[P1]>[P2]>,
    ) => InputBaseProps<NonNullable<NonNullable<T>[P1]>[P2]>)
  | (<
      P1 extends keyof NonNullable<T>,
      P2 extends keyof NonNullable<NonNullable<T>[P1]>,
      P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>
    >(
      path: [P1, P2, P3],
      options?: FieldOptions<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>,
    ) => InputBaseProps<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>);

export function useForm<T extends object>(
  defaultData: Partial<T> | null,
  formOptions: FormOptions<T> = {},
) {
  const [data, setData] = useState<T>(({} as unknown) as T);
  const undoState = useRef<{
    index: number;
    entries: { timestamp: number; patches: Patch[]; inversePatches: Patch[] }[];
  }>({
    index: -1,
    entries: [],
  });
  const [errors, setErrors] = useState<Record<string, string | undefined>>({});
  const [humanValidationErrors, setHumanValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const validators = useRef<{
    [path: string]: FieldValidatorEntry | undefined;
  }>({});
  const formOptionsRef = useRef(formOptions);

  const merged: T = formOptions.merger
    ? mergeWith({}, defaultData || {}, data, formOptions.merger)
    : merge({}, defaultData || {}, data);

  const variablesRef = useRef<T>(merged);

  if (!equals(merged, variablesRef.current)) {
    variablesRef.current = merged;
  }
  if (!equals(formOptions, formOptionsRef.current)) {
    formOptionsRef.current = formOptions;
  }

  function bind<R>(pathFn: (formData: T) => string[], options: FieldOptions<R>): InputBaseProps<R>;
  function bind<P1 extends keyof NonNullable<T>>(
    path: P1 | [P1],
    options: FieldOptions<NonNullable<T>[P1]>,
  ): InputBaseProps<NonNullable<T>[P1]>;
  function bind<P1 extends keyof NonNullable<T>, P2 extends keyof NonNullable<NonNullable<T>[P1]>>(
    path: [P1, P2],
    options: FieldOptions<NonNullable<NonNullable<T>[P1]>[P2]>,
  ): InputBaseProps<NonNullable<NonNullable<T>[P1]>[P2]>;
  function bind<
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>,
    P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>
  >(
    path: [P1, P2, P3],
    options: FieldOptions<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>,
  ): InputBaseProps<NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3]>;
  function bind<R>(
    nameOrNameFn: string | string[] | ((formData: T) => string[]),
    options: FieldOptions<R>,
  ): InputBaseProps<R> {
    const name = typeof nameOrNameFn === 'function' ? nameOrNameFn(merged) : nameOrNameFn;

    const pathKey = typeof name === 'string' ? name : name.join('.');
    if (options.validator) {
      validators.current[pathKey] = {
        label: options.accessibilityLabel ?? options.label,
        validator: options.validator,
      };
    } else {
      delete validators.current[pathKey];
    }

    const returnValue: InputBaseProps<R> = {
      testID: `INPUT_${pathKey.replace(/\./g, '_')}`,
      error: get(errors, name),
      value: get(merged, name, options.defaultValue),
      onChangeValue: (newVal: unknown) => {
        setData((curr) =>
          produce(
            curr,
            (f) => {
              set(f, name, newVal);
            },
            (patches, inversePatches) => {
              const index = (undoState.current.index += 1);
              undoState.current.entries[index] = {
                timestamp: Date.now(),
                patches,
                inversePatches,
              };
            },
          ),
        );
      },
    };
    if (options.label) {
      returnValue.label = options.label;
    }
    if (options.accessibilityLabel) {
      returnValue.accessibilityLabel = options.accessibilityLabel;
    }
    return returnValue;
  }

  // TODO implement undo/redo groups by leveraging timestamp to detect changes within x millis
  const canUndo = !(undoState.current.index < 0);
  function undo() {
    if (!canUndo) return;

    setData((curr) => {
      const currentEntry = undoState.current.entries[undoState.current.index];
      undoState.current.index -= 1;
      return applyPatches(curr, [...currentEntry.inversePatches].reverse());
    });
  }

  const canRedo = !(
    undoState.current.index < -1 || undoState.current.index === undoState.current.entries.length - 1
  );
  function redo() {
    if (!canRedo) return;

    setData((curr) => {
      undoState.current.index += 1;
      const currentEntry = undoState.current.entries[undoState.current.index];
      return applyPatches(curr, currentEntry.patches);
    });
  }

  const validate = useCallback(() => {
    let validationErrors = {};
    let humanValidationErrs = {};
    Object.entries(validators.current).forEach(([path, entry]) => {
      if (!entry) return;
      const { label, validator: validatorOrValidators } = entry;
      const validatorsArray = Array.isArray(validatorOrValidators)
        ? validatorOrValidators
        : [validatorOrValidators];
      validatorsArray.map((validator) => {
        const value = get(variablesRef.current, path);
        const error =
          typeof validator === 'function'
            ? validator(value)
            : VALIDATORS[validator.type](value, validator);
        // TODO instead of overwritting an existing error, turn into an array and/or join
        if (error) {
          set(validationErrors, path, error);
          if (label) {
            set(humanValidationErrs, label, error);
          }
        }
      });
    });
    if (formOptionsRef.current.validate) {
      validationErrors = formOptionsRef.current.validate(variablesRef.current, validationErrors);
    }
    setErrors({});
    setHumanValidationErrors({});
    setTimeout(() => {
      setErrors(validationErrors);
      setHumanValidationErrors(humanValidationErrs);
    }, 0);
    return Object.keys(validationErrors).length === 0;
  }, []);

  const clear = useCallback(() => {
    setData(({} as unknown) as T);
    setErrors({});
    setHumanValidationErrors({});
  }, []);

  return {
    dirty: Object.keys(data).length,
    bind,
    clear,
    data: variablesRef.current,
    redo: canRedo ? redo : undefined,
    undo: canUndo ? undo : undefined,
    validate,
    errors,
    humanErrors: humanValidationErrors,
  };
}
