import React from 'react';

import MyStoryMyPlanContainer, { ExplanationVideos } from '@src/components/MyStoryMyPlanContainer';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';
import { APP_SLUG } from '@src/constants';
import { useT } from '@src/lib/i18n';

export default function PatientNarrativeExplanation(
  props: JcoMyStoryMyPlanScreenProps<'PatientNarrativeExplanation'>,
) {
  const t = useT();
  const complete = useSetMyStoryMyPlanStepComplete('PatientNarrativeExplanationComplete');

  return (
    <MyStoryMyPlanContainer
      progress={1}
      heading={t('mystory_explanation.heading')}
      subheading={t('mystory_explanation.description')}
      onNext={() => {
        complete();
        if (APP_SLUG === 'oui-aviva') {
          props.navigation.navigate('SuicidalModeExplanation', props.route.params);
        } else {
          props.navigation.navigate('PatientNarrativeForm', props.route.params);
        }
      }}
      testID="PatientNarrativeExplanation"
    >
      <ExplanationVideos
        videos={[
          {
            label: t('mystory_explanation.voiceover_label'),
            uri:
              'https://storage.googleapis.com/asset.oui.dev/voiceover/Onboarding_MyStory_v1_1.mp4',
            caption: t('mystory_explanation.voiceover_caption'),
            posterSource: require('../assets/posters/MyStory.jpg'),
          },
          {
            label: t('mystory_explanation.vignette_label'),
            uri:
              'https://storage.googleapis.com/asset.oui.dev/vignette/Vet_Vignette_Background_Story_v1_1.mp4',
            caption: t('mystory_explanation.vignette_caption'),
            posterSource: require('../assets/posters/Vignette_MyStory.jpg'),
          },
        ]}
      />
    </MyStoryMyPlanContainer>
  );
}
