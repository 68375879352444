import React from 'react';
import { Linking as RNLinking, Platform, ScrollView } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import Url from 'url-parse';

import Sentry from '@src/sentry';
import { initConfig } from '@src/lib/remoteConfig';
import LogBox from '@src/lib/LogBox';
import { fetchIsConnected } from '@src/lib/NetInfo';
import { View } from '@src/components/View';
import { login, signOut, initAuth } from '@src/lib/auth';
import { initFonts } from '@src/lib/initFonts';
import { APP_SLUG, SESSION_TIMEOUT, LOADING_TIMEOUT } from '@src/constants';
import AuthScreenContainer from '@src/components/AuthScreenContainer';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import { setPersistedState, preloadPersistedState } from '@src/hooks/usePersistedState';

const IS_JCO = APP_SLUG === 'oui-jco';

async function getInitialURL() {
  return await RNLinking.getInitialURL();
}

let hasSimulatedError = false;

async function wrapInitStep<T>(
  step: string,
  factory: () => Promise<T>,
  errorValue?: T,
): Promise<T> {
  let start = Date.now();
  try {
    Sentry.addBreadcrumb({ message: 'wrapInitStep:start ' + step });
    const result = await factory();
    Sentry.addBreadcrumb({
      message: 'wrapInitStep:success ' + step,
      data: { duration: Date.now() - start },
    });
    return result;
  } catch (e) {
    Sentry.captureException(e, { extra: { initStep: step, duration: Date.now() - start } });
    Sentry.captureException(new Error(`wrapInitStep:${step} failed`), {
      extra: { originalError: e, duration: Date.now() - start },
    });
    if (typeof errorValue !== 'undefined') {
      return errorValue;
    }
    throw e;
  }
}

export async function initApp() {
  const initialURL = await getInitialURL();
  const isE2E = await preloadPersistedState('IS_E2E');

  const { query } = new Url(initialURL, {}, { parser: true });

  const isDetox = query.detox === 'true';
  const clearState = query.detoxClearState === 'true';

  if (clearState) {
    await signOut();
  }

  if (isDetox || isE2E) {
    global.e2e = true;
    LogBox.ignoreAllLogs(true);
  }

  if (query.detoxEmail && query.detoxPassword) {
    await login(query.detoxEmail, query.detoxPassword);
  }

  if (isDetox || isE2E) {
    setPersistedState('IS_E2E', true);
  }

  if (query.skipLocalAuthenticationPrompt) {
    setPersistedState(
      'SeenLocalAuthenticationPrompt',
      JSON.parse(query.skipLocalAuthenticationPrompt),
    );
  }

  if (query.asyncStorage) {
    const obj: object = JSON.parse(query.asyncStorage);
    await AsyncStorage.multiSet(Object.entries(obj));
  }

  const remoteConfigPromise = wrapInitStep('remoteConfig', initConfig);

  const lastSeenPromise = wrapInitStep(
    'lastSeen',
    () =>
      AsyncStorage.getItem('lastSeen').then((lastSeenStr) => {
        const lastSeen = Number.parseInt(lastSeenStr || '');
        return !IS_JCO && (!Number.isFinite(lastSeen) || Date.now() - lastSeen > SESSION_TIMEOUT);
      }),
    true,
  );

  const connectedPromise = wrapInitStep('netInfo', () => fetchIsConnected(5000));
  connectedPromise.then((isConnected) => {
    if (!isConnected) {
      Sentry.addBreadcrumb({ message: 'NOT_CONNECTED_TO_INTERNET' });
    }
  });

  const preloadSeenLocalAuthenticationPromptPromise = wrapInitStep(
    'preloadSeenLocalAuthenticationPrompt',
    () => preloadPersistedState('SeenLocalAuthenticationPrompt').then(() => true),
    true,
  );

  const fontPromise = wrapInitStep(
    'initFonts',
    () =>
      initFonts().then(() => {
        global.fontsLoaded = true;
        return true;
      }),
    true,
  );
  const fontTimeout = new Promise<void>((resolve) => {
    const timeout = setTimeout(() => {
      Sentry.captureMessage('FONT_TIMEOUT');
      resolve();
    }, 2000);

    fontPromise.then(() => {
      clearTimeout(timeout);
    });
  });
  const fontLoader = Promise.race([fontPromise, fontTimeout]);

  if (query.detoxSimulateLoadingError && !hasSimulatedError) {
    hasSimulatedError = true;
    throw new Error('SIMULATED LOADING ERROR');
  }

  const promise = Promise.all([
    fontLoader,
    wrapInitStep('initAuth', initAuth),
    lastSeenPromise,
    connectedPromise,
    preloadSeenLocalAuthenticationPromptPromise,
    remoteConfigPromise,
  ]).then(([, user, reauthenticate, isConnected]) => ({
    user,
    reauthenticate: !!(user && reauthenticate),
    initialURL,
    isConnected,
  }));

  const timeoutPromise = new Promise((_, reject) => {
    const timeout = setTimeout(() => {
      console.log('LOADING_TIMEOUT');
      reject(new Error('LOADING_TIMEOUT'));
    }, LOADING_TIMEOUT);
    promise.then(() => clearTimeout(timeout));
  });
  return Promise.race([promise, timeoutPromise]) as typeof promise;
}

export function shouldShowWebBlocker() {
  return (
    !global.e2e &&
    Platform.OS === 'web' &&
    ['oui-aviva'].includes(APP_SLUG) &&
    !window.location.hostname.includes('-dev-')
  );
}

export function AppError(props: { retry: () => unknown; type?: 'loading' | 'runtime' }) {
  return (
    <AuthScreenContainer
      heading="Something went wrong"
      // @src/components/ScrollView uses useIsFocused internally which depends on react-navigation mounting
      // successfully. Since in an error state, we may not be able to guarantee that, we use a
      // standard ScrollView here which should be sufficient for our needs
      _scrollView={ScrollView}
    >
      <View spacing={40} style={{ padding: Platform.OS === 'web' ? 20 : 0 }}>
        {props.type === 'runtime' ? (
          <Text text="We're sorry, but something went wrong. Please try reloading Aviva." />
        ) : (
          <Text text="We're sorry, but we couldn't load Aviva. Please make sure you're connected to the internet." />
        )}
        <View>
          <Text text="If you're in crisis, please call the National Suicide Prevention Lifeline." />
          <Button
            text="Call Lifeline"
            onPress={() => {
              RNLinking.openURL('tel:1-800-273-8255');
            }}
            alignSelf="flex-start"
            variant="text"
            testID="AppError_callLifelineButton"
          />
        </View>
        <Button text="Reload Aviva" onPress={() => props.retry()} testID="AppError_reloadButton" />
      </View>
    </AuthScreenContainer>
  );
}
