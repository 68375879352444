function createObjectURL(content: object | string, blobOptions: { type: string }) {
  const file = new Blob(
    [typeof content === 'string' ? content : JSON.stringify(content, null, 2)],
    blobOptions,
  );
  return URL.createObjectURL(file);
}

export function webDownload(
  content: object | string,
  fileName: string,
  blobOptions = { type: 'text/plain' },
) {
  const a = document.createElement('a');
  a.href = createObjectURL(content, blobOptions);
  a.download = fileName;
  a.click();
}

export function webPrint(content: object | string, blobOptions = { type: 'text/plain' }) {
  const iframe = document.createElement('iframe');
  iframe.src = createObjectURL(content, blobOptions);
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  return new Promise<void>((res) => {
    setTimeout(() => {
      iframe.contentWindow?.print();
      document.body.removeChild(iframe);
      res();
    }, 200);
  });
}
