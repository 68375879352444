import React, { useContext, useEffect, useMemo, useState } from 'react';

import NetInfo from '@src/lib/NetInfo';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Icon } from '@src/components/Icon';
import { View } from '@src/components/View';
import { Shadow } from '@src/styles';
import { startPendingUploads } from '@src/lib/resumableUploadManager';
import { Severity, addBreadcrumb } from '@src/lib/log';
import { MandatoryModal } from '@src/components/Modal';
import NotConnected from '@src/assets/notconnected.svg';

const InternetConnectivityContext = React.createContext<{ isInternetReachable: boolean | null }>({
  isInternetReachable: null,
});

export function useInternetConnectivityContext() {
  return useContext(InternetConnectivityContext);
}

export function InternetConnectivityProvider({
  forceOffline,
  children,
}: {
  forceOffline: boolean;
  children: React.ReactNode;
}) {
  const [isInternetReachable, setIsInternetReachable] = React.useState<boolean | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    let notifyUserTimeout: NodeJS.Timeout;

    const remove = NetInfo.addEventListener((state) => {
      clearTimeout(notifyUserTimeout);
      addBreadcrumb({ category: 'net-info', data: { state }, level: Severity.Debug });
      if (state.isInternetReachable === false) {
        notifyUserTimeout = setTimeout(() => {
          setShowModal(true);
          setIsInternetReachable(false);
          // This timeout helps prevent the offline state from flapping in a way that's visible to the user
        }, 10000);
      } else if (state.isInternetReachable === true) {
        setShowModal(false);
        setIsInternetReachable(true);
        startPendingUploads();
      }
    });

    return () => {
      clearTimeout(notifyUserTimeout);
      remove();
    };
  }, []);

  useEffect(() => {
    if (forceOffline) {
      setShowModal(true);
      setIsInternetReachable(false);
    } else {
      setShowModal(false);
      setIsInternetReachable(true);
    }
  }, [forceOffline]);

  const value = useMemo(() => ({ isInternetReachable }), [isInternetReachable]);

  return (
    <>
      <InternetConnectivityContext.Provider value={value}>
        {children}
      </InternetConnectivityContext.Provider>
      {isInternetReachable || isInternetReachable === null ? null : showModal ? (
        <MandatoryModal visible={true}>
          <View spacing={16} style={{ alignItems: 'center' }}>
            <NotConnected accessibilityLabel={undefined} />
            <Text text="You're offline" weight="bold" size={21} />
            <Text
              text={`Try reconnecting your wifi or data.

You'll be able to access things you've already done, but not do new things while you're offline.`}
              textAlign="center"
            />
            <Button
              text="OK"
              variant="solid"
              onPress={() => setShowModal(false)}
              alignSelf="center"
              testID="InternetConnectivityProvider_acknowledgeButton"
            />
          </View>
        </MandatoryModal>
      ) : (
        <View
          pointerEvents="none"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 140,
            width: '100%',
          }}
        >
          <View
            testID="InternetConnectivityProvider_banner"
            style={[
              {
                backgroundColor: '#932a2a',
                borderRadius: 5,
                paddingHorizontal: 10,
                paddingVertical: 5,
              },
              Shadow.low,
            ]}
            row
            spacing={8}
          >
            <Icon name="cloud-off" color="white" />
            <Text text="Currently offline" color="white" weight="semibold" />
          </View>
        </View>
      )}
    </>
  );
}
