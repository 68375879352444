import React from 'react';

import MyStoryMyPlanContainer, { ExplanationVideos } from '@src/components/MyStoryMyPlanContainer';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';
import { useT } from '@src/lib/i18n';

export default function MyPlanExplanation(props: JcoMyStoryMyPlanScreenProps<'MyPlanExplanation'>) {
  const t = useT();
  const complete = useSetMyStoryMyPlanStepComplete('MyPlanExplanationComplete');

  return (
    <MyStoryMyPlanContainer
      progress={5}
      heading={t('myplan_explanation.heading')}
      subheading={t('myplan_explanation.description')}
      onNext={() => {
        complete();
        props.navigation.navigate('MyPlanForm', props.route.params);
      }}
      testID="MyPlanExplanation"
    >
      <ExplanationVideos
        videos={[
          {
            label: t('myplan_explanation.voiceover_label'),
            uri: 'https://storage.googleapis.com/asset.oui.dev/voiceover/Onboarding_MyPlan.mp4',
            caption: t('myplan_explanation.voiceover_caption'),
            posterSource: require('../assets/posters/MyPlan.jpg'),
          },
          {
            label: t('myplan_explanation.vignette_label'),
            uri:
              'https://storage.googleapis.com/asset.oui.dev/vignette/Vet_Vignette_My_Plan_RC01.mp4',
            caption: t('myplan_explanation.vignette_caption'),
            posterSource: require('../assets/posters/Vignette_MyPlan.jpg'),
          },
        ]}
      />
    </MyStoryMyPlanContainer>
  );
}
