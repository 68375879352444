import { PixelRatio } from 'react-native';
import { stringify } from 'querystringify';
import pickBy from 'lodash/pickBy';

import videoMapping from '@src/metadata/videoMapping.json';

type MuxUrl = string & { _mux: true };
type PlaybackID = string & { _muxID: true };

type VideoMappingEntry = { assetId: string; playbackId: string };
type ReverseVideoMapping = Record<string, (VideoMappingEntry & { gcsUrl: string }) | undefined>;
let _videoMappingByAssetID: ReverseVideoMapping;

function getVideoMappingByAssetID() {
  if (_videoMappingByAssetID) return _videoMappingByAssetID;

  _videoMappingByAssetID = Object.entries(videoMapping).reduce<ReverseVideoMapping>(
    (carry, [gcsUrl, payload]) => {
      if (payload) {
        carry[payload.playbackId] = { ...payload, gcsUrl };
      }
      return carry;
    },
    {},
  );

  return _videoMappingByAssetID;
}

function getPlaybackID(url: MuxUrl) {
  return url.split('/')[3].split('.')[0] as PlaybackID;
}

export const getMuxPlaybackID = getPlaybackID;

export function isMuxUrl(str?: string): str is MuxUrl {
  return !!str?.startsWith('https://stream.mux.com');
}

// https://docs.mux.com/guides/video/get-images-from-a-video#thumbnail-query-string-parameters
export function getMuxThumbnail(
  muxUrl: MuxUrl,
  {
    format,
    width,
    height,
    fitMode = 'smartcrop',
    time,
  }: {
    format: 'png' | 'jpg';
    width: number;
    height: number;
    fitMode?: 'preserve' | 'crop' | 'smartcrop' | 'pad';
    time?: number;
  },
) {
  const id = getPlaybackID(muxUrl);
  const paramObj = {
    width: PixelRatio.getPixelSizeForLayoutSize(width),
    height: PixelRatio.getPixelSizeForLayoutSize(height),
    fit_mode: fitMode,
    time,
  };
  const params = stringify(pickBy(paramObj));
  return `https://image.mux.com/${id}/thumbnail.${format}?${params}`;
}

export function getMuxUrlFromGCSUrl(url: string): MuxUrl {
  const playbackId = (videoMapping as Record<string, { playbackId: string } | null>)[url]
    ?.playbackId;
  if (!playbackId) {
    throw new Error('Invalid url for videoMapping: ' + url);
  }
  return `https://stream.mux.com/${playbackId}.m3u8` as MuxUrl;
}

export function getMuxVTTUri(url: MuxUrl) {
  return getVideoMappingByAssetID()[getPlaybackID(url)]?.gcsUrl.replace('.mp4', '.vtt');
}
