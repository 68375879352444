import React from 'react';
import { StatusBar, TouchableOpacity, Modal, StyleProp, TextStyle } from 'react-native';

import hexToRgba from 'hex-to-rgba';

import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Shadow, useColor } from '@src/styles';
import { View } from '@src/components/View';
import { Icon } from '@src/components/Icon';

export function ConfirmationModal(props: {
  cancelTestID?: string;
  cancelText?: string;
  children?: React.ReactNode;
  confirmTestID?: string;
  confirmText?: string;
  description?: string;
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  visible: boolean;
  showCloseIcon?: boolean;
  textStyle?: StyleProp<TextStyle>;
}) {
  const { Color } = useColor();
  return (
    <Modal visible={props.visible} onRequestClose={() => {}} transparent>
      <TouchableOpacity
        accessible={false}
        activeOpacity={1}
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: hexToRgba(Color.styleGuide.Gray1, '0.5'),
        }}
        onPress={() => {}}
      >
        {props.visible ? (
          <StatusBar
            backgroundColor={hexToRgba(Color.styleGuide.Gray1, '0.5')}
            barStyle="light-content"
            translucent
          />
        ) : null}
        <TouchableOpacity
          accessible={false}
          activeOpacity={1}
          style={[
            {
              width: '90%',
              maxWidth: 400,
              backgroundColor: Color.backgroundColor,
              marginHorizontal: 10,
              borderRadius: 20,
              paddingVertical: 26,
              paddingHorizontal: 24,
            },
            Shadow.high,
          ]}
        >
          {props.children}
          {props.showCloseIcon ? (
            <View row>
              <Text text={props.title} size={21} weight="bold" textAlign="center" />
              <Icon
                name="close"
                onPress={props.onCancel}
                size={14}
                style={{
                  marginLeft: 'auto',
                }}
                accessibilityLabel="Close modal"
              />
            </View>
          ) : (
            <Text text={props.title} size={21} weight="bold" textAlign="center" />
          )}
          {props.description ? (
            <Text
              text={props.description}
              textAlign={'center'}
              style={[props.textStyle, { marginTop: 16 }]}
            />
          ) : null}
          <View row style={{ justifyContent: 'space-between', marginTop: 32 }}>
            <Button
              onPress={props.onCancel}
              text={props.cancelText ?? 'Cancel'}
              variant="text"
              testID={props.cancelTestID}
            />
            <Button
              onPress={props.onConfirm}
              text={props.confirmText ?? 'OK'}
              variant="solid"
              testID={props.confirmTestID}
            />
          </View>
        </TouchableOpacity>
      </TouchableOpacity>
    </Modal>
  );
}
