import type ApolloClient from '@src/lib/apollo';
import * as userResponse from '@src/lib/userResponse';
import { ContentType } from '@src/types/generated_v2';

import { SelfPatient_patientByPatientID_progress } from '@src/hooks/__generated__/SelfPatient';
import { CurrentUserDocument, CurrentUserQuery } from '@src/hooks/useCurrentUser.graphql.generated';
import { performSaveResponse } from '@src/lib/userResponse.graphql.generated';
import { performFabricateStateV2End } from './mockUserState.graphql.generated';

export function saveUserResponse(
  apollo: ApolloClient<unknown>,
  patientID: string,
  context: string,
  key: string,
  data: GQLAny,
) {
  return apollo.mutate<userResponse.SaveUserResponse, userResponse.SaveUserResponseVariables>({
    mutation: userResponse.SaveUserResponseMutation,
    variables: {
      patientID,
      context,
      key,
      data,
    },
  });
}

export function saveResponse(
  apollo: ApolloClient<unknown>,
  context: string,
  key: string,
  data: GQLAny,
) {
  return performSaveResponse(apollo, { context, key, data });
}

export function mockConversationCompleteV2(client: ApolloClient<unknown>, convoID: ContentType) {
  return performFabricateStateV2End(
    client,
    { content: convoID },
    {
      update: (cache, result) => {
        if (!result.data) return;
        const query = cache.readQuery<CurrentUserQuery>({ query: CurrentUserDocument });
        const patientID = query?.currentUser?.user?.ID;
        const key = cache.identify({ __typename: 'PatientProfile', patient: { ID: patientID } });
        cache.modify({
          id: key,
          fields: {
            ID: (id) => {
              return id;
            },
            progress: (existingProgress: SelfPatient_patientByPatientID_progress[] = []) => {
              if (!result.data) return existingProgress;
              const progress = existingProgress.filter((p) => p.content !== convoID);
              return [...progress, result.data.setOuiProgress];
            },
          },
        });
      },
    },
  );
}

export function mockMyStoryMyPlanComplete(
  apollo: ApolloClient<unknown>,
  userID: string,
  { respondForPatient }: { respondForPatient: boolean },
) {
  return respondForPatient
    ? saveUserResponse(apollo, userID, 'MyStoryMyPlan', 'MyPlanFormComplete', { complete: true })
    : saveResponse(apollo, 'MyStoryMyPlan', 'MyPlanFormComplete', { complete: true });
}
