import { useMutation } from '@apollo/client';
import React from 'react';
import { SafeAreaView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import uuid from 'uuid/v4';

import { ScrollView } from '@src/components/ScrollView';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Icon } from '@src/components/Icon';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { Shadow, card, Color } from '@src/styles';
import { useForm } from '@src/hooks/useForm';
import { useT } from '@src/lib/i18n';
import { getStartingJcoModule, getStartingJcoScreen } from '@src/lib/getStartingJcoModule';

import { SaveUserResponseMutation, SaveUserResponseVariables } from '@src/lib/userResponse';
import CAPS_Logo from '@src/assets/CAPS_Logo.svg';

function Step({ text, duration, index }: { text: string; duration: number; index: number }) {
  const t = useT();
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', maxWidth: 200 }} spacing={12}>
      <View
        style={{
          height: 40,
          width: 40,
          borderRadius: 20,
          backgroundColor: Color.tertiary,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Text text={(index + 1).toString()} color="white" weight="bold" />
      </View>
      <Text text={text} size={21} weight="semibold" textAlign="center" />
      <View row spacing={8}>
        <Icon name="clock" color={Color.styleGuide.Gray5} />
        <Text
          text={t('new_safety_screening.duration_in_minutes', {
            numMinutes: duration,
          })}
        />
      </View>
    </View>
  );
}

export default function NewSafetyScreening() {
  const t = useT();
  const [startScreening] = useMutation<void, SaveUserResponseVariables>(SaveUserResponseMutation);
  const { navigate } = useNavigation();
  const { validate, data, bind } = useForm<{
    patientID: string;
    clinicianName: string;
  }>({});
  const startingModule = getStartingJcoModule();

  function start() {
    const newUuid = uuid();
    return startScreening({
      fetchPolicy: 'no-cache',
      variables: {
        patientID: newUuid,
        context: 'caps',
        key: 'newSafetyScreening',
        data,
      },
    }).then(() => ({ data: { patientID: newUuid } }));
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <View spacing={40} style={{ paddingBottom: 40 }}>
          <View
            spacing={20}
            style={{ alignItems: 'center', padding: 40, backgroundColor: 'white' }}
          >
            <CAPS_Logo color={Color.accent} accessibilityLabel="CAPS" />
            <Text text={t('new_safety_screening.description')} weight="semibold" />
          </View>
          {startingModule === 'safety' ? (
            <View
              testID="NewSafetyScreening_steps"
              row
              style={{ width: 800, alignSelf: 'center', justifyContent: 'space-between' }}
              spacing={50}
            >
              <Step index={0} text={t('new_safety_screening.step_one')} duration={5} />
              <Step index={1} text={t('new_safety_screening.step_two')} duration={10} />
              <Step index={2} text={t('new_safety_screening.step_three')} duration={30} />
            </View>
          ) : null}
          <View
            style={[card, Shadow.overlay, { width: 600, alignSelf: 'center', padding: 20 }]}
            spacing={24}
          >
            <Text
              text={t('new_safety_screening.form.header')}
              style={{ textAlign: 'center' }}
              size={30}
              weight="semibold"
              color={Color.tertiary}
            />
            <View>
              <TextInput
                placeholder={t('new_safety_screening.form.patient_placeholder')}
                {...bind(['patientID'], {
                  label: `${t('new_safety_screening.form.patient_label')} *`,
                  validator: { type: 'present' },
                })}
                testID="NewSafetyScreening_patientIDInput"
              />
              <Text
                text={t('new_safety_screening.form.patient_hint')}
                size={13}
                color={Color.styleGuide.Gray4}
              />
            </View>
            <View>
              <TextInput
                placeholder={t('new_safety_screening.form.clinician_placeholder')}
                {...bind(['clinicianName'], {
                  label: `${t('new_safety_screening.form.clinician_label')} *`,
                  validator: { type: 'present' },
                })}
                testID="NewSafetyScreening_clinicianNameInput"
              />
            </View>
            <Button
              testID="NewSafetyScreening_submit"
              text={t('new_safety_screening.form.submit_button')}
              iconRight="arrow-right"
              onPress={() => {
                if (validate()) {
                  return start().then((result) => {
                    if (result && result.data) {
                      navigate(getStartingJcoScreen(), { patientID: result.data.patientID });
                    }
                  });
                }
                return;
              }}
              alignSelf="center"
              variant="solid"
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
