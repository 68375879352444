import React, { useState } from 'react';
import produce from 'immer';

import { View } from '@src/components/View';
import Checkbox from '@src/components/Checkbox';
import { Text, Subheading, Heading } from '@src/components/Text';
import TextInput from '@src/components/TextInput';
import { useAppContext } from '@src/hooks/useAppContext';

type CrisisService = {
  name: string;
  description: string;
};

type Value = { national: CrisisService[]; local: CrisisService[]; doctor: string };
export type CrisisServices = Value;

const NATIONAL: CrisisService[] = [
  {
    name: 'National Suicide Prevention Lifeline',
    description: 'For anyone in suicidal crisis or emotional distress',
  },
  {
    name: 'Trevor Lifeline',
    description: 'For LGBTQ youth',
  },
  {
    name: 'Veterans Crisis Line',
    description: 'For veterans or service members',
  },
];

const LOCAL: CrisisService[] = [
  {
    name: 'The Samaritans of New York',
    description: 'For anyone in suicidal crisis or emotional distress',
  },
  {
    name: 'Help-Line Telephone Services',
    description: 'For anyone in suicidal crisis or emotional distress',
  },
];

const MyPlanCrisisServicesInput = (props: {
  value: Value;
  onChangeValue: (value: Value) => void;
}) => {
  const [showDoctorInput, setShowDoctorInput] = useState(!!props.value.doctor);
  const { flags } = useAppContext();

  const toggleService = (
    add: boolean,
    type: Exclude<keyof CrisisServices, 'doctor'>,
    service: CrisisService,
  ) => {
    const newValue = produce(props.value, (draft) => {
      if (add) {
        draft[type].push(service);
      } else {
        draft[type] = draft[type].filter((s) => s.name !== service.name);
      }
    });
    props.onChangeValue(newValue);
  };

  function renderService(type: Exclude<keyof CrisisServices, 'doctor'>, service: CrisisService) {
    return (
      <View row spacing={8} key={service.name} style={{ alignItems: 'flex-start' }}>
        <Checkbox
          value={!!props.value[type].find((s) => s.name === service.name)}
          onChangeValue={(checked) => {
            toggleService(checked, type, service);
          }}
        />
        <View style={{ flex: 1 }} spacing={4}>
          <Text text={service.name} weight="bold" />
          <Text text={service.description} />
        </View>
      </View>
    );
  }

  return (
    <View spacing={20} style={{ backgroundColor: 'white', flex: 1, padding: 16 }}>
      <Heading text="Crisis & professional services" />
      <View row style={{ alignItems: 'flex-start', marginBottom: 40 }} spacing={20}>
        <View flex={1} spacing={20}>
          <Subheading text="National hotlines" />
          {NATIONAL.map((service) => renderService('national', service))}
        </View>
        {flags.showLocalCrisisServices ? (
          <View flex={1} spacing={20}>
            <Subheading text="Local hotlines" />
            {LOCAL.map((service) => renderService('local', service))}
          </View>
        ) : (
          false
        )}
      </View>
      <View>
        <View row spacing={4}>
          <Checkbox value={showDoctorInput} onChangeValue={setShowDoctorInput} />
          <Subheading text="Doctor or therapist" />
        </View>
        {showDoctorInput ? (
          <TextInput
            placeholder="Name of doctor or therapist"
            style={{ width: 400 }}
            value={props.value.doctor}
            onChangeValue={(doctor) => props.onChangeValue({ ...props.value, doctor })}
          />
        ) : null}
      </View>
    </View>
  );
};

export default MyPlanCrisisServicesInput;
