import { useMutation, useQuery, gql as gqlDeprecated } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import debounce from 'lodash/debounce';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ApolloClient from '@src/lib/apollo';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { UnorderedList } from '@src/components/UnorderedList';
import { Schema, useRenderForm, FormAnswers } from '@src/components/SchemaForm';
import { MyPlanCard } from '@src/components/MyStoryMyPlanCards';
import { useAppContext } from '@src/hooks/useAppContext';
import { useForm } from '@src/hooks/useForm';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { View } from '@src/components/View';
import { Shadow, Color } from '@src/styles';
import { Text, Heading } from '@src/components/Text';
import { Button } from '@src/components/Button';
import MyPlanContactsInput, { Contact } from '@src/components/MyPlanContactsInput';
import MyPlanCrisisServicesInput, {
  CrisisServices,
} from '@src/components/MyPlanCrisisServicesInput';
import CheckboxFormInput, {
  getTextListFromAnswerEntries,
  CheckboxAnswerList,
  CheckboxForm,
  CheckboxFormQuestion,
} from '@src/components/CheckboxFormInput';
import {
  SaveMyPlanResponse,
  SaveMyPlanResponseVariables,
} from './__generated__/SaveMyPlanResponse';
import {
  GetSuicidalModeAndMyPlanAnswers,
  GetSuicidalModeAndMyPlanAnswersVariables,
  GetSuicidalModeAndMyPlanAnswers_patientCrisisService,
} from './__generated__/GetSuicidalModeAndMyPlanAnswers';
import { SuicidalMode } from '@src/metadata/suicidalModeQuestions';
import MyStoryMyPlanContainer from '@src/components/MyStoryMyPlanContainer';
import { APP_SLUG } from '@src/constants';
import { useT, TranslationFunction, getTranslations } from '@src/lib/i18n';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';

const NSL_CRISIS_SERVICE: GetSuicidalModeAndMyPlanAnswers_patientCrisisService = {
  name: {
    first: 'National Suicide Prevention Lifeline',
    last: null,
    nick: null,
  },
  phone: ['1-800-273-8255'],
  email: [''],
  image: {
    data: null,
    url: 'https://storage.googleapis.com/asset.oui.dev/static/NSPL-logo.jpg',
  },
};

const SaveMyPlanResponseQuery = gqlDeprecated`
  mutation SaveMyPlanResponse(
    $patientID: UserID!
    $key: String!
    $value: Any!
    $resultKey: String!
    $resultValue: Any!
  ) {
    respondForPatient(patientID: $patientID, context: "myPlan", key: $key, data: $value)
    respondForPatientResult: respondForPatient(
      patientID: $patientID
      context: "myPlan"
      key: $resultKey
      data: $resultValue
    )
  }
`;

const GetSuicidalModeAnswersQuery = gqlDeprecated`
  query GetSuicidalModeAndMyPlanAnswers($patientID: UserID!) {
    activatingEvents: patientResponse(
      patientID: $patientID
      context: "suicidalMode"
      key: "activatingEvents"
    )
    behavioral: patientResponse(patientID: $patientID, context: "suicidalMode", key: "behavioral")
    cognitive: patientResponse(patientID: $patientID, context: "suicidalMode", key: "cognitive")
    emotional: patientResponse(patientID: $patientID, context: "suicidalMode", key: "emotional")
    physiological: patientResponse(
      patientID: $patientID
      context: "suicidalMode"
      key: "physiological"
    )
    riskFactors: patientResponse(patientID: $patientID, context: "suicidalMode", key: "riskFactors")

    warningSigns: patientResponse(patientID: $patientID, context: "myPlan", key: "warningSigns")
    selfManagement: patientResponse(patientID: $patientID, context: "myPlan", key: "selfManagement")
    contacts: patientResponse(patientID: $patientID, context: "myPlan", key: "contacts")
    crisisServices: patientResponse(patientID: $patientID, context: "myPlan", key: "crisisServices")
    meansSafetyPlan: patientResponse(
      patientID: $patientID
      context: "myPlan"
      key: "meansSafetyPlan"
    )

    patientContact(patientID: $patientID) {
      name {
        first
        last
        nick
      }
      phone
      email
      image {
        url
        data
      }
    }
    patientCrisisService(patientID: $patientID) {
      name {
        first
        last
        nick
      }
      phone
      email
      image {
        url
        data
      }
    }
  }
`;

// prettier-ignore
const WarningSignsQuestions: CheckboxForm<CheckboxFormQuestion> = {
  cognitive__idiot: { category: 'Thoughts', label: 'I’m an idiot' },
  cognitive__endless: { category: 'Thoughts', label: 'This will never end' },
  cognitive__fault: { category: 'Thoughts', label: 'It’s my fault' },
  cognitive__nobody_cares: { category: 'Thoughts', label: 'Nobody cares about me' },
  cognitive__cannot_continue: { category: 'Thoughts', label: 'I can’t take this anymore' },
  cognitive__deserve_punishment: { category: 'Thoughts', label: 'I deserve to be punished' },
  cognitive__failure: { category: 'Thoughts', label: 'I’m a failure' },

  mental_image__stressful: { category: 'Mental images', label: "Stressful memories", question: 'What is the memory?' },
  mental_image__flashbacks: { category: 'Mental images', label: "Flashbacks about trauma", question: 'What is the flashback?' },
  mental_image__uncomfortable_situations: { category: 'Mental images', label: "Reliving uncomfortable situations", question: 'What is the situation?' },
  mental_image__suicide_attempt: { category: 'Mental images', label: "Seeing myself make a suicide attempt", question: 'What do you see?' },
  mental_image__after_death: { category: 'Mental images', label: "Seeing what happens after I die", question: 'What do you see?' },

  physical__pain: { category: 'Physical', label: "Headaches or other pain", question: 'Where is the pain?' },
  physical__agitation: { category: 'Physical', label: "Agitation/feeling on edge", question: '' },
  physical__racing_heart: { category: 'Physical', label: "Racing heart", question: '' },
  physical__muscle_tension: { category: 'Physical', label: "Muscle tension", question: '' },
  physical__nausea: { category: 'Physical', label: "Nausea", question: '' },
  physical__breathing: { category: 'Physical', label: "Breathing difficulties", question: '' },
  physical__insomnia: { category: 'Physical', label: "Insomnia", question: '' },

  behavioral__pacing: { category: 'Behaviors', label: "Pacing", question: '' },
  behavioral__avoiding: { category: 'Behaviors', label: "Avoiding others", question: '' },
  behavioral__yelling: { category: 'Behaviors', label: "Yelling/screaming", question: '' },
  behavioral__crying: { category: 'Behaviors', label: "Crying", question: '' },
  behavioral__shaking: { category: 'Behaviors', label: "Shaking/trembling", question: '' },
  behavioral__aggression: { category: 'Behaviors', label: "Aggression", question: '' },
  behavioral__self_harm: { category: 'Behaviors', label: "Self-injury", question: '' },
  behavioral__rehearse_suicide: { category: 'Behaviors', label: "Practicing/rehearsing the suicide attempt", question: '' },
  behavioral__prepare_suicide: { category: 'Behaviors', label: "Getting ready for a suicide attempt", question: '' },

  emotional__sadness: { category: 'Emotions', label: "Sadness or depression", question: '' },
  emotional__guilt: { category: 'Emotions', label: "Guilt or remorse", question: '' },
  emotional__worry: { category: 'Emotions', label: "Anxiety", question: '' },
  emotional__anger: { category: 'Emotions', label: "Anger", question: '' },

  other: { category: '', label: "Other", question: 'What was your warning sign?' },
};

const SelfManagementQuestions: CheckboxForm<CheckboxFormQuestion> = {
  movie: { label: 'Watching a movie', question: 'A specific movie?' },
  tv: { label: 'Watching a TV show', question: 'A specific TV show?' },
  music: { label: 'Listening to music', question: 'Any specific song?' },
  singing: { label: 'Singing', question: 'Any specific song?' },
  pet: { label: 'Playing with a pet', question: '' },
  walk: { label: 'Going for a walk', question: '' },
  exercise: { label: 'Exercising', question: 'A specific exercise?' },
  bathe: { label: 'Taking a warm bath or shower', question: '' },
  read_book: { label: 'Reading a book', question: 'A specific book?' },
  read_spiritual: { label: 'Reading spiritual or religious material', question: '' },
  meditation: { label: 'Meditation', question: '' },
  relaxation_exercise: { label: 'Relaxation or breathing exercises', question: '' },
  prayer: { label: 'Prayer', question: 'A specific prayer?' },
  puzzles: { label: 'Puzzles', question: 'Any specific puzzles?' },
  upcoming_event: { label: 'Thinking about a positive upcoming event', question: '' },
  positive_memories: { label: 'Thinking about positive memories', question: 'A specific memory?' },
  loved_ones: { label: 'Thinking about loved ones', question: '' },
  future: { label: 'Thinking about the future', question: '' },
  important_things: { label: 'Focusing on the important things in your life', question: '' },
  pictures_friends: { label: 'Looking at pictures of friends', question: 'Any specific pictures?' },
  reading_letters: { label: 'Reading letters or e-mails from family members', question: '' },
  eating: { label: 'Eating a favorite food', question: 'Any specific foods?' },
  cooking: { label: 'Cooking or baking', question: 'Any specific recipes?' },
  sports: { label: 'Playing a sport', question: 'A specific sport?' },
  other: { label: 'Other', question: 'How do you manage yourself?' },
};

function getQuestionsFromSuicidalModeCheckboxAnswers(
  suicidalModeQuestions: SuicidalMode<CheckboxForm<CheckboxFormQuestion>>,
  suicidalModeAnswers: GetSuicidalModeAndMyPlanAnswers,
  section: keyof SuicidalMode,
) {
  const categoryBySection: SuicidalMode<string> = {
    behavioral: 'Behaviors',
    cognitive: 'Thoughts',
    emotional: 'Emotions',
    physiological: 'Physical',
    riskFactors: '',
    activatingEvents: '',
  };

  const sectionAnswers = suicidalModeAnswers[section];
  return sectionAnswers
    ? Object.entries(sectionAnswers).reduce<CheckboxForm<CheckboxFormQuestion>>(
        (carry, [key, value]) => {
          const label = value.text || suicidalModeQuestions[section][key]?.label;
          if (
            // Check presense of label for backwards compatibility when changing SuicidalModeQuestions keys
            label &&
            value.checked &&
            !Object.values(WarningSignsQuestions).find((q) => q.label === label)
          ) {
            label.split('\n').map((part: string, i: number, arr: string[]) => {
              const newKey =
                arr.length === 1
                  ? `suicidalMode_${section}_${key}`
                  : `suicidalMode_${section}_${key}_${i}`;
              carry[newKey] = {
                category: categoryBySection[section],
                label: part,
              };
            });
          }
          return carry;
        },
        {},
      )
    : {};
}

function getPersonalizedWarningSigns(
  suicidalModeQuestions: SuicidalMode<CheckboxForm<CheckboxFormQuestion>>,
  suicidalModeAnswers: GetSuicidalModeAndMyPlanAnswers | undefined,
): typeof WarningSignsQuestions {
  if (!suicidalModeAnswers) return WarningSignsQuestions;
  return {
    ...WarningSignsQuestions,
    ...getQuestionsFromSuicidalModeCheckboxAnswers(
      suicidalModeQuestions,
      suicidalModeAnswers,
      'cognitive',
    ),
    ...getQuestionsFromSuicidalModeCheckboxAnswers(
      suicidalModeQuestions,
      suicidalModeAnswers,
      'emotional',
    ),
    ...getQuestionsFromSuicidalModeCheckboxAnswers(
      suicidalModeQuestions,
      suicidalModeAnswers,
      'physiological',
    ),
    ...getQuestionsFromSuicidalModeCheckboxAnswers(
      suicidalModeQuestions,
      suicidalModeAnswers,
      'behavioral',
    ),
  };
}

type MyPlan = {
  warningSigns: CheckboxForm;
  selfManagement: CheckboxForm;
  contacts: { names: string[] };
  crisisServices: CrisisServices;
  meansSafetyPlan: object;
};

type Section = JcoMyStoryMyPlanScreenProps<'MyPlanForm'>['route']['params']['myPlanSelected'];

function getNextSection(data: MyPlan): Section {
  if (!Object.values(data.warningSigns).find((v) => v!.checked)) {
    return 'warningSigns';
  } else if (!Object.values(data.selfManagement).find((v) => v!.checked)) {
    return 'selfManagement';
  }

  if (APP_SLUG === 'oui-jco') {
    if ((data.contacts?.names?.length ?? 0) === 0) {
      return 'contacts';
    } else if (Object.keys(data.meansSafetyPlan || {}).length === 0) {
      return 'meansSafetyPlan';
    }
  }

  return '';
}

const DEFAULT_FORM: MyPlan = {
  warningSigns: {},
  selfManagement: {},
  contacts: { names: [] },
  crisisServices: { national: [], local: [], doctor: '' },
  meansSafetyPlan: {},
};

function getHeading(t: TranslationFunction, section: Section, active: boolean) {
  if (!active) return t('myplan_form.heading');
  switch (section) {
    case 'warningSigns':
      return t('myplan_form.warning_signs.heading');
    case 'selfManagement':
      return t('myplan_form.self_management.heading');
    case 'contacts':
      return t('myplan_form.contacts.heading');
    case 'crisisServices':
      return t('myplan_form.crisis_services.heading');
    case 'meansSafetyPlan':
      return t('myplan_form.means_safety_plan.heading');
    default:
      return t('myplan_form.heading');
  }
}

export function getPlaceholderText(t: TranslationFunction, section: Exclude<Section, ''>): string {
  switch (section) {
    case 'warningSigns':
      return t('myplan_form.warning_signs.description');
    case 'selfManagement':
      return t('myplan_form.self_management.description');
    case 'contacts':
      return t('myplan_form.contacts.description');
    case 'crisisServices':
      return t('myplan_form.crisis_services.description');
    case 'meansSafetyPlan':
      return t('myplan_form.means_safety_plan.description');
    default:
      return t('myplan_form.description');
  }
}

function getMeansSafetyPlanItems(t: TranslationFunction, data: FormAnswers) {
  if (!data) return [];
  const schema = getMeansSafetyPlanSchema(t, data);
  const items: string[] = [];

  const otherMeansValue: string[] | undefined = data.other_means?.[0]?.value as any;
  const otherMeansLabels = otherMeansValue
    ?.map((key: string) => {
      const found = (schema.other_means as any).choices.find((c: any) => c.value === key);
      return key === 'other'
        ? (data.other_means?.[0] as any)?.followups?.other[0].value ?? found.label
        : found?.label;
    })
    .flat();
  const otherMeansSuffix = otherMeansLabels ? ` (${otherMeansLabels.join(', ')})` : '';

  const secureGunValue: string[] | undefined = data.secure_gun?.[0]?.value as any;
  const secureGunLabels = secureGunValue
    ?.map((key: string) => {
      const found = (schema.secure_gun as any).choices.find((c: any) => c.value === key);
      return key === 'other'
        ? (data.secure_gun?.[0] as any)?.followups?.other[0].value ?? found.label
        : found?.label;
    })
    .flat();

  const secureOtherValue: string[] | undefined = data.secure_other?.[0]?.value as any;
  const secureOtherMeans: string[] | undefined = secureOtherValue
    ?.map((key: string) => {
      const found = (schema.secure_other as any).choices.find((c: any) => c.value === key);
      return key === 'other'
        ? (data.secure_other?.[0] as any)?.followups?.other[0].value ?? found.label
        : found?.label;
    })
    .flat();

  secureGunLabels?.forEach((i) => items.push(i));
  secureOtherMeans?.forEach((i) => items.push(`${i}${otherMeansSuffix}`));

  return items;
}

function getMeansSafetyPlanSchema(t: TranslationFunction, data: FormAnswers) {
  const schema: Schema = {
    own_gun: {
      index: 0,
      type: 'yesno',
      label: t('means_safety_plan_form.own_gun.label'),
    },
    other_means: {
      index: 1,
      type: 'multichoice',
      label: t('means_safety_plan_form.other_means.label'),
      choices: [
        { label: t('means_safety_plan_form.other_means.choices.poison'), value: 'poison' },
        { label: t('means_safety_plan_form.other_means.choices.drugs'), value: 'drugs' },
        {
          label: t('means_safety_plan_form.other_means.choices.prescriptions'),
          value: 'prescriptions',
        },
        {
          label: t('means_safety_plan_form.other_means.choices.suffocation'),
          value: 'suffocation',
        },
        { label: t('means_safety_plan_form.other_means.choices.hanging'), value: 'hanging' },
        { label: t('means_safety_plan_form.other_means.choices.cutting'), value: 'cutting' },
        { label: t('means_safety_plan_form.other_means.choices.other'), value: 'other' },
      ],
      followups: {
        other: [
          {
            type: 'text',
            placeholder: t('means_safety_plan_form.other_means.other_followup'),
            other: true,
          },
        ],
      },
    },
  };
  if (data['own_gun']?.[0]?.value?.[0]) {
    schema.secure_gun = {
      index: 0.5,
      type: 'multichoice',
      label: t('means_safety_plan_form.secure_gun.label'),
      choices: [
        {
          label: t('means_safety_plan_form.secure_gun.choices.give_to_family'),
          value: 'give_to_family',
        },
        { label: t('means_safety_plan_form.secure_gun.choices.gun_safe'), value: 'gun_safe' },
        {
          label: t('means_safety_plan_form.secure_gun.choices.separate_storage'),
          value: 'separate_storage',
        },
        {
          label: t('means_safety_plan_form.secure_gun.choices.trigger_lock'),
          value: 'trigger_lock',
        },
        { label: t('means_safety_plan_form.secure_gun.choices.cap_lock'), value: 'cap_lock' },
        { label: t('means_safety_plan_form.secure_gun.choices.other'), value: 'other' },
      ],
      followups: {
        other: [
          {
            type: 'text',
            placeholder: t('means_safety_plan_form.secure_gun.other_followup'),
            other: true,
          },
        ],
      },
    };
  }
  if (data['other_means']?.[0]?.value?.length) {
    schema.secure_other = {
      index: 1.5,
      type: 'multichoice',
      label: t('means_safety_plan_form.secure_other.label'),
      choices: [
        {
          label: t('means_safety_plan_form.secure_other.choices.give_to_family'),
          value: 'give_to_family',
        },
        { label: t('means_safety_plan_form.secure_other.choices.throw_away'), value: 'throw_away' },
        {
          label: t('means_safety_plan_form.secure_other.choices.someone_else_possesses'),
          value: 'someone_else_possesses',
        },
        { label: t('means_safety_plan_form.secure_other.choices.lock_away'), value: 'lock_away' },
        { label: t('means_safety_plan_form.secure_other.choices.other'), value: 'other' },
      ],
      followups: {
        other: [
          {
            type: 'text',
            placeholder: t('means_safety_plan_form.secure_other.other_followup'),
            other: true,
          },
        ],
      },
    };
  }
  return schema;
}

function MeansSafetyPlan({
  value,
  onChangeValue,
}: {
  value: object;
  onChangeValue: (v: object) => void;
}) {
  const t = useT();
  const { data: formData, form } = useRenderForm(
    getMeansSafetyPlanSchema.bind(null, t),
    value as any,
  );
  useDeepCompareEffect(() => {
    onChangeValue(formData);
  }, [formData]);

  return (
    <View
      style={[Shadow.medium, { borderRadius: 10, backgroundColor: 'white', flex: 1, padding: 20 }]}
    >
      {form}
    </View>
  );
}

export async function myPlanFormText({
  locale,
  client,
  t,
  patientID,
}: {
  locale: string;
  client: ApolloClient<unknown>;
  t: TranslationFunction;
  patientID: string;
}): Promise<string> {
  const { data } = await client.query<
    GetSuicidalModeAndMyPlanAnswers & SuicidalMode & MyPlan,
    GetSuicidalModeAndMyPlanAnswersVariables
  >({
    query: GetSuicidalModeAnswersQuery,
    variables: {
      patientID,
    },
  });

  const SuicidalModeQuestions = getTranslations<SuicidalMode<CheckboxForm<CheckboxFormQuestion>>>(
    locale,
    'suicidal_mode_form.questions',
  );

  const warningSigns = getTextListFromAnswerEntries({
    questions: getPersonalizedWarningSigns(SuicidalModeQuestions, data),
    answers: data.warningSigns,
  }).map((text) => `- ${text}`);
  const selfManagement = getTextListFromAnswerEntries({
    questions: SelfManagementQuestions,
    answers: data.selfManagement,
  }).map((text) => `- ${text}`);
  const contacts = data?.contacts?.names.map((text) => `- ${text}`);
  const crisisServices = [`- ${NSL_CRISIS_SERVICE.name.first}: ${NSL_CRISIS_SERVICE.phone![0]}`];
  const meansSafetyPlan = getMeansSafetyPlanItems(t, (data.meansSafetyPlan as any) ?? {}).map(
    (text) => `- ${text}`,
  );

  return `
${t('myplan_form.heading')}

${t('myplan_form.warning_signs.card_title')}
${warningSigns.join('\n')}

${t('myplan_form.self_management.card_title')}
${selfManagement.join('\n')}

${t('myplan_form.contacts.card_title')}
${contacts.join('\n')}

${t('myplan_form.crisis_services.card_title')}
${crisisServices.join('\n')}

${t('myplan_form.means_safety_plan.card_title')}
${meansSafetyPlan.join('\n')}
`.trim();
}

function MyPlanForm(
  props: JcoMyStoryMyPlanScreenProps<'MyPlanForm', { printRef?: React.MutableRefObject<unknown> }>,
) {
  const { locale } = useAppContext();
  const t = useT();
  const SuicidalModeQuestions = getTranslations<SuicidalMode<CheckboxForm<CheckboxFormQuestion>>>(
    locale,
    'suicidal_mode_form.questions',
  );
  const patientID = props.route.params.patientID;
  const selected = props.route.params.myPlanSelected ?? '';
  const setSelected = (val: Section) => {
    props.navigation.push('MyPlanForm', { ...props.route.params, myPlanSelected: val });
  };
  const [error, setError] = useState('');
  const scrollViewRef = useRef<ScrollView>(null);
  const [saveMyPlanResponse] = useMutation<SaveMyPlanResponse, SaveMyPlanResponseVariables>(
    SaveMyPlanResponseQuery,
  );
  const saveMyPlanResponseDebounced = useRef(debounce(saveMyPlanResponse, 500));
  const { data: persistedResponses, loading: isLoading } = useQuery<
    GetSuicidalModeAndMyPlanAnswers,
    GetSuicidalModeAndMyPlanAnswersVariables
  >(GetSuicidalModeAnswersQuery, {
    variables: {
      patientID,
    },
  });
  const complete = useSetMyStoryMyPlanStepComplete('MyPlanFormComplete');

  const { bind, data } = useForm<MyPlan>(
    persistedResponses
      ? ({
          warningSigns: persistedResponses.warningSigns || {},
          selfManagement: persistedResponses.selfManagement || {},
          contacts: persistedResponses.contacts || { names: [] },
          crisisServices: persistedResponses.crisisServices || {
            local: [],
            national: [],
            doctor: '',
          },
          meansSafetyPlan: persistedResponses.meansSafetyPlan || {},
        } as MyPlan)
      : DEFAULT_FORM,
  );

  const nextSection = getNextSection(data);

  const isFirstCardActive =
    !nextSection || nextSection === 'warningSigns' || nextSection === 'selfManagement';
  const isSecondCardActive = !nextSection || nextSection === 'contacts';
  const isThirdCardActive = !nextSection || nextSection === 'crisisServices';
  const isFourthCardActive = !nextSection || nextSection === 'meansSafetyPlan';

  const { value: warningSigns, onChangeValue: onChangeWarningSigns } = bind('warningSigns', {
    label: undefined,
  });
  const { value: selfManagement, onChangeValue: onChangeSelfManagement } = bind('selfManagement', {
    label: undefined,
  });

  function getResultValue(newValue: CheckboxForm | object) {
    if (selected !== 'warningSigns' && selected !== 'selfManagement') return [];

    const questions =
      selected === 'warningSigns'
        ? getPersonalizedWarningSigns(SuicidalModeQuestions, persistedResponses)
        : SelfManagementQuestions;

    return getTextListFromAnswerEntries({ questions, answers: newValue as CheckboxForm });
  }

  const onSaveSelectedDebounced = (newValue: CheckboxForm) => {
    saveMyPlanResponseDebounced.current({
      variables: {
        patientID,
        key: selected,
        value: newValue,
        resultKey: `${selected}List`,
        resultValue: getResultValue(newValue),
      },
    });
  };

  return (
    <MyStoryMyPlanContainer
      progress={6}
      heading={getHeading(t, selected || nextSection, !!selected)}
      scrollViewRef={scrollViewRef}
      testID="MyPlanForm"
    >
      {error ? (
        <Text text={error} style={{ marginBottom: 20 }} size={30} color={Color.error} />
      ) : null}
      <View
        ref={(r) => {
          if (props.printRef) props.printRef.current = r;
        }}
        style={{
          flexGrow: 1,
          padding: 20,
          backgroundColor: Color.styleGuide.LogoLilac,
          borderRadius: 20,
        }}
      >
        {isLoading ? (
          <ActivityIndicator />
        ) : selected === 'warningSigns' ? (
          <CheckboxFormInput
            placeholder={getPlaceholderText(t, 'warningSigns')}
            questions={getPersonalizedWarningSigns(SuicidalModeQuestions, persistedResponses)}
            formTitle={t('myplan_form.warning_signs.card_title')}
            value={warningSigns}
            onChangeValue={(newValue) => {
              onSaveSelectedDebounced(newValue);
              onChangeWarningSigns(newValue);
            }}
          />
        ) : selected === 'selfManagement' ? (
          <CheckboxFormInput
            placeholder={getPlaceholderText(t, 'selfManagement')}
            accentColor={Color.primary}
            questions={SelfManagementQuestions}
            formTitle={t('myplan_form.self_management.card_title')}
            value={selfManagement}
            onChangeValue={(newValue) => {
              onSaveSelectedDebounced(newValue);
              onChangeSelfManagement(newValue);
            }}
          />
        ) : selected === 'contacts' ? (
          <MyPlanContactsInput {...bind('contacts', { label: undefined })} />
        ) : selected === 'crisisServices' ? (
          <MyPlanCrisisServicesInput {...bind('crisisServices', { label: undefined })} />
        ) : selected === 'meansSafetyPlan' ? (
          <MeansSafetyPlan {...bind('meansSafetyPlan', { label: undefined })} />
        ) : (
          <View flex={1} spacing={16}>
            <MyPlanCard disabled={!isFirstCardActive}>
              <View row style={{ flex: 1, alignItems: 'stretch' }}>
                <TouchableOpacity onPress={() => setSelected('warningSigns')} style={{ flex: 1 }}>
                  <View flex={1} spacing={8}>
                    <Heading text={t('myplan_form.warning_signs.card_title')} />
                    {nextSection === 'warningSigns' ? (
                      <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Button
                          text={t('myplan_form.continue_button')}
                          onPress={() => setSelected('warningSigns')}
                          alignSelf="center"
                          variant="solid"
                          testID="MyPlanForm_startWarningSigns"
                        />
                      </View>
                    ) : (
                      <CheckboxAnswerList
                        questions={getPersonalizedWarningSigns(
                          SuicidalModeQuestions,
                          persistedResponses,
                        )}
                        answers={data.warningSigns}
                      />
                    )}
                  </View>
                </TouchableOpacity>
                <View
                  style={{
                    width: 2,
                    height: '90%',
                    backgroundColor: Color.secondary,
                    marginHorizontal: 16,
                    alignSelf: 'center',
                  }}
                />
                <TouchableOpacity onPress={() => setSelected('selfManagement')} style={{ flex: 1 }}>
                  <View flex={1} spacing={8}>
                    <Heading text={t('myplan_form.self_management.card_title')} />
                    {nextSection === 'selfManagement' ? (
                      <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Button
                          text={t('myplan_form.continue_button')}
                          onPress={() => setSelected('selfManagement')}
                          alignSelf="center"
                          variant="solid"
                          testID="MyPlanForm_startSelfManagement"
                        />
                      </View>
                    ) : (
                      <CheckboxAnswerList
                        color={Color.primary}
                        questions={SelfManagementQuestions}
                        answers={data.selfManagement}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              </View>
            </MyPlanCard>
            <MyPlanCard
              disabled={!isSecondCardActive}
              onPress={APP_SLUG === 'oui-jco' ? () => setSelected('contacts') : undefined}
            >
              <Heading
                text={t('myplan_form.contacts.card_title')}
                style={{ textAlign: 'center' }}
              />
              <View row style={{ justifyContent: 'space-around', flexWrap: 'wrap', flex: 1 }}>
                {nextSection === 'contacts' ? (
                  <View style={{ flex: 1, justifyContent: 'center' }}>
                    <Button
                      text={t('myplan_form.continue_button')}
                      onPress={() => setSelected('contacts')}
                      alignSelf="center"
                      variant="solid"
                    />
                  </View>
                ) : (
                  <>
                    {persistedResponses?.patientContact?.map((c, i) => (
                      <Contact name={c.name} image={c.image?.data ?? c.image?.url} key={i} />
                    ))}
                    {data?.contacts?.names.map((c, i) => (
                      <Contact name={{ first: c, last: '' }} key={i} />
                    ))}
                  </>
                )}
              </View>
            </MyPlanCard>
            <MyPlanCard disabled={!isThirdCardActive}>
              <Heading
                text={t('myplan_form.crisis_services.card_title')}
                style={{ textAlign: 'center' }}
              />
              <View row style={{ justifyContent: 'space-around', flexWrap: 'wrap', flex: 1 }}>
                {(persistedResponses?.patientCrisisService ?? [NSL_CRISIS_SERVICE]).map((c, i) => (
                  <View style={{ alignItems: 'center', flexBasis: '33%' }} key={i}>
                    <Contact
                      name={c.name}
                      image={c.image?.data ?? c.image?.url}
                      imageBorderRadius={false}
                    />
                    {c.phone?.[0] ? <Text text={c.phone?.[0]} weight="semibold" /> : null}
                  </View>
                ))}
              </View>
            </MyPlanCard>
            {APP_SLUG === 'oui-jco' ? (
              <MyPlanCard
                disabled={!isFourthCardActive}
                onPress={() => setSelected('meansSafetyPlan')}
              >
                <Heading
                  text={t('myplan_form.means_safety_plan.card_title')}
                  style={{ textAlign: 'center' }}
                />
                <View row style={{ justifyContent: 'space-around', flexWrap: 'wrap', flex: 1 }}>
                  {nextSection === 'meansSafetyPlan' ? (
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                      <Button
                        text={t('myplan_form.continue_button')}
                        onPress={() => setSelected('meansSafetyPlan')}
                        alignSelf="center"
                        variant="solid"
                      />
                    </View>
                  ) : (
                    <UnorderedList
                      items={getMeansSafetyPlanItems(t, (data.meansSafetyPlan as any) ?? {})}
                      color={Color.primary}
                    />
                  )}
                </View>
              </MyPlanCard>
            ) : null}
          </View>
        )}
      </View>
      <View row style={{ paddingVertical: 16, justifyContent: 'space-between' }}>
        <Button
          text={t('myplan_form.back_button')}
          style={{ borderWidth: 0, opacity: !selected ? 0 : 1 }}
          disabled={!selected}
          onPress={() => {
            props.navigation.goBack();
          }}
        />
        <Button
          text={t('myplan_form.continue_button')}
          testID="MyPlanForm_continue"
          onPress={() => {
            if (selected) {
              if (nextSection === selected) {
                setError('You must make at least one selection to continue.');
                if (scrollViewRef.current) {
                  scrollViewRef.current.scrollToPosition(0, 0);
                }
                return;
              }
              if (error) {
                setError('');
              }
              saveMyPlanResponseDebounced.current.cancel();
              saveMyPlanResponse({
                variables: {
                  patientID,
                  key: selected,
                  value: data[selected],
                  resultKey: `${selected}List`,
                  resultValue: getResultValue(data[selected]),
                },
              }).then(() => {
                setSelected('');
              });
            } else if (nextSection) {
              setSelected(nextSection);
            } else {
              complete();
              props.navigation.push('PatientOnboardingEnd', {
                patientID: props.route.params.patientID,
              });
            }
          }}
        />
      </View>
    </MyStoryMyPlanContainer>
  );
}

export default MyPlanForm;
