import gqlDeprecated from 'graphql-tag';

export * from './__generated__/SaveUserResponse';
export const SaveUserResponseMutation = gqlDeprecated`
  mutation SaveUserResponse($context: String!, $key: String!, $patientID: UserID!, $data: Any!) {
    respondForPatient(patientID: $patientID, context: $context, key: $key, data: $data)
  }
`;

export * from './__generated__/GetUserResponse';
export const GetUserResponseQuery = gqlDeprecated`
  query GetUserResponse($context: String!, $key: String!, $patientID: UserID!) {
    patientResponse(patientID: $patientID, context: $context, key: $key)
  }
`;
