// This file is automatically generated. See gqlcodegen.yml for details
import type * as Types from '@lib/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type LogoutMutationVariables = Types.Exact<{
  deviceID: Types.Scalars['String'];
}>;

export type LogoutMutation = { readonly logout: boolean };

export const LogoutDocument = gql`
  mutation Logout($deviceID: String!) {
    logout(deviceID: $deviceID)
  }
`;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      deviceID: // value for 'deviceID'
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;

export function performLogout(
  client: Apollo.ApolloClient<unknown>,
  variables: LogoutMutationVariables,
  options?: Omit<
    Apollo.MutationOptions<LogoutMutation, LogoutMutationVariables>,
    'mutation' | 'variables'
  >,
) {
  return client.mutate<LogoutMutation, LogoutMutationVariables>({
    mutation: LogoutDocument,
    variables,
    ...options,
  });
}
