export type JcoModule = 'severity' | 'safety' | 'mystorymyplan';

export function getStartingJcoModule(): JcoModule {
  const subdomain = window.location.host.split('.')[0];

  switch (subdomain) {
    case 'cmhc':
    case 'jco-skip':
      return 'mystorymyplan';
    default:
      return 'safety';
  }
}

export function getStartingJcoScreen() {
  return {
    mystorymyplan: 'MyStoryMyPlan',
    safety: 'SafetyDiagnostic',
    severity: 'SeverityDiagnostic',
  }[getStartingJcoModule()];
}
