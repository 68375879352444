/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActionType {
  ACTIVITY_ADD = "ACTIVITY_ADD",
  ACTIVITY_RATE = "ACTIVITY_RATE",
  COPING_CARD_ADD = "COPING_CARD_ADD",
  COPING_CARD_REVIEW = "COPING_CARD_REVIEW",
  HOPE_KIT_ADD = "HOPE_KIT_ADD",
  HOPE_KIT_REVIEW = "HOPE_KIT_REVIEW",
  MY_PLAN_REVIEW = "MY_PLAN_REVIEW",
  RELAX = "RELAX",
  SLEEP_DIARY_ENTRY_MORNING = "SLEEP_DIARY_ENTRY_MORNING",
  SLEEP_DIARY_ENTRY_NIGHT = "SLEEP_DIARY_ENTRY_NIGHT",
  THOUGHT_DIARY_SPOT = "THOUGHT_DIARY_SPOT",
  THOUGHT_DIARY_SWITCH = "THOUGHT_DIARY_SWITCH",
  THOUGHT_DIARY_TEST = "THOUGHT_DIARY_TEST",
}

export enum AddressUse {
  HOME = "HOME",
  OLD = "OLD",
  TEMP = "TEMP",
  WORK = "WORK",
}

export enum CompositionTemplate {
  MYSTORYMYPLAN = "MYSTORYMYPLAN",
  MYSTORYMYPLAN_CLINICAL = "MYSTORYMYPLAN_CLINICAL",
  SCREENER_CSSRS = "SCREENER_CSSRS",
  SCREENER_NUMERIC = "SCREENER_NUMERIC",
  TEST = "TEST",
}

export enum ContactPointSystem {
  EMAIL = "EMAIL",
  FAX = "FAX",
  OTHER = "OTHER",
  PAGER = "PAGER",
  PHONE = "PHONE",
  SMS = "SMS",
  URL = "URL",
}

export enum ContactPointUse {
  HOME = "HOME",
  MOBILE = "MOBILE",
  OLD = "OLD",
  TEMP = "TEMP",
  WORK = "WORK",
}

export enum ContentType {
  ACTIVITY_PLANNING = "ACTIVITY_PLANNING",
  COPING_CARDS = "COPING_CARDS",
  HOPE_KIT = "HOPE_KIT",
  MYPLAN = "MYPLAN",
  MYPLAN_REVIEW = "MYPLAN_REVIEW",
  MYSTORYMYPLAN = "MYSTORYMYPLAN",
  POST_AVIVA = "POST_AVIVA",
  REDUCE_RISK = "REDUCE_RISK",
  RELAXATION = "RELAXATION",
  SLEEP = "SLEEP",
  SPOT_IT = "SPOT_IT",
  STATIC_01 = "STATIC_01",
  STATIC_02 = "STATIC_02",
  STATIC_03 = "STATIC_03",
  STATIC_04 = "STATIC_04",
  STATIC_05 = "STATIC_05",
  STATIC_06 = "STATIC_06",
  STATIC_07 = "STATIC_07",
  STATIC_08 = "STATIC_08",
  STATIC_09 = "STATIC_09",
  STATIC_10 = "STATIC_10",
  STATIC_11 = "STATIC_11",
  STATIC_12 = "STATIC_12",
  SWITCH_IT = "SWITCH_IT",
  TEST = "TEST",
  TEST_IT = "TEST_IT",
  V1_SESSION_01 = "V1_SESSION_01",
  V1_SESSION_02 = "V1_SESSION_02",
  V1_SESSION_03 = "V1_SESSION_03",
  V1_SESSION_04 = "V1_SESSION_04",
  V1_SESSION_05 = "V1_SESSION_05",
  V1_SESSION_06 = "V1_SESSION_06",
  V1_SESSION_06P = "V1_SESSION_06P",
  V1_SESSION_07 = "V1_SESSION_07",
  V1_SESSION_07P = "V1_SESSION_07P",
  V1_SESSION_08 = "V1_SESSION_08",
  V1_SESSION_08P = "V1_SESSION_08P",
  V1_SESSION_09 = "V1_SESSION_09",
  V1_SESSION_09P = "V1_SESSION_09P",
  V1_SESSION_10 = "V1_SESSION_10",
  V1_SESSION_10P = "V1_SESSION_10P",
  V1_WELCOME = "V1_WELCOME",
}

export enum OnboardingVariant {
  COLLABORATIVE = "COLLABORATIVE",
  INDEPENDENT = "INDEPENDENT",
}

export enum OuiUserRoleType {
  PATIENT = "PATIENT",
  PRACTITIONER = "PRACTITIONER",
  REGISTRAR = "REGISTRAR",
  UNKNOWN = "UNKNOWN",
}

export enum PatientGenderIdentity {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NON_BINARY = "NON_BINARY",
  NON_DISCLOSE = "NON_DISCLOSE",
  TRANSGENDER_FEMALE = "TRANSGENDER_FEMALE",
  TRANSGENDER_MALE = "TRANSGENDER_MALE",
  UNKNOWN = "UNKNOWN",
}

export enum PatientMilitaryStatus {
  ACTIVE_MILITARY_DUTY = "ACTIVE_MILITARY_DUTY",
  CIVILIAN = "CIVILIAN",
  DISABLED_VETERAN = "DISABLED_VETERAN",
  FAMILY_MEMBER_OF_ACTIVE_DUTY = "FAMILY_MEMBER_OF_ACTIVE_DUTY",
  FAMILY_MEMBER_OF_RETIRED_MILITARY = "FAMILY_MEMBER_OF_RETIRED_MILITARY",
  INACTIVE_READY_RESERVE = "INACTIVE_READY_RESERVE",
  NATIONAL_GUARD = "NATIONAL_GUARD",
  NON_DISCLOSE = "NON_DISCLOSE",
  RESERVES = "RESERVES",
  RETIRED_MILITARY = "RETIRED_MILITARY",
  UNKNOWN = "UNKNOWN",
  VETERAN = "VETERAN",
}

export enum PatientRace {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "AMERICAN_INDIAN_OR_ALASKA_NATIVE",
  ASIAN = "ASIAN",
  BLACK_OR_AFRICAN_AMERICAN = "BLACK_OR_AFRICAN_AMERICAN",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER",
  NON_DISCLOSE = "NON_DISCLOSE",
  UNKNOWN = "UNKNOWN",
  WHITE = "WHITE",
}

export enum PatientSexualOrientation {
  BISEXUAL = "BISEXUAL",
  HETEROSEXUAL = "HETEROSEXUAL",
  HOMOSEXUAL = "HOMOSEXUAL",
  NON_DISCLOSE = "NON_DISCLOSE",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

export enum PracticeType {
  ACTIVITY = "ACTIVITY",
  SLEEP_DIARY_ENTRY = "SLEEP_DIARY_ENTRY",
  THOUGHT_DIARY_ENTRY = "THOUGHT_DIARY_ENTRY",
}

export enum PractitionerRoleType {
  BEHAVIOR_THERAPIST = "BEHAVIOR_THERAPIST",
  BEHAVIOR_THERAPIST_ASSISTANT = "BEHAVIOR_THERAPIST_ASSISTANT",
  DOCTOR = "DOCTOR",
  EDUCATOR = "EDUCATOR",
  ICT_PROFESSIONAL = "ICT_PROFESSIONAL",
  NURSE = "NURSE",
  PHARMACIST = "PHARMACIST",
  PSYCHOTHERAPIST = "PSYCHOTHERAPIST",
  RESEARCHER = "RESEARCHER",
}

export enum ProductVariant {
  AVIVA_ADOLESCENT = "AVIVA_ADOLESCENT",
  AVIVA_ADULT = "AVIVA_ADULT",
  AVIVA_STATIC = "AVIVA_STATIC",
}

export enum ProductVersion {
  AVIVA_1_0 = "AVIVA_1_0",
  AVIVA_1_1 = "AVIVA_1_1",
  AVIVA_2_0 = "AVIVA_2_0",
}

export enum RatingDuration {
  ALL = "ALL",
  DAY = "DAY",
  MONTH = "MONTH",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

export enum RatingType {
  QUALITY = "QUALITY",
  RATING = "RATING",
  RATING_AFTER = "RATING_AFTER",
  RATING_BEFORE = "RATING_BEFORE",
}

export enum SleepDiaryEntryDuration {
  DURATION_120_TO_180 = "DURATION_120_TO_180",
  DURATION_15_TO_30 = "DURATION_15_TO_30",
  DURATION_1_TO_5 = "DURATION_1_TO_5",
  DURATION_20_TO_45 = "DURATION_20_TO_45",
  DURATION_30_TO_60 = "DURATION_30_TO_60",
  DURATION_45_TO_60 = "DURATION_45_TO_60",
  DURATION_5_TO_10 = "DURATION_5_TO_10",
  DURATION_60_TO_120 = "DURATION_60_TO_120",
  DURATION_LESS_THAN_15 = "DURATION_LESS_THAN_15",
  DURATION_MORE_THAN_120 = "DURATION_MORE_THAN_120",
  DURATION_MORE_THAN_180 = "DURATION_MORE_THAN_180",
  DURATION_MORE_THAN_60 = "DURATION_MORE_THAN_60",
}

export enum SleepDiaryEntryTimeOfDay {
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
  MORNING = "MORNING",
  NIGHT = "NIGHT",
}

export enum UploadType {
  AUDIO = "AUDIO",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum UserGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  NOANSWER = "NOANSWER",
  OTHER = "OTHER",
}

export enum UserRace {
  ASIAN = "ASIAN",
  BLACK = "BLACK",
  NATIVE = "NATIVE",
  NOANSWER = "NOANSWER",
  OTHER = "OTHER",
  PACIFIC = "PACIFIC",
  WHITE = "WHITE",
}

export enum UserSexuality {
  BISEXUAL = "BISEXUAL",
  HETEROSEXUAL = "HETEROSEXUAL",
  HOMOSEXUAL = "HOMOSEXUAL",
  NOANSWER = "NOANSWER",
}

export interface ActionValuesInput {
  patientID: GQLUUID;
}

export interface ActivityInput {
  attendee?: string | null;
  endTime: GQLDateTime;
  location?: string | null;
  notes?: string | null;
  startTime: GQLDateTime;
  title: string;
}

export interface AddActionInput {
  actionType: ActionType;
  actionValues: ActionValuesInput;
  practiceID?: GQLUUID | null;
}

export interface AddActivityPracticeInput {
  activity: ActivityInput;
  practiceValues: PracticeValuesInput;
}

export interface AddHopeKitImageInput {
  hopeKitImage: HopeKitImageInput;
  uploadContentType: string;
}

export interface AddHopeKitQuoteInput {
  hopeKitQuote: HopeKitQuoteInput;
}

export interface AddHopeKitVideoInput {
  hopeKitVideo: HopeKitVideoInput;
  uploadContentType: string;
}

export interface AddSleepDiaryEntryPracticeInput {
  practiceValues: PracticeValuesInput;
  sleepDiaryEntry: SleepDiaryEntryInput;
}

export interface AddThoughtDiaryEntryPracticeInput {
  practiceValues: PracticeValuesInput;
  thoughtDiaryEntry: ThoughtDiaryEntryInput;
}

export interface AddressInput {
  city?: string | null;
  line?: string[] | null;
  postalCode?: string | null;
  rank: number;
  state?: string | null;
  use: AddressUse;
}

export interface ContactPointInput {
  rank: number;
  system: ContactPointSystem;
  use: ContactPointUse;
  value: string;
}

export interface HopeKitImageInput {
  reason?: string | null;
}

export interface HopeKitQuoteInput {
  author?: string | null;
  reason?: string | null;
  text: string;
}

export interface HopeKitVideoInput {
  reason?: string | null;
}

export interface OrganizationInput {
  address: AddressInput[];
  contactPoint: ContactPointInput[];
  name: string;
  onboardingVariant: OnboardingVariant;
  parent?: GQLUUID | null;
  productVariant: ProductVariant;
  productVersion: ProductVersion;
}

export interface OrganizationUpdateInput {
  address: AddressInput[];
  contactPoint: ContactPointInput[];
  name?: string | null;
  onboardingVariant?: OnboardingVariant | null;
  productVariant?: ProductVariant | null;
  productVersion?: ProductVersion | null;
}

export interface PatientInput {
  birthDate?: GQLDate | null;
  genderIdentity?: PatientGenderIdentity | null;
  militaryStatus?: PatientMilitaryStatus | null;
  onboardingVariant?: OnboardingVariant | null;
  person: PersonInput;
  productVariant?: ProductVariant | null;
  productVersion?: ProductVersion | null;
  race?: PatientRace | null;
  sexualOrientation?: PatientSexualOrientation | null;
}

export interface PatientUpdateInput {
  birthDate?: GQLDate | null;
  genderIdentity?: PatientGenderIdentity | null;
  militaryStatus?: PatientMilitaryStatus | null;
  onboardingVariant?: OnboardingVariant | null;
  person?: PersonUpdateInput | null;
  productVariant?: ProductVariant | null;
  productVersion?: ProductVersion | null;
  race?: PatientRace | null;
  sexualOrientation?: PatientSexualOrientation | null;
}

export interface PersonInput {
  contactPoint?: ContactPointInput[] | null;
  email?: string | null;
  familyName: string;
  givenName: string;
}

export interface PersonUpdateInput {
  contactPoint?: ContactPointInput[] | null;
  email?: string | null;
  familyName?: string | null;
  givenName?: string | null;
}

export interface PracticeRatingInput {
  type: RatingType;
  value: number;
}

export interface PracticeValuesInput {
  date?: GQLDate | null;
  patientID: GQLUUID;
  ratings: PracticeRatingInput[];
}

export interface PractitionerInput {
  npi: number;
  person: PersonInput;
  role: PractitionerRoleType;
}

export interface PractitionerUpdateInput {
  npi?: number | null;
  person?: PersonUpdateInput | null;
  role?: PractitionerRoleType | null;
}

export interface RegistrarInput {
  person: PersonInput;
}

export interface RemoveHopeKitItemInput {
  hopeKitItemID: GQLUUID;
}

export interface SleepDiaryEntryInfluencerInput {
  count?: number | null;
  duration?: SleepDiaryEntryDuration | null;
  kinds?: string[] | null;
  occurred: boolean;
  timesOfDay: SleepDiaryEntryTimeOfDay[];
}

export interface SleepDiaryEntryInput {
  alcohol?: SleepDiaryEntryInfluencerInput | null;
  caffeine?: SleepDiaryEntryInfluencerInput | null;
  deviceInBed?: SleepDiaryEntryInfluencerInput | null;
  endTime: GQLDateTime;
  exercise?: SleepDiaryEntryInfluencerInput | null;
  medicine?: SleepDiaryEntryInfluencerInput | null;
  napping?: SleepDiaryEntryInfluencerInput | null;
  sleepDelay?: SleepDiaryEntryDuration | null;
  startTime: GQLDateTime;
  tobacco?: SleepDiaryEntryInfluencerInput | null;
  wakeupCount: number;
  wakeupDuration?: SleepDiaryEntryDuration | null;
}

export interface TextItemInput {
  ID: GQLUUID;
  text: string;
}

export interface ThoughtDiaryEntryInput {
  behavior: string;
  event: string;
  evidenceAgainst: TextItemInput[];
  evidenceFor: TextItemInput[];
  feelingsAfter: TextItemInput[];
  feelingsBefore: TextItemInput[];
  thoughtAfter: string;
  thoughtBefore: string;
}

export interface UpdateActivityPracticeInput {
  activity: ActivityInput;
  practiceID: GQLUUID;
  practiceValues: PracticeValuesInput;
}

export interface UpdateHopeKitImageInput {
  hopeKitItemID: GQLUUID;
  reason?: string | null;
}

export interface UpdateHopeKitQuoteInput {
  author?: string | null;
  hopeKitItemID: GQLUUID;
  reason?: string | null;
  text: string;
}

export interface UpdateHopeKitVideoInput {
  hopeKitItemID: GQLUUID;
  reason?: string | null;
}

export interface UpdateSleepDiaryEntryPracticeInput {
  practiceID: GQLUUID;
  practiceValues: PracticeValuesInput;
  sleepDiaryEntry: SleepDiaryEntryInput;
}

export interface UpdateThoughtDiaryEntryPracticeInput {
  practiceID: GQLUUID;
  practiceValues: PracticeValuesInput;
  thoughtDiaryEntry: ThoughtDiaryEntryInput;
}

export interface UserAddressInput {
  city: string;
  country: string;
  line1: string;
  line2: string;
  state?: string | null;
  zip?: string | null;
}

export interface UserDemoInput {
  DOB?: GQLDate | null;
  gender?: UserGender | null;
  isVeteran?: boolean | null;
  race?: UserRace | null;
  sexuality?: UserSexuality | null;
}

export interface UserNameInput {
  first: string;
  last: string;
  pfx?: string | null;
  preferred?: string | null;
  sfx?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
