import React from 'react';

import { View } from '@src/components/View';
import { Color } from '@src/styles';
import { Text } from '@src/components/Text';
import { Button } from '@src/components/Button';
import MyStoryMyPlanContainer from '@src/components/MyStoryMyPlanContainer';
import { useSetMyStoryMyPlanStepComplete } from '@src/hooks/useSetMyStoryMyPlanStepComplete';
import { useT } from '@src/lib/i18n';
import { JcoMyStoryMyPlanScreenProps } from '@src/types';

function StoryGuide() {
  const t = useT();
  return (
    <View childFlex={1} row spacing={20} style={{ alignItems: 'stretch' }}>
      <View
        spacing={4}
        style={{
          borderRadius: 20,
          padding: 20,
          borderWidth: 2,
          borderColor: Color.styleGuide.Gray6,
        }}
      >
        <Text
          weight="bold"
          text={t('mystory_form.hints.story_flow_heading')}
          style={{ marginBottom: 12 }}
        />
        <Text text={`\u2022 ${t('mystory_form.hints.story_flow_hint1')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.story_flow_hint2')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.story_flow_hint3')}`} />
      </View>
      <View
        spacing={4}
        style={{
          borderRadius: 20,
          padding: 20,
          borderWidth: 2,
          borderColor: Color.styleGuide.Gray6,
        }}
      >
        <Text
          weight="bold"
          text={t('mystory_form.hints.internal_heading')}
          style={{ marginBottom: 12 }}
        />
        <Text text={`\u2022 ${t('mystory_form.hints.internal_hint1')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.internal_hint2')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.internal_hint3')}`} />
      </View>
      <View
        spacing={4}
        style={{
          borderRadius: 20,
          padding: 20,
          borderWidth: 2,
          borderColor: Color.styleGuide.Gray6,
        }}
      >
        <Text
          weight="bold"
          text={t('mystory_form.hints.external_heading')}
          style={{ marginBottom: 12 }}
        />
        <Text text={`\u2022 ${t('mystory_form.hints.external_hint1')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.external_hint2')}`} />
        <Text text={`\u2022 ${t('mystory_form.hints.external_hint3')}`} />
      </View>
    </View>
  );
}

export default function PatientNarrativeForm(
  props: JcoMyStoryMyPlanScreenProps<'PatientNarrativeForm'>,
) {
  const t = useT();
  const complete = useSetMyStoryMyPlanStepComplete('PatientNarrativeFormComplete');
  return (
    <MyStoryMyPlanContainer
      progress={2}
      heading={t('mystory_form.no_recording.tell_story_heading')}
      subheading={t('mystory_form.no_recording.tell_story_description')}
    >
      <View spacing={30} style={{ flex: 1 }}>
        <StoryGuide />
        <Button
          onPress={() => {
            complete();
            props.navigation.navigate('SuicidalModeExplanation', props.route.params);
          }}
          variant="solid"
          text={t('mystory_form.continue_button')}
          alignSelf="center"
          testID="PatientNarrativeForm_continue"
        />
      </View>
    </MyStoryMyPlanContainer>
  );
}
