import { useQuery, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native';
import { useRoute, useNavigation } from '@react-navigation/native';
import noop from 'lodash/noop';

import {
  GetUserResponseVariables,
  GetUserResponseQuery,
  SaveUserResponseMutation,
  SaveUserResponseVariables,
} from '@src/lib/userResponse';
import { WebPicker } from '@src/components/WebPicker';
import { Button } from '@src/components/Button';
import { Text } from '@src/components/Text';
import { Icon } from '@src/components/Icon';
import { ScrollView } from '@src/components/ScrollView';
import { View } from '@src/components/View';
import {
  Schema,
  getTextSummaryForForm,
  useRenderForm,
  FormAnswers,
} from '@src/components/SchemaForm';
import { Shadow, card, Color } from '@src/styles';
import { JcoStackScreenProps } from '@src/types';
import { useT, TranslationFunction } from '@src/lib/i18n';

const SAFETY_DIAGNOSTICS = [
  { label: 'ASQ', value: 'asq' },
  { label: 'PHQ-9 (Coming soon)', value: 'phq9', isDisabled: true },
  { label: 'C-SSRS (Coming soon)', value: 'cssrs', isDisabled: true },
  { label: 'SBQ-R (Coming soon)', value: 'sbqr', isDisabled: true },
];

const SEVERITY_DIAGNOSTICS = [
  { label: 'C-SSRS', value: 'cssrs' },
  { label: 'SSI-W (Coming soon)', value: 'ssiw', isDisabled: true },
  { label: 'BSI (Coming soon)', value: 'bsi', isDisabled: true },
];

function getASQSchema(t: TranslationFunction, data: FormAnswers) {
  const schema: Schema = {
    desire: {
      type: 'yesno',
      label: t('safety_diagnostic.asq.desire'),
    },
    family_better_off: {
      type: 'yesno',
      label: t('safety_diagnostic.asq.family_better_off'),
    },
    suicidal_thoughts: {
      type: 'yesno',
      label: t('safety_diagnostic.asq.suicidal_thoughts'),
    },
    suicide_attempt: {
      type: 'yesno',
      label: t('safety_diagnostic.asq.suicide_attempt'),
      followups: {
        true: [
          {
            type: 'text',
            sublabel: t('safety_diagnostic.asq.suicide_attempt_how'),
          },
          {
            type: 'text',
            sublabel: t('safety_diagnostic.asq.suicide_attempt_when'),
          },
        ],
      },
    },
  };
  if (Object.values(data).some((v) => v[0].value?.[0])) {
    schema['current_suicidal_thoughts'] = {
      type: 'yesno',
      label: t('safety_diagnostic.asq.current_suicidal_thoughts'),
      followups: {
        true: [
          {
            type: 'text',
            sublabel: t('safety_diagnostic.asq.current_suicidal_thoughts_describe'),
          },
        ],
      },
    };
  }
  return schema;
}

function ASQ({ t, defaultData }: { t: TranslationFunction; defaultData?: FormAnswers }) {
  const { data: formData, form } = useRenderForm((d) => getASQSchema(t, d), defaultData);

  const allAnswered =
    Object.values(formData).length > 3 &&
    Object.values(formData).every((v) => typeof v[0].value?.[0] === 'boolean');
  return {
    data: formData,
    form,
    result: allAnswered ? Object.values(formData).some((v) => v[0].value?.[0]) : undefined,
  };
}

export function getCSSRSSchema(t: TranslationFunction) {
  const RECENCY_FOLLOWUPS = [
    {
      type: 'singlechoice' as const,
      ui: { horizontal: true },
      choices: [
        { label: t('safety_diagnostic.cssrs.self_harm.recent'), value: 'recent' },
        { label: t('safety_diagnostic.cssrs.self_harm.not_recent'), value: 'not_recent' },
      ],
    },
    {
      type: 'text' as const,
      placeholder: t('safety_diagnostic.cssrs.self_harm.describe_behavior'),
    },
  ];
  const schema: Schema = {
    self_harm: {
      type: 'multichoice',
      label: t('safety_diagnostic.cssrs.self_harm.label'),
      sublabel: t('safety_diagnostic.cssrs.self_harm.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.self_harm.choices.suicide_attempt'),
          value: 'suicide_attempt',
        },
        {
          label: t('safety_diagnostic.cssrs.self_harm.choices.interrupted_attempt'),
          value: 'interrupted_attempt',
        },
        {
          label: t('safety_diagnostic.cssrs.self_harm.choices.aborted_attempt'),
          value: 'aborted_attempt',
        },
        {
          label: t('safety_diagnostic.cssrs.self_harm.choices.preparatory_acts'),
          value: 'preparatory_acts',
        },
        { label: t('safety_diagnostic.cssrs.self_harm.choices.self_harm'), value: 'self_harm' },
      ],
      followups: {
        suicide_attempt: RECENCY_FOLLOWUPS,
        interrupted_attempt: RECENCY_FOLLOWUPS,
        aborted_attempt: RECENCY_FOLLOWUPS,
        preparatory_acts: RECENCY_FOLLOWUPS,
        self_harm: RECENCY_FOLLOWUPS,
      },
    },
    suicidal_thoughts: {
      type: 'singlechoice',
      label: t('safety_diagnostic.cssrs.suicidal_thoughts.label'),
      sublabel: t('safety_diagnostic.cssrs.suicidal_thoughts.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.suicidal_thoughts.choices.desire'),
          value: 'WISH_TO_BE_DEAD',
        },
        {
          label: t('safety_diagnostic.cssrs.suicidal_thoughts.choices.thoughts'),
          value: 'GENERAL_SUICIDAL_IDEATION',
        },
        {
          label: t('safety_diagnostic.cssrs.suicidal_thoughts.choices.thoughts_with_method'),
          value: 'IDEATION_WITH_METHOD_WITHOUT_INTENT',
        },
        {
          label: t('safety_diagnostic.cssrs.suicidal_thoughts.choices.intent_without_plan'),
          value: 'IDEATION_WITH_INTENT_WITHOUT_PLAN',
        },
        {
          label: t('safety_diagnostic.cssrs.suicidal_thoughts.choices.intent_with_plan'),
          value: 'IDEATION_WITH_PLAN_WITH_INTENT',
        },
      ],
    },
    activating_events: {
      type: 'multichoice',
      label: t('safety_diagnostic.cssrs.activating_events.label'),
      sublabel: t('safety_diagnostic.cssrs.activating_events.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.activating_events.choices.negative_events'),
          value: 'negative_events',
        },
        {
          label: t(
            'safety_diagnostic.cssrs.activating_events.choices.incarceration_or_homelessness',
          ),
          value: 'incarceration_or_homelessness',
        },
        {
          label: t('safety_diagnostic.cssrs.activating_events.choices.loneliness'),
          value: 'loneliness',
        },
      ],
      followups: {
        negative_events: [
          {
            type: 'text',
            placeholder: t('safety_diagnostic.cssrs.activating_events.negative_events_description'),
          },
        ],
      },
    },
    treatment_history: {
      type: 'multichoice',
      label: t('safety_diagnostic.cssrs.treatment_history.label'),
      sublabel: t('safety_diagnostic.cssrs.treatment_history.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.treatment_history.choices.psychiatric'),
          value: 'psychiatric',
        },
        {
          label: t('safety_diagnostic.cssrs.treatment_history.choices.dissatisfied'),
          value: 'dissatisfied',
        },
        {
          label: t('safety_diagnostic.cssrs.treatment_history.choices.non_compliant'),
          value: 'non_compliant',
        },
        {
          label: t('safety_diagnostic.cssrs.treatment_history.choices.no_treatment'),
          value: 'no_treatment',
        },
      ],
      followups: {
        psychiatric: [
          {
            type: 'text',
            placeholder: t('safety_diagnostic.cssrs.treatment_history.psychiatric_followup'),
          },
        ],
      },
    },
    other_risk_factors: {
      type: 'text',
      label: t('safety_diagnostic.cssrs.other_risk_factors.label'),
      other: true,
    },
    clinical_status: {
      type: 'multichoice',
      label: t('safety_diagnostic.cssrs.clinical_status.label'),
      sublabel: t('safety_diagnostic.cssrs.clinical_status.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.hopelessness'),
          value: 'hopelessness',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.depressive_episode'),
          value: 'depressive_episode',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.affective_episode'),
          value: 'affective_episode',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.hallucinations'),
          value: 'hallucinations',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.impulsive_behavior'),
          value: 'impulsive_behavior',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.substance_abuse'),
          value: 'substance_abuse',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.anxiety'),
          value: 'anxiety',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.perceived_burden'),
          value: 'perceived_burden',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.acute_medical_problem'),
          value: 'acute_medical_problem',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.homicidal'),
          value: 'homicidal',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.aggressive'),
          value: 'aggressive',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.suicide_method_available'),
          value: 'suicide_method_available',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.refuse_safety_plan'),
          value: 'refuse_safety_plan',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.sexual_abuse'),
          value: 'sexual_abuse',
        },
        {
          label: t('safety_diagnostic.cssrs.clinical_status.choices.family_history_of_suicide'),
          value: 'family_history_of_suicide',
        },
      ],
    },
    protective_factors: {
      type: 'multichoice',
      label: t('safety_diagnostic.cssrs.protective_factors.label'),
      sublabel: t('safety_diagnostic.cssrs.protective_factors.description'),
      choices: [
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.reasons_for_living'),
          value: 'reasons_for_living',
        },
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.family_responsibility'),
          value: 'family_responsibility',
        },
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.support_network'),
          value: 'support_network',
        },
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.fear_of_death'),
          value: 'fear_of_death',
        },
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.immoral_belief'),
          value: 'immoral_belief',
        },
        {
          label: t('safety_diagnostic.cssrs.protective_factors.choices.engaged_in_activity'),
          value: 'engaged_in_activity',
        },
      ],
    },
    other_protective_factors: {
      type: 'text',
      label: t('safety_diagnostic.cssrs.other_protective_factors.label'),
      other: true,
    },
  };
  return schema;
}

function CSSRS({ t, defaultData }: { t: TranslationFunction; defaultData?: FormAnswers }) {
  const { data, form } = useRenderForm(() => getCSSRSSchema(t), defaultData);

  const result = Object.entries(data).some(([key, answer]) => {
    return (
      !['protective_factors', 'other_protective_factors'].includes(key) &&
      (answer?.[0]?.value?.length ?? 0 > 0)
    );
  });

  return { data, form, result };
}

export function safetyDiagnosticText(
  t: TranslationFunction,
  diagnostic: 'asq' | 'cssrs',
  data: FormAnswers,
) {
  const diagnostics = [...SAFETY_DIAGNOSTICS, ...SEVERITY_DIAGNOSTICS];
  const diagnosticLabel =
    diagnostics.find((d) => d.value === diagnostic && !d.isDisabled)?.label ?? '';
  return (
    `${diagnosticLabel}\n\n` +
    getTextSummaryForForm(t, diagnostic === 'asq' ? getASQSchema(t, data) : getCSSRSSchema(t), data)
  ).trim();
}

type NavigationProps = JcoStackScreenProps<'SafetyDiagnostic' | 'SeverityDiagnostic'>;

export default function SafetyDiagnostic({
  type,
  printRef,
}: {
  type?: 'safety' | 'severity';
  printRef: React.MutableRefObject<any>;
}) {
  const { navigate } = useNavigation<NavigationProps['navigation']>();
  const { name, params } = useRoute<NavigationProps['route']>();
  const patientID = params?.patientID;
  const isSafetyDiagnostic = type ? type === 'safety' : name === 'SafetyDiagnostic';
  const diagnostics = isSafetyDiagnostic ? SAFETY_DIAGNOSTICS : SEVERITY_DIAGNOSTICS;
  const [diagnostic, setDiagnostic] = useState(diagnostics[0].value);
  const t = useT();

  const { data: persistedData } = useQuery<
    { patientResponse: FormAnswers },
    GetUserResponseVariables
  >(GetUserResponseQuery, {
    variables: {
      patientID,
      context: 'caps',
      key: `diagnostic_${diagnostic}`,
    },
  });
  const defaultData = persistedData?.patientResponse;

  const { data, form, result } =
    diagnostic === 'asq' ? ASQ({ t, defaultData }) : CSSRS({ t, defaultData });

  const [startScreening] = useMutation<void, SaveUserResponseVariables>(SaveUserResponseMutation);
  useEffect(() => {
    startScreening({
      fetchPolicy: 'no-cache',
      variables: {
        patientID,
        context: 'caps',
        key: `diagnostic_${diagnostic}`,
        data,
      },
    });
  }, [data, diagnostic, patientID, startScreening]);

  return (
    <SafeAreaView
      style={{ flex: 1 }}
      testID={isSafetyDiagnostic ? 'SafetyDiagnostic' : 'SeverityDiagnostic'}
    >
      <ScrollView testID="Diagnostic_scrollView">
        <View style={{ backgroundColor: 'white', padding: 12, zIndex: 1 }} row spacing={12}>
          <Text text="Diagnostic" weight="semibold" />
          <View style={{ width: 200 }}>
            <WebPicker items={diagnostics} value={diagnostic} onChangeValue={setDiagnostic} />
          </View>
        </View>
        <View spacing={20} style={{ alignItems: 'center', padding: 40 }}>
          <View
            style={[
              card,
              {
                width: 800,
                alignSelf: 'center',
                justifyContent: 'space-between',
                borderRadius: 20,
                overflow: 'hidden',
              },
            ]}
            ref={(r) => {
              if (printRef) printRef.current = r;
            }}
            testID={`Diagnostic_${diagnostic}`}
          >
            <View style={{ backgroundColor: Color.styleGuide.Gray4, padding: 20 }}>
              <Text
                text={
                  diagnostics.find((d) => d.value === diagnostic)?.label || 'Unknown diagnostic'
                }
                size={24}
                weight="semibold"
                textAlign="center"
                color="white"
              />
            </View>
            <View style={[{ width: 800, alignSelf: 'center', padding: 20 }]} spacing={24}>
              {form}
            </View>
            <View style={{ backgroundColor: Color.styleGuide.Gray4, padding: 30 }} spacing={30}>
              <>
                <Text
                  text={t('safety_diagnostic.recommendation')}
                  size={24}
                  weight="semibold"
                  textAlign="center"
                  color="white"
                />
              </>
              {result === true ? (
                <View style={[card, { padding: 20, alignItems: 'center' }]} spacing={20}>
                  <View spacing={5} style={{ alignItems: 'center' }}>
                    <Text
                      text={
                        isSafetyDiagnostic
                          ? t('safety_diagnostic.next_step_description')
                          : t('severity_diagnostic.next_step_description')
                      }
                      weight="semibold"
                      textAlign="center"
                    />
                    <View row spacing={8}>
                      <Icon name="clock" color={Color.styleGuide.Gray5} />
                      <Text
                        text={t('safety_diagnostic.duration_in_minutes', {
                          numMinutes: isSafetyDiagnostic ? 10 : 30,
                        })}
                      />
                    </View>
                  </View>
                  <Button
                    testID="Diagnostic_continue"
                    text={
                      isSafetyDiagnostic
                        ? t('safety_diagnostic.continue_button')
                        : t('severity_diagnostic.continue_button')
                    }
                    onPress={() => {
                      if (isSafetyDiagnostic) {
                        navigate('SeverityDiagnostic', params);
                      } else {
                        navigate('MyStoryMyPlan', { screen: 'MyStoryMyPlanOverview', params });
                      }
                    }}
                    iconRight="arrow-right"
                    variant="solid"
                  />
                </View>
              ) : result === false ? (
                <Button
                  testID="Diagnostic_finish"
                  text={t('safety_diagnostic.no_risk')}
                  onPress={() =>
                    navigate('SafetyScreeningResults', {
                      patientID,
                      lastScreen: isSafetyDiagnostic ? 'safety' : 'severity',
                    })
                  }
                  color="rgb(107,107,112)"
                  style={[Shadow.default, { borderColor: 'transparent' }]}
                />
              ) : (
                <Button
                  testID="Diagnostic_pending"
                  text={t('safety_diagnostic.pending_completion')}
                  disabled={true}
                  onPress={noop}
                  color="rgb(107,107,112)"
                  style={[Shadow.default, { borderColor: 'transparent' }]}
                />
              )}
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
