import React, { useCallback, useRef } from 'react';
import { Pressable, StyleSheet, ImageURISource, SafeAreaView } from 'react-native';
import { KeyboardAwareScrollView as ScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useNavigation, useRoute, useFocusEffect } from '@react-navigation/native';

import { useColor } from '@src/styles';
import { APP_SLUG } from '@src/constants';
import { useWindowDimensions } from '@src/hooks/useWindowDimensions';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Button } from '@src/components/Button';
import MediaPlayer, { FullscreenMediaPlayer } from '@src/components/MediaPlayer';
import { useT } from '@src/lib/i18n';

type Props = {
  progress?: number; // deprecated
  heading: string;
  subheading?: string;
  nextText?: string;
  onNext?: () => unknown;
  children: React.ReactNode;
  scrollViewRef?: React.Ref<ScrollView>;
  testID?: string;
};

const MOBILE_BORDER_RADIUS = 30;

const OFFWHITE = 'rgba(255,255,255,0.6)';
export function MobileHeaderStep({
  backgroundColor,
  num,
  active,
  label,
  onPress,
}: {
  backgroundColor?: string;
  onPress?: () => void;
  label: string;
  num: number;
  active: boolean;
}) {
  const { Color } = useColor();
  return (
    <Pressable onPress={onPress} style={{ flex: 1, alignItems: 'center' }}>
      <View
        style={{
          height: 2,
          position: 'absolute',
          top: 34 / 2,
          backgroundColor: OFFWHITE,
          left: num === 1 ? '50%' : 0,
          right: num === 3 ? '50%' : 0,
        }}
      />
      <View
        style={{
          borderWidth: 2,
          borderColor: active ? 'white' : OFFWHITE,
          justifyContent: 'center',
          backgroundColor: active ? 'white' : backgroundColor ?? Color.tertiary,
          alignItems: 'center',
          width: 34,
          height: 34,
          borderRadius: 18,
        }}
      >
        <Text
          text={num.toString()}
          color={active ? backgroundColor ?? Color.tertiary : OFFWHITE}
          weight="bold"
        />
      </View>
      <Text text={label} color={active ? 'white' : OFFWHITE} />
    </Pressable>
  );
}

function MobileHeader() {
  const { navigate } = useNavigation();
  const { Color } = useColor();
  const route = useRoute();

  return (
    <View
      row
      style={{
        paddingTop: 24,
        paddingBottom: 24 + MOBILE_BORDER_RADIUS,
        backgroundColor: Color.tertiary,
      }}
    >
      <MobileHeaderStep
        num={1}
        label="MyStory"
        active={['PatientNarrativeExplanation', 'PatientNarrativeForm'].includes(route.name)}
        onPress={() => navigate('PatientNarrativeExplanation')}
      />
      <MobileHeaderStep
        num={2}
        label="Suicidal mode"
        active={['SuicidalModeExplanation', 'SuicidalModeForm'].includes(route.name)}
        onPress={() => navigate('SuicidalModeExplanation')}
      />
      <MobileHeaderStep
        num={3}
        label="MyPlan"
        active={['MyPlanExplanation', 'MyPlanForm'].includes(route.name)}
        onPress={() => navigate('MyPlanExplanation')}
      />
    </View>
  );
}

export default function MyStoryMyPlanContainer(props: Props) {
  const t = useT();
  const { width } = useWindowDimensions();
  const isMobile = width < 768;
  const { Color } = useColor();
  const route = useRoute();
  const showMobileHeader =
    APP_SLUG === 'oui-aviva' &&
    !['MyStoryMyPlanOverview', 'PatientOnboardingEnd'].includes(route.name);

  return (
    <SafeAreaView style={{ flex: 1 }} testID={props.testID}>
      {showMobileHeader ? (
        <View style={StyleSheet.absoluteFillObject}>
          <View style={{ flex: 1, backgroundColor: Color.tertiary }} />
          <View style={{ flex: 1, backgroundColor: 'transparent' }} />
        </View>
      ) : null}
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          width: isMobile ? '100%' : 650,
          alignSelf: 'center',
        }}
        ref={props.scrollViewRef}
      >
        {showMobileHeader ? <MobileHeader /> : null}
        <View
          spacing={12}
          style={[
            {
              paddingHorizontal: 26,
              paddingVertical: 26,
              flex: 1,
              backgroundColor: 'white',
            },
            showMobileHeader
              ? {
                  borderTopStartRadius: MOBILE_BORDER_RADIUS,
                  borderTopEndRadius: MOBILE_BORDER_RADIUS,
                  marginTop: -MOBILE_BORDER_RADIUS,
                }
              : null,
          ]}
        >
          <Text
            text={props.heading}
            size={isMobile ? 21 : 40}
            style={{ lineHeight: isMobile ? 22 : 55, marginTop: 20, marginBottom: 10 }}
            weight="bold"
          />
          {props.subheading ? (
            <Text
              text={props.subheading}
              size={isMobile ? 17 : 21}
              style={{ lineHeight: isMobile ? 23 : 28 }}
            />
          ) : null}
          {props.children}
          {props.onNext ? (
            <Button
              testID={props.testID ? `${props.testID}_continue` : undefined}
              text={props.nextText || t('mystory_myplan_container.continue_button')}
              iconRight="arrow-right"
              onPress={props.onNext}
              alignSelf="center"
              style={{ minWidth: 100 }}
            />
          ) : null}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export function ExplanationVideos(props: {
  videos: Array<{
    uri: string;
    label: string;
    caption: string;
    posterSource?: ImageURISource | number;
  }>;
}) {
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  const player = useRef<MediaPlayer>(null);
  const player2 = useRef<MediaPlayer>(null);

  useFocusEffect(
    useCallback(() => {
      return () => {
        if (player.current) {
          player.current.setIsPlayingAsync(false);
        }
        if (player2.current) {
          player2.current.setIsPlayingAsync(false);
        }
      };
    }, []),
  );

  return (
    <View
      childFlex={1}
      spacing={isMobile ? 26 : 16}
      row={!isMobile}
      style={{ marginTop: 32, alignItems: 'flex-end' }}
    >
      {props.videos.map((video) => (
        <View spacing={8} key={video.uri || video.caption}>
          <Text
            size={isMobile ? 17 : 21}
            text={video.label}
            weight="bold"
            accessibilityRole="header"
          />
          <Text size={isMobile ? 17 : 21} text={video.caption} />
          <View flex={1} />
          <FullscreenMediaPlayer
            uri={video.uri || undefined!}
            ref={player}
            posterSource={video.posterSource}
          />
        </View>
      ))}
    </View>
  );
}
