import React from 'react';
import {
  Keyboard,
  KeyboardAvoidingView,
  Modal as RNModal,
  Platform,
  ScrollView,
  StatusBar,
  TouchableOpacity,
} from 'react-native';
import hexToRgba from 'hex-to-rgba';

import { Button } from '@src/components/Button';
import { View } from '@src/components/View';
import { Heading } from '@src/components/Text';
import { useColor } from '@src/styles';

export function MandatoryModal(props: { children?: React.ReactNode; visible: boolean }) {
  const { Shadow, Color } = useColor();
  return (
    <RNModal visible={props.visible} onRequestClose={() => {}} transparent>
      <TouchableOpacity
        accessible={false}
        activeOpacity={1}
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: hexToRgba(Color.styleGuide.Gray1, '0.5'),
        }}
        onPress={() => {}}
      >
        {props.visible ? (
          <StatusBar
            backgroundColor={hexToRgba(Color.styleGuide.Gray1, '0.5')}
            barStyle="light-content"
            translucent
          />
        ) : null}
        <TouchableOpacity
          accessible={false}
          activeOpacity={1}
          style={[
            {
              width: '90%',
              maxWidth: 400,
              backgroundColor: Color.backgroundColor,
              marginHorizontal: 10,
              borderRadius: 20,
              paddingVertical: 26,
              paddingHorizontal: 12,
            },
            Shadow.high,
          ]}
        >
          {props.children}
        </TouchableOpacity>
      </TouchableOpacity>
    </RNModal>
  );
}

export function Modal(props: {
  _onBack?: () => void;
  buttonText?: string;
  children: React.ReactNode;
  heading?: string;
  maxHeight?: number;
  maxWidth?: number | string;
  onPressButton?: () => void;
  onRequestClose: () => void;
  visible: boolean;
}) {
  const { Color } = useColor();

  const inner = (
    <TouchableOpacity
      accessible={false}
      activeOpacity={1}
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: hexToRgba(Color.styleGuide.Gray1, '0.5'),
      }}
      onPress={props.onRequestClose}
    >
      {props.visible ? (
        <StatusBar
          backgroundColor={hexToRgba(Color.styleGuide.Gray1, '0.5')}
          barStyle="light-content"
          translucent
        />
      ) : null}
      <TouchableOpacity
        accessible={false}
        activeOpacity={1}
        style={{
          width: '90%',
          maxWidth: props.maxWidth ?? 400,
          backgroundColor: Color.backgroundColor,
          marginHorizontal: 10,
        }}
      >
        <View
          row
          style={{
            justifyContent: 'space-between',
            paddingVertical: 12,
            paddingHorizontal: 20,
            borderBottomWidth: 1,
            borderColor: Color.styleGuide.Gray6,
          }}
        >
          {props._onBack ? (
            <Button
              icon="arrow-left"
              variant="text"
              onPress={props._onBack}
              accessibilityLabel="Go back"
              style={{ marginRight: 8 }}
            />
          ) : null}
          {props.heading ? <Heading text={props.heading} style={{ marginBottom: 0 }} /> : null}
          <Button
            icon="close"
            variant="text"
            onPress={props.onRequestClose}
            accessibilityLabel="Close modal"
          />
        </View>
        {props.maxHeight ? (
          <ScrollView style={{ maxHeight: props.maxHeight }}>
            <View
              style={{ padding: 20, zIndex: 1 }}
              onStartShouldSetResponder={() => {
                Keyboard.dismiss();
                return true;
              }}
            >
              {props.children}
            </View>
          </ScrollView>
        ) : (
          <View
            style={{ padding: 20, zIndex: 1 }}
            onStartShouldSetResponder={() => {
              Keyboard.dismiss();
              return true;
            }}
          >
            {props.children}
          </View>
        )}
        {props.buttonText && props.onPressButton ? (
          <View
            row
            style={{
              justifyContent: 'center',
              paddingVertical: 12,
              paddingHorizontal: 20,
              borderTopWidth: 1,
              borderColor: Color.styleGuide.Gray6,
            }}
          >
            <Button text={props.buttonText} onPress={props.onPressButton} />
          </View>
        ) : null}
      </TouchableOpacity>
    </TouchableOpacity>
  );

  return (
    <RNModal visible={props.visible} transparent>
      {Platform.OS === 'android' ? (
        inner
      ) : (
        <KeyboardAvoidingView
          style={{ flex: 1 }}
          behavior="padding"
          onAccessibilityEscape={props.onRequestClose}
        >
          {inner}
        </KeyboardAvoidingView>
      )}
    </RNModal>
  );
}
