import { ApolloClient } from '@apollo/client';
import Constants from 'expo-constants';
import { Platform } from 'react-native';

import * as Notifications from '@src/lib/notifications';
import { getClaimsSync } from '@src/lib/auth';
import { performSetPushToken } from '@src/lib/setDeviceInfo.graphql.generated';

export async function setDeviceInfo(client: ApolloClient<unknown>) {
  const { status } =
    Platform.OS === 'web' || !Constants.isDevice
      ? { status: 'denied' } // currently don't support notifications on web
      : await Notifications.getPermissionsAsync();

  let expoPushToken: Notifications.ExpoPushToken | undefined;
  let platformPushToken: string | undefined;
  if (status === 'granted') {
    expoPushToken = await Notifications.getExpoPushTokenAsync();
    try {
      platformPushToken = (await Notifications.getDevicePushTokenAsync()).data;
    } catch (e) {}
  }

  if (getClaimsSync()?.admin) return;

  performSetPushToken(client, {
    deviceID: Constants.installationId,
    platform: Platform.OS,
    expoPushToken: expoPushToken?.data,
    platformPushToken,
  });
}
