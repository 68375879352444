import React, { useRef, useEffect } from 'react';
import { Animated, Easing, TouchableWithoutFeedback, Platform } from 'react-native';
import MaterialIcons from '@expo/vector-icons/build/MaterialIcons';
import hexToRgba from 'hex-to-rgba';

import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { useColor } from '@src/styles';
import { useIsSafeLayoutAnimating } from '@src/hooks/useSafeLayoutAnimation';

const AnimatedIcon = Animated.createAnimatedComponent(MaterialIcons);

type Props = {
  disabled?: boolean;
  horizontal?: boolean;
  label?: string;
  labelWeight?: React.ComponentProps<typeof Text>['weight'];
  onChangeValue: (value: boolean) => void;
  testID?: string;
  value?: boolean;
  variant?: 'radio' | 'checkbox';
};

// CheckboxInner is purely a perf optimization. onChangeValue is often the
// prop that changes but only affects the TouchableWithoutFeedback wrapper component
// If that changes, we dont care about rerendering the visible portion of the component
const CheckboxInner = React.memo(function CheckboxInner({
  horizontal,
  label,
  labelWeight,
  value,
  variant,
}: Omit<Props, 'onChangeValue' | 'testID'>) {
  const isAnimating = useIsSafeLayoutAnimating();
  const { Color } = useColor();
  const animValue = useRef(new Animated.Value(value ? 1 : 0));
  useEffect(() => {
    Animated.timing(animValue.current, {
      useNativeDriver: Platform.OS !== 'web',
      toValue: value ? 1 : 0,
      duration: 150,
      easing: Easing.inOut(Easing.linear),
    }).start();
  }, [animValue, value]);
  return (
    <>
      {label ? (
        <Text
          text={label}
          style={{
            flex: horizontal ? 1 : 0,
            marginBottom: horizontal ? 0 : 4,
            marginLeft: horizontal ? 16 : 0,
          }}
          weight={labelWeight ?? 'semibold'}
        />
      ) : null}
      <View
        key={isAnimating.toString()}
        style={[
          {
            alignItems: 'center',
            borderColor: Color.accent,
            borderRadius: variant === 'radio' ? 15 : 4,
            borderWidth: 2,
            height: 30,
            justifyContent: 'center',
            overflow: variant === 'radio' ? 'hidden' : undefined,
            width: 30,
          },
        ]}
      >
        <Animated.View
          style={{
            alignItems: 'center',
            backgroundColor: hexToRgba(Color.accent),
            height: 27,
            justifyContent: 'center',
            opacity: isAnimating ? (value ? 1 : 0) : animValue.current,
            width: 27,
          }}
        >
          {variant === 'radio' ? (
            <View
              style={{
                backgroundColor: 'transparent',
                borderColor: Color.backgroundColor,
                borderWidth: 3,
                height: 26,
                width: 26,
                borderRadius: 13,
              }}
            />
          ) : (
            <AnimatedIcon
              name="check"
              size={24}
              color="white"
              style={{ transform: [{ scale: isAnimating ? (value ? 1 : 0) : animValue.current }] }}
            />
          )}
        </Animated.View>
      </View>
    </>
  );
});

export function Checkbox({ onChangeValue, testID, ...props }: Props) {
  return (
    <TouchableWithoutFeedback
      testID={testID}
      disabled={props.disabled}
      onPress={() => onChangeValue(!props.value)}
      hitSlop={{
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      }}
      accessibilityRole={props.variant === 'radio' ? 'radio' : 'checkbox'}
      accessibilityState={{
        disabled: props.disabled,
        checked: props.value,
      }}
    >
      <View
        row={props.horizontal}
        style={[
          props.horizontal
            ? {
                flexDirection: 'row-reverse',
                alignItems: 'flex-start',
              }
            : null,
          props.disabled ? { opacity: 0.5 } : null,
        ]}
      >
        <CheckboxInner {...props} />
      </View>
    </TouchableWithoutFeedback>
  );
}

export default Checkbox;
