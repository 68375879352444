import React, { forwardRef } from 'react';

import { StyleSheet, View, StyleProp, TextStyle, Text as RNText } from 'react-native';
import { useColor } from '@src/styles';

type TextAlign = 'auto' | 'left' | 'right' | 'center' | 'justify';

type Props = {
  color?: string;
  italic?: boolean;
  numberOfLines?: number;
  size?: number;
  style?: StyleProp<TextStyle>;
  testID?: string;
  text: string;
  textAlign?: TextAlign;
  strikethrough?: boolean;
  weight?: 'normal' | 'bold' | 'semibold';
  children?: React.ReactNode;
} & Pick<
  React.ComponentProps<typeof RNText>,
  'accessibilityLabel' | 'accessibilityRole' | 'accessibilityHint' | 'accessibilityLiveRegion'
>;

export const Text = forwardRef<RNText, Props>(function Text(
  {
    accessibilityHint,
    accessibilityLabel,
    accessibilityLiveRegion,
    accessibilityRole,
    color,
    italic,
    numberOfLines,
    size = 16,
    strikethrough,
    style,
    testID,
    text,
    textAlign,
    weight,
    children,
  }: Props,
  ref,
) {
  const { Color } = useColor();
  const inner = (
    <RNText
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      accessibilityLiveRegion={accessibilityLiveRegion}
      accessibilityRole={accessibilityRole}
      testID={testID}
      ref={ref}
      numberOfLines={numberOfLines}
      style={[
        {
          textAlign,
          color: color || Color.text,
          fontFamily: global.fontsLoaded
            ? weight === 'bold'
              ? 'OpenSansBold'
              : weight === 'semibold'
              ? 'OpenSansSemiBold'
              : 'OpenSansRegular'
            : undefined,
          fontSize: size,
          lineHeight: size * 1.4,
        },
        style,
        italic ? { fontStyle: 'italic', fontFamily: undefined } : null,
        strikethrough ? { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } : null,
      ]}
    >
      {text || children || ''}
    </RNText>
  );

  // accessibilityElementsHidden doesn't work on text elements, so if we want to hide this on iOS
  // we need to wrap in a View
  if (accessibilityRole === 'none') {
    return (
      <View
        importantForAccessibility="no-hide-descendants"
        accessibilityElementsHidden
        style={{ flex: StyleSheet.flatten(style)?.flex }}
      >
        {inner}
      </View>
    );
  }

  return inner;
});

export function Heading({
  style,
  ...props
}: {
  text: string;
  style?: StyleProp<TextStyle>;
  textAlign?: TextAlign;
  testID?: string;
}) {
  const { Color } = useColor();
  return (
    <Text
      {...props}
      size={22}
      color={Color.tertiary}
      weight="bold"
      style={[{ lineHeight: 28, marginBottom: 4 }, style]}
      accessibilityRole="header"
    />
  );
}

export function Subheading({
  style,
  ...props
}: {
  text: string;
  style?: StyleProp<TextStyle>;
  textAlign?: TextAlign;
  testID?: string;
}) {
  return (
    <Text
      {...props}
      size={16}
      weight="bold"
      style={[{ lineHeight: 18, marginBottom: 4 }, style]}
      accessibilityRole="header"
    />
  );
}
