import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { PickerSelectProps as PickerProps } from 'react-native-picker-select';
import noop from 'lodash/noop';
// import { FixedSizeList as List } from 'react-window';

import ReactSelect from 'react-select';

import { Color } from '@src/styles';
import { View } from '@src/components/View';
import { TextInput } from '@src/components/TextInput';
import { Text } from '@src/components/Text';

// We want to appear above modals, so use a Z_INDEX larger than the modal zindex
// https://github.com/Dekoruma/react-native-web-modal/blob/9688f8a0132177d0fd7a4766bf9b7408bafa9db8/packages/modal-react-native-web/src/styles.js#L10
const Z_INDEX = 10000;

// Restore if perf is an issue but doesnt play well with grouped options
// const ITEM_HEIGHT = 35;
// class MenuList extends React.Component<any> {
//   render() {
//     const { options, children, maxHeight, getValue } = this.props;
//     const [value] = getValue();
//     const initialOffset = options.indexOf(value) * ITEM_HEIGHT;

//     const childrenLength = (children as any).length;

//     return childrenLength ? (
//       <List
//         height={Math.min(maxHeight, (childrenLength || 1) * ITEM_HEIGHT)}
//         itemCount={(children as any).length}
//         itemSize={ITEM_HEIGHT}
//         initialScrollOffset={initialOffset}
//         width="100%"
//       >
//         {({ index, style }) => <div style={style}>{(children as any)[index]}</div>}
//       </List>
//     ) : (
//       children
//     );
//   }
// }

const TextComponent = (name: string) => (props: any) => (
  <div
    ref={props.innerRef}
    {...props.innerProps}
    style={props.getStyles && props.getStyles(name, props)}
  >
    {typeof props.children === 'object' ? (
      <View style={{ justifyContent: 'center' }}>{props.children}</View>
    ) : name === 'groupHeading' ? (
      <Text text={props.children} color={Color.tertiary} size={13} weight="bold" />
    ) : name === 'multiValueLabel' ? (
      <Text text={props.children} color={Color.tertiary} size={13} />
    ) : (
      <Text text={props.children} style={{ opacity: props.isDisabled ? 0.6 : 1 }} />
    )}
  </div>
);

const components = {
  Option: TextComponent('option'),
  Placeholder: TextComponent('placeholder'),
  SingleValue: TextComponent('singleValue'),
  GroupHeading: TextComponent('groupHeading'),
  MultiValueLabel: TextComponent('multiValueLabel'),
  MultiValueContainer: (props: any) => (
    <View row style={{ backgroundColor: Color.styleGuide.Gray7, margin: 2 }}>
      {props.children}
    </View>
  ),
  // MenuList,
};

export function WebPicker<T>({
  testID,
  label,
  onChangeValue,
  placeholder,
  style,
  value,
  ...props
}: Omit<PickerProps, 'items' | 'value' | 'placeholder' | 'onValueChange' | 'style'> & {
  testID?: string;
  items:
    | { label: string; value: T }[]
    | { label: string; options: { label: string; value: T }[] }[];
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  onChangeValue?: (value: T) => void;
  style?: StyleProp<ViewStyle>;
  value?: T;
}) {
  function getValue() {
    for (const i of props.items as any) {
      if (i.value === value) return i;
      if (i.options) {
        for (const i2 of i.options) {
          if (i2.value === value) return i2;
        }
      }
    }
  }

  if (global.e2e) {
    return (
      <View style={style}>
        <TextInput
          testID={testID}
          label={label}
          placeholder={placeholder}
          onChangeValue={onChangeValue as any}
          value={value as any}
        />
      </View>
    );
  }

  return (
    <View style={[{ zIndex: 1 }, style]}>
      {label ? <Text text={label} style={{ marginBottom: 4 }} weight="bold" /> : null}
      <ReactSelect
        isDisabled={props.disabled}
        options={props.items}
        placeholder={placeholder || 'Choose a value'}
        onChange={
          onChangeValue
            ? (item: T, { action }: any) => {
                if (!onChangeValue) return;
                if (Array.isArray(item)) {
                  onChangeValue(item.map((i) => i.value) as any);
                } else if (item) {
                  onChangeValue(
                    (item as any).value === '__placeholder__' ? '' : (item as any).value,
                  );
                } else {
                  onChangeValue((Array.isArray(props.items) ? [] : '') as any);
                }
              }
            : noop
        }
        isMulti={Array.isArray(value)}
        styles={{
          menuPortal: (base: any) => ({ ...base, zIndex: Z_INDEX }),
        }}
        components={components}
        value={
          typeof value === undefined ? undefined : typeof value === 'object' ? value : getValue()
        }
      />
    </View>
  );
}
