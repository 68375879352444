import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import * as SecureStore from 'expo-secure-store';
import * as Crypto from 'expo-crypto';
import Auth from 'firebase-auth-lite';
import { NativeModulesProxy } from '@unimodules/core';

import { APP_SLUG, IS_PRODUCTION, FIREBASE_CONFIG } from '@src/constants';
import { Severity, setUser, setUserProperties, addBreadcrumb } from '@src/lib/log';
import Sentry from '@src/sentry';
import { parseJwt } from '@lib/parseJwt';
import { clearAll } from '@src/lib/mmkv';

function getSecureStoreCompatibleKey(key: string): string {
  return key.replace(/[^\w\d\.\-\_]/g, '_');
}

function isCryptoAvailable() {
  return !!NativeModulesProxy.ExpoCrypto;
}

const HASH_SALT = 'bc22d4a6ff53d8406c6ad864195ed144';
const PASSWORD_HASH_STORAGE_KEY = 'passwordHash';
async function hashPassword(password: string) {
  if (isCryptoAvailable()) {
    return await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA512,
      HASH_SALT + password,
    );
  }
  throw new Error('expo-crypto is unavailable');
}

async function hashAndStorePassword(password: string) {
  if (isCryptoAvailable()) {
    const hash = await hashPassword(password);
    if (Platform.OS !== 'web') {
      await SecureStore.setItemAsync(PASSWORD_HASH_STORAGE_KEY, hash);
    }
  }
}

const authStorage =
  Platform.OS === 'web'
    ? AsyncStorage
    : {
        getItem: async (key: string) => {
          const secureValue = await SecureStore.getItemAsync(getSecureStoreCompatibleKey(key));
          if (!secureValue) {
            const insecureValue = await AsyncStorage.getItem(key);
            if (insecureValue) {
              await SecureStore.setItemAsync(getSecureStoreCompatibleKey(key), insecureValue);
              await AsyncStorage.removeItem(key);
            }
            return insecureValue;
          }
          return secureValue;
        },
        setItem: async (key: string, value: string) => {
          if (value.length > 2048 - 100) {
            Sentry.captureMessage('Auth SecureStore usage close to length limit', {
              level: Sentry.Severity.Warning,
              extra: {
                length: value.length,
              },
            });
          }
          return SecureStore.setItemAsync(getSecureStoreCompatibleKey(key), value);
        },
        removeItem: async (key: string) => {
          await AsyncStorage.removeItem(key);
          return SecureStore.deleteItemAsync(getSecureStoreCompatibleKey(key));
        },
      };

const _auth = new Auth({
  lazyInit: true,
  name: '[DEFAULT]',
  storage: authStorage,
  apiKey: FIREBASE_CONFIG.apiKey,
});

export const auth = () => _auth;

export function isValidRoleForApp() {
  const claims = getClaimsSync();
  if (claims?.product && claims.product !== '1.0.0') {
    const wrongRole =
      (APP_SLUG === 'oui-aviva' && (claims.role !== 'patient' || claims.admin)) ||
      (APP_SLUG === 'oui-aviva-staff' && claims.role === 'patient');
    if (wrongRole) {
      return false;
    }
  }
  return true;
}

let initialized = false;

export async function initAuth(): Promise<typeof _auth.user> {
  if (initialized) {
    return Promise.resolve(getCurrentUser());
  }

  return new Promise(async (resolve) => {
    function handleUser(user: typeof _auth.user) {
      initialized = true;
      setUser({
        userID: (user as any)?.localId,
        email: IS_PRODUCTION && APP_SLUG !== 'oui-aviva-staff' ? undefined : user?.email,
      });
      if (user) {
        const claims = getClaimsSync();
        const useMonoAPI = !!((claims?.product && claims?.product !== '1.0.0') || claims?.admin);
        global.setFlags?.((flags) => ({ ...flags, useMonoAPI }));
        setUserProperties({
          productVersion: claims?.product,
          testUser: claims?.testUser || user?.email?.includes('detox+'),
        });
      }
      resolve(user);
    }

    auth()
      .initUser()
      .catch((e) => {
        Sentry.addBreadcrumb({ category: 'auth', message: 'initUser catch' });
        Sentry.captureException(e);
        if (['USER_NOT_FOUND', 'USER_DISABLED', 'TOKEN_EXPIRED'].includes(e.message)) {
          signOut();
        } else if (auth().user) {
          // this can be reached if internet is unreachable
          handleUser(auth().user);
        }
      });

    auth().onAuthStateChanged(handleUser);
  });
}

async function clearStorage() {
  if (Platform.OS !== 'web') {
    await SecureStore.deleteItemAsync(PASSWORD_HASH_STORAGE_KEY);
    await SecureStore.deleteItemAsync('reauthPIN');
  }
  return Promise.all([clearAll(), AsyncStorage.clear()]).catch((e) => {
    // if we can't clear it's not worth stopping the entire process
    Sentry.captureException(e);
  });
}

export async function getAuthToken(forceRefresh?: boolean): Promise<string | null> {
  let user = getCurrentUser();
  if (user) {
    await auth().refreshIdToken(forceRefresh);
    user = getCurrentUser();
  }
  return user ? Promise.resolve(user.tokenManager.idToken) : Promise.resolve(null);
}

export function getAuthTokenSync(): string | null {
  return getCurrentUser()?.tokenManager?.idToken ?? null;
}

export function getCurrentUser() {
  return auth()?.currentUser;
}

export async function signOut() {
  addBreadcrumb({ category: 'auth', message: 'sign-out' });
  await clearStorage();
  return auth().signOut();
}

export async function createUser(email: string, password: string) {
  addBreadcrumb({ category: 'auth', message: 'create-user' });
  const data = await auth().signUp(email, password);
  await hashAndStorePassword(password);
  addBreadcrumb({ category: 'auth', message: 'create-user-success' });
  await AsyncStorage.setItem('lastSeen', Date.now().toString());
  return data;
}

export async function login(email: string, password: string) {
  // just in case some data from the last user was somehow still persisted, we proactively clear
  // storage before signing in a new user so there is no chance of showing a user the wrong user's data
  await clearStorage();
  addBreadcrumb({ category: 'auth', message: 'login' });
  const data = await auth().signIn(email, password);
  addBreadcrumb({ category: 'auth', message: 'login-success' });
  if (!isValidRoleForApp()) {
    await signOut();
    throw new Error('MISSING_EMAIL');
  }
  await hashAndStorePassword(password);
  await AsyncStorage.setItem('lastSeen', Date.now().toString());
  return data;
}

export async function reauthenticate(email: string, password: string) {
  const user = getCurrentUser();
  if (user) {
    addBreadcrumb({ category: 'auth', message: 'reauthenticate' });
    const passwordHash =
      Platform.OS === 'web' ? null : await SecureStore.getItemAsync(PASSWORD_HASH_STORAGE_KEY);
    if (passwordHash && isCryptoAvailable()) {
      const candidateHash = await hashPassword(password);
      if (passwordHash !== candidateHash) {
        throw new Error('INVALID_PASSWORD');
      }
    } else {
      await auth().signIn(email || user.email!, password);
      await hashAndStorePassword(password);
    }
    addBreadcrumb({ category: 'auth', message: 'reauthenticate-success' });
    await AsyncStorage.setItem('lastSeen', Date.now().toString());
  } else {
    addBreadcrumb({
      category: 'auth',
      message: 'reauthenticate called without current user',
      level: Severity.Warning,
    });
    await login(email, password);
  }
}

export async function getIdTokenResult() {
  await auth().refreshIdToken(true);
  return getAuthToken();
}

export type Claims = {
  admin?: boolean;
  product: '1.0.0' | '1.1.0' | '1.2.0' | '2.0.0';
  role: 'patient' | 'practitioner' | 'registrar';
  testUser: boolean;
  firebase: {
    identities: {
      email?: string[];
    };
  };
  user_id: string;
};

export async function getClaims(): Promise<Claims | null> {
  const token = await getAuthToken();
  return token ? parseJwt(token) : null;
}

export function getClaimsSync(): Claims | null {
  const token = getAuthTokenSync();
  return token ? parseJwt(token) : null;
}

export function isFirebaseErrorKey(str: string) {
  return !str.match(/[^A-Z_]+/);
}
