// This file is automatically generated. See gqlcodegen.yml for details
import type * as Types from '@lib/types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  readonly currentUser?: Types.Maybe<{
    readonly __typename: 'CurrentOuiUser';
    readonly role: Types.OuiUserRoleType;
    readonly user?: Types.Maybe<
      | {
          readonly __typename: 'Patient';
          readonly ID: GQLUUID;
          readonly productVersion: Types.ProductVersion;
          readonly productVariant: Types.ProductVariant;
          readonly onboardingVariant: Types.OnboardingVariant;
          readonly birthDate?: Types.Maybe<GQLDate>;
          readonly race?: Types.Maybe<Types.PatientRace>;
          readonly genderIdentity?: Types.Maybe<Types.PatientGenderIdentity>;
          readonly sexualOrientation?: Types.Maybe<Types.PatientSexualOrientation>;
          readonly militaryStatus?: Types.Maybe<Types.PatientMilitaryStatus>;
          readonly person: {
            readonly email?: Types.Maybe<string>;
            readonly givenName?: Types.Maybe<string>;
            readonly familyName?: Types.Maybe<string>;
            readonly contactPoint: ReadonlyArray<{
              readonly ID: GQLUUID;
              readonly system?: Types.Maybe<string>;
              readonly use?: Types.Maybe<string>;
              readonly value?: Types.Maybe<string>;
            }>;
          };
          readonly address: ReadonlyArray<{
            readonly use: Types.AddressUse;
            readonly line?: Types.Maybe<ReadonlyArray<string>>;
            readonly city?: Types.Maybe<string>;
            readonly state?: Types.Maybe<string>;
            readonly postalCode?: Types.Maybe<string>;
          }>;
        }
      | {
          readonly __typename: 'Practitioner';
          readonly ID: GQLUUID;
          readonly person: {
            readonly email?: Types.Maybe<string>;
            readonly givenName?: Types.Maybe<string>;
            readonly familyName?: Types.Maybe<string>;
          };
        }
      | {
          readonly __typename: 'Registrar';
          readonly ID: GQLUUID;
          readonly person: {
            readonly email?: Types.Maybe<string>;
            readonly givenName?: Types.Maybe<string>;
            readonly familyName?: Types.Maybe<string>;
          };
        }
    >;
  }>;
};

export type SelfPatientQueryVariables = Types.Exact<{
  patientID: Types.Scalars['UUID'];
}>;

export type SelfPatientQuery = {
  readonly patientByPatientID?: Types.Maybe<{
    readonly __typename: 'PatientProfile';
    readonly patient: { readonly __typename: 'Patient'; readonly ID: GQLUUID };
    readonly progress: ReadonlyArray<{
      readonly content: string;
      readonly completion: number;
      readonly completed: boolean;
      readonly updatedAt: GQLDateTime;
    }>;
  }>;
};

export type OrganizationsQueryVariables = Types.Exact<{
  query?: Types.Maybe<Types.Scalars['String']>;
}>;

export type OrganizationsQuery = {
  readonly organizations?: Types.Maybe<
    ReadonlyArray<{
      readonly __typename: 'Organization';
      readonly ID: GQLUUID;
      readonly name?: Types.Maybe<string>;
      readonly productVersion: Types.ProductVersion;
      readonly productVariant: Types.ProductVariant;
      readonly caringContactsEnabled: boolean;
    }>
  >;
};

export const CurrentUserDocument = gql`
  query CurrentUser {
    currentUser: ouiUser {
      __typename
      role
      user {
        __typename
        ... on Registrar {
          ID
          person {
            email
            givenName
            familyName
          }
        }
        ... on Practitioner {
          ID
          person {
            email
            givenName
            familyName
          }
        }
        ... on Patient {
          ID
          productVersion
          productVariant
          onboardingVariant
          person {
            email
            givenName
            familyName
            contactPoint {
              ID
              system
              use
              value
            }
          }
          birthDate
          race
          genderIdentity
          sexualOrientation
          militaryStatus
          address {
            use
            line
            city
            state
            postalCode
          }
        }
      }
    }
  }
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;

export function queryCurrentUser(
  client: Apollo.ApolloClient<unknown>,
  variables?: CurrentUserQueryVariables | undefined,
  options?: Omit<
    Apollo.QueryOptions<CurrentUserQuery, CurrentUserQueryVariables>,
    'query' | 'variables'
  >,
) {
  return client.query<CurrentUserQuery, CurrentUserQueryVariables>({
    query: CurrentUserDocument,
    variables,
    ...options,
  });
}

export const SelfPatientDocument = gql`
  query SelfPatient($patientID: UUID!) {
    patientByPatientID(patientID: $patientID) {
      __typename
      patient {
        __typename
        ID
      }
      progress {
        content
        completion
        completed
        updatedAt
      }
    }
  }
`;

/**
 * __useSelfPatientQuery__
 *
 * To run a query within a React component, call `useSelfPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfPatientQuery({
 *   variables: {
 *      patientID: // value for 'patientID'
 *   },
 * });
 */
export function useSelfPatientQuery(
  baseOptions: Apollo.QueryHookOptions<SelfPatientQuery, SelfPatientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SelfPatientQuery, SelfPatientQueryVariables>(SelfPatientDocument, options);
}
export function useSelfPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SelfPatientQuery, SelfPatientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SelfPatientQuery, SelfPatientQueryVariables>(
    SelfPatientDocument,
    options,
  );
}
export type SelfPatientQueryHookResult = ReturnType<typeof useSelfPatientQuery>;
export type SelfPatientLazyQueryHookResult = ReturnType<typeof useSelfPatientLazyQuery>;

export function querySelfPatient(
  client: Apollo.ApolloClient<unknown>,
  variables: SelfPatientQueryVariables,
  options?: Omit<
    Apollo.QueryOptions<SelfPatientQuery, SelfPatientQueryVariables>,
    'query' | 'variables'
  >,
) {
  return client.query<SelfPatientQuery, SelfPatientQueryVariables>({
    query: SelfPatientDocument,
    variables,
    ...options,
  });
}

export const OrganizationsDocument = gql`
  query Organizations($query: String) {
    organizations(query: $query) {
      __typename
      ID
      name
      productVersion
      productVariant
      caringContactsEnabled
    }
  }
`;

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options,
  );
}
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>;
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>;

export function queryOrganizations(
  client: Apollo.ApolloClient<unknown>,
  variables?: OrganizationsQueryVariables | undefined,
  options?: Omit<
    Apollo.QueryOptions<OrganizationsQuery, OrganizationsQueryVariables>,
    'query' | 'variables'
  >,
) {
  return client.query<OrganizationsQuery, OrganizationsQueryVariables>({
    query: OrganizationsDocument,
    variables,
    ...options,
  });
}
